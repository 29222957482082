import React, { useEffect, useState } from "react";
import styled from "styled-components";
import OperatorSearch from "./Recharge/OperatorSearch";
import { useTheme } from "@material-ui/core";
import { localforageGetItem } from "oautils/oaForageUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const innerPageUrl = "/recharge/";

const RechargeSection = (props) => {
  const history = useHistory();
  const theme = useTheme();
  const [userType, setUserType] = useState(null);
  const [corporateType, setCorporateType] = useState(null);

  const optionsQuicklinks = [
    // {
    //     name: "transactionEnquiry",
    //     label: "Transaction Enquiry",
    //     url: innerPageUrl + "transaction-enquiry",
    //     icon: <i className="fas fa-search"></i>,
    // },
    {
      name: "transactionReports",
      label: "Transaction Reports",
      url: "/admin/booking-report/recharge",
      icon: <i className="fas fa-list"></i>,
    },
  ];

  const openQuickLink = (url) => {
    history.push({
      pathname: url,
    });
  };

  useEffect(() => {
    localforageGetItem("user-details", function(err, details) {
      if (details != null) {
        setUserType(details.userType);
        setCorporateType(details.corporateType);
      }
    });
  }, []);

  return (
    <RootContainer theme={theme}>
      <OperatorSearch />
      {userType &&
        userType == "agent" &&
        corporateType &&
        corporateType != "corporate" && (
          <div className="quick-links-box">
            {optionsQuicklinks &&
              optionsQuicklinks.map((val, ind) => (
                <div
                  item
                  className="quick-link-item"
                  key={ind}
                  onClick={() => openQuickLink(val.url)}
                >
                  <div className="qlink-icon-wrapper">{val.icon}</div>
                  <div className="qlink-label-wrapper">{val.label}</div>
                </div>
              ))}
          </div>
        )}
    </RootContainer>
  );
};

export default RechargeSection;

const RootContainer = styled.div`
  width: 100%;

  .quick-links-box {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  .quick-link-item {
    margin: 5px;
    padding: 2px 5px;
    color: ${(prope) => prope.theme.palette.primary.contrastText};
    background-color: ${(prope) => prope.theme.palette.primary.main};
    border-radius: 4px;
    cursor: pointer;
    width: 130px;
    line-height: 1.2;
    border: solid 1px ${(prope) => prope.theme.palette.background.default};
    &:hover {
      border: solid 1px ${(prope) => prope.theme.palette.primary.main};
    }
    .quicklink-icon {
      img {
        height: 18px;
      }
    }
    ,
    .qlink-icon-wrapper {
      text-align: center;
      margin-top: 3px .MuiSvgIcon-root {
        font-size: 15px;
      }

      i {
        font-size: 15px;
      }
    }
    .qlink-label-wrapper {
      font-size: 10px;
      text-align: center;
      white-space: nowrap;
      text-transform: uppercase;
    }
  }
`;
