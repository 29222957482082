import React from "react";
import { AppStateContext } from "layouts/AppStateProvider";
import {
  localforageSetItem,
  localforageGetItem,
  localforageClear,
} from "oautils/oaForageUtils";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import { currencyFormatter } from "oautils/oaCommonUtils";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { RefreshRounded } from "@material-ui/icons";
import { AppConstant } from "appConstant";
import MailIcon from "assets/icons/mail_icon.svg";
import PhoneIcon from "assets/icons/phone_icon.svg";

const TopSection = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [corpName, setCorpName] = React.useState("");
  const [corpIdentity, setCorpIdentity] = React.useState("");
  const [serviceOptions, setServiceOptions] = React.useState([]);
  const [userType, setUserType] = React.useState(null);
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [corporateType, setCorporateType] = React.useState(null);
  const [accountBalance, setAccountBalance] = React.useState("");

  const isMounted = React.useRef(false);

  const refreshBalance = () => {
    setAccountBalance(0);
    apiCall(WebApi.getDashboardData, {}, function(response) {
      let mainAccountBalance = response.data.mainAccountBalance;
      setAccountBalance(mainAccountBalance);
      localforageGetItem("user-details", function(err, value) {
        localforageSetItem("user-details", {
          ...value,
          cashBalance: mainAccountBalance,
        });
      });
    });
  };

  const logOut = (event) => {
    localforageClear(() => {
      WebApi.getAgentInfo({ userType: "G" }, (response) => {
        if (response != null && response.success === true) {
          localforageClear(() => {
            localforageSetItem("user-id", 0);

            const loginPagePath =
              corporateType && corporateType != "corporate"
                ? "/auth/login-page"
                : "/corporate_login";

            localforageSetItem("user-details", response.data, () => {
              localforageSetItem("access-key", response.accesskey, () => {
                history.push("/flights");
                window.location.reload();
              });
            });
          });
        }
      });
    });
  };

  const logOutB2C = (event) => {
    localforageClear(() => {
      WebApi.getAgentInfo({ userType: "G" }, (response) => {
        if (response != null && response.success === true) {
          localforageClear(() => {
            localforageSetItem("user-id", 0);

            localforageSetItem("user-details", response.data, () => {
              localforageSetItem("access-key", response.accesskey, () => {
                history.push("/flights");
                window.location.reload();
              });
            });
          });
        }
      });
    });
  };

  const openDashboard = (event) => {
    history.push("/admin/dashboard");
  };

  const openDashboardB2c = (event) => {
    history.push("/b2c/mybookings");
  };

  const getUserDetails = (mountStatus) => {
    localforageGetItem("user-details", function(err, value) {
      if (mountStatus && value) {
        if (value.userTypeAbv == "R") {
          setIsLoggedIn(true);
        }

        setCorpName(value.corpname);
        setCorpIdentity(value.userIdentity);
        setAccountBalance(value.cashBalance);
        setServiceOptions(value.serviceOption);

        setUserType(value.userType);
        setUserTypeb2c(value.userType);
        setCorporateType(value.corporateType);

        if (
          value.userType === "agent" ||
          value.userType === "direct_agent" ||
          value.corporateType == "corporate"
        ) {
          refreshBalance();
        }
      } else {
        console.log("error getting user details", err);
      }
    });
  };

  React.useEffect(() => {
    // console.log("####################### get serviceOption");
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    getUserDetails(isMounted.current);
  }, [isMounted.current]);
  //console.log("userType " + userType);
  return (
    <>
      {(userType === null ||
        userType === "b2c_user" ||
        userType === "agent" ||
        userType === "direct_agent") && (
        <section className={classes.topSection}>
          <div className="top-section-container">
            <div className="column-left">
              {/* <div className="top-section-link-wrapper">
                                <ul>
                                    <li>
                                        <a href="">
                                            <img src={PhoneIcon} /> {AppConstant.countryDialCode} {AppConstant.supportContact}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <img src={MailIcon} /> {AppConstant.supportEmail}
                                        </a>
                                    </li>
                                </ul>
                            </div> */}
              {/* {!userType &&
                            <div className="top-section-link-wrapper social-link-wrapper">
                                <ul>
                                    <li>
                                        <a href="">
                                            <img src={FacebookIcon} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <img src={TwitterIcon} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <img src={GoogleplusIcon} />
                                        </a>
                                    </li>
                                </ul>
                            </div>} */}
            </div>
            <div className="column-right">
              <div className="top-section-link-wrapper">
                {/* {!userType && 
                                    <ul>
                                        <li>
                                            <a onClick={() => history.push("/")}>Sign in</a>
                                        </li>
                                        <li>
                                            <a onClick={() => history.push("/prelogin/registration")}>New User?</a>
                                        </li>
                                        <li>
                                            <a href="https://partner.triumphhtravel.com/">Old Website</a>
                                        </li>  
                                    </ul>
                                } */}
                {userType === "b2c_user" && (
                  <ul>
                    <li>
                      <a onClick={(event) => openDashboardB2c(event)}>
                        Dashboard
                      </a>
                    </li>
                    <li>
                      <a>
                        {corpName}({corpIdentity})
                      </a>
                    </li>
                    <li>
                      <a onClick={(event) => logOutB2C(event)}>Sign out</a>
                    </li>
                  </ul>
                )}
                {(userType === "agent" || userType === "direct_agent") && (
                  <ul>
                    {/* <li>
                                            <a onClick={(event) => openDashboard(event)}>Dashboard</a>
                                        </li> */}
                    <li className="app-bar-link-list-item dropdown-on-hover-list-item">
                      <a className="app-bar-link no-user dropdown-on-hover">
                        24x7 helpline
                      </a>
                      <ul className="dropdown-on-hover-menu">
                        <li>
                          <a
                            href={`tel:${AppConstant.countryDialCode}${AppConstant.supportContactView}`}
                          >
                            <img src={PhoneIcon} />+
                            {AppConstant.countryDialCode}&nbsp;
                            {AppConstant.supportContactView}
                          </a>
                        </li>
                        <li>
                          <a href={`mailto:${AppConstant.supportEmail}`}>
                            <img src={MailIcon} /> {AppConstant.supportEmail}
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      Balance:&nbsp;&nbsp;
                      <a
                        onClick={(event) => refreshBalance()}
                        className="balance-link"
                      >
                        <RefreshRounded />
                        &nbsp;{currencyFormatter(accountBalance)}
                      </a>
                    </li>
                    <li>
                      <a>
                        {corpName}({corpIdentity})
                      </a>
                    </li>
                    <li>
                      <a onClick={(event) => logOut(event)}>Sign out</a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default TopSection;

const useStyles = makeStyles((theme) => ({
  topSection: {
    // backgroundColor:theme.palette.secondary.darkOrange,
    backgroundColor: theme.palette.primary.LightGraySec,
    transition:
      "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s",
    marginTop: 0,
    marginBottom: 0,
    padding: 0,
    position: "relative",
    boxSizing: "border-box",
    "& .app-bar-link-list-item": {
      "&.dropdown-on-hover-list-item": {
        position: "relative",
        "&:hover": {
          "& .dropdown-on-hover-menu": {
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.down(400)]: {
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              left: 0,
              zIndex: 1,
            },
          },
        },
        "& .dropdown-on-hover-menu": {
          display: "none",
          position: "absolute",
          top: 21,
          right: -10,
          // width: 230,
          background: theme.palette.text.defaultContrast,
          boxShadow: "0px 0px 10px -5px rgba(0,0,0,0.75)",
          padding: 10,
          "& li": {
            listStyle: "none",
            margin: "3px 0",
          },
          "& a": {
            display: "flex",
            gap: 5,
            color: theme.palette.text.default,
          },
        },
      },
    },
    "& *::before, *::after": {
      boxSizing: "inherit",
      WebkitFontSmoothing: "inherit",
      wordBreak: "break-word",
      wordWrap: "break-word",
    },
    "& .top-section-container": {
      display: "-webkit-box",
      display: "-ms-flexbox",
      display: "flex",
      marginRight: "auto",
      marginLeft: "auto",
      position: "relative",
      WebkitBoxSizing: "border-box",
      boxSizing: "border-box",
      justifyContent: "flex-end",
      padding: "0 60px 0 30px",
      "& *::before, *::after": {
        boxSizing: "inherit",
        WebkitFontSmoothing: "inherit",
        wordBreak: "break-word",
        wordWrap: "break-word",
      },
      [theme.breakpoints.down(960)]: {
        padding: "0 10px",
      },
    },
    "& .column-left": {
      marginRight: "auto",
      display: "-webkit-box",
      display: "-ms-flexbox",
      display: "flex",
    },
    "& .column-right": {
      marginLeft: "auto",
      display: "-webkit-box",
      display: "-ms-flexbox",
      display: "flex",
    },
    "& .top-section-link-wrapper": {
      "& ul": {
        listStyle: "none",
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(960)]: {
          display: "none",
        },
      },
      "& li": {
        margin: "0 10px",
        color: theme.palette.secondary.darkOrange,
      },
      "& a": {
        // color: theme.palette.primary.lightText,
        color: theme.palette.primary.darkText,
        cursor: "pointer",
        "&.balance-link": {
          color: theme.palette.primary.darkText,
          // color: theme.palette.secondary.main,
          "& .MuiSvgIcon-root": {
            height: 15,
          },
        },
        [theme.breakpoints.down(1441)]: {
          fontSize: 12,
        },
      },
    },
    [theme.breakpoints.down(960)]: {
      backgroundColor: "none",
      position: "absolute",
    },
  },
}));
