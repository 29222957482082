import React, { useState, useEffect } from 'react'
import WebApi from "api/ApiConstants";
import { apiCall } from 'oautils/oaDataUtils';
import { getLogoUrl } from 'oautils/oaImageUtils';
import OaLoading from 'pages/components/OaLoading';
import OaError from 'pages/components/OaError';
import OaCard from 'oahoc/OaCard';
import { currencyFormatter } from 'oautils/oaCommonUtils';
import 'assets/css/print-portrait.css';
import cx from "classnames";
import Chip from '@material-ui/core/Chip';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PrintIcon from '@material-ui/icons/Print';

import { Dialog, makeStyles, TextField, useTheme } from '@material-ui/core';
import { pdfWsDownload } from 'oautils/oaPdfUtils';
import { FLIGHT_WS_INVOICE_PDF_DOWNLOAD } from 'api/ApiConstants';
import { AppConstant } from 'appConstant';

const useStyles = makeStyles(theme => ({
  tdHead: {
    borderWidth: "1px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: theme.palette.background.dark,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  tdCell: {
    borderWidth: "1px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: theme.palette.secondary.chineseGray,
    backgroundColor: theme.palette.background.default,
  },

  tdcellDividerTop: {
    borderWidth: "1px 0 0 0",
    borderStyle: "solid",
    borderColor: theme.palette.secondary.chineseGray,
    backgroundColor: theme.palette.background.default,
  },

  tdcellPayment: {
    borderWidth: "1px 0 1px 0",
    borderStyle: "solid",
    borderColor: theme.palette.secondary.chineseGray,
    backgroundColor: theme.palette.background.default,
  },

  tdcellPaymentBottom: {
    borderWidth: "0 0 1px 0",
    borderStyle: "solid",
    borderColor: theme.palette.secondary.chineseGray,
    backgroundColor: theme.palette.background.default,
    textTransform: 'capitalize'
  },
  tdcellLeft: {
    textAlign: "left"
  },
  tdcellRight: {
    textAlign: "right"
  },
  dialogPaper: {
    "& .dialog-content": {
      position: "relative",
      width: "100%",
      padding: 20
    },
    "& .btn-close": {
      cursor: "pointer",
      width: 30,
      height: 30,
      position: "absolute",
      top: 0,
      right: 0,
      background: theme.palette.background.default,
      border: "none",
      fontSize: 20
    },
    "& .form-container": {
      boxSizing: "border-box"
    },
    "& .form-element": {
      padding: "5px 0",
      "& label": {
        color: "#333"
      },
      "& .btn-add": {
        cursor: "pointer",
        outline: "none",
        border: `solid 1px ${theme.palette.primary.main}`,
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: 10,
        borderRadius: 4
      }
    }
  }
}));

const InvoiceFlight = (props) => {

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const [infoData, setInfoData] = useState({});
  const [showCustomerInfoModal, setShowCustomerInfoModal] = useState(false);
  const theme = useTheme();
  const styles = useStyles();

  const hideCustomerInfoModal = () => {
    if(infoData?.to.corporateName !== "") {
      setShowCustomerInfoModal(false);
    }
  };

  const getInfoData = () => {
    if (props?.type === "agent_invoice" || props.query?.type === "flight-agent") {
      setInfoData({
        from: {
          corporateName: AppConstant?.name,
          address: AppConstant.address?.office,
          phone: AppConstant?.supportContact,
          email: AppConstant?.supportEmail,
        },
        to: {
          corporateName: data.invoiceHeader?.corporateName,
          address: data.invoiceHeader?.address,
          phone: data.invoiceHeader?.mobileNumber,
          email: data.invoiceHeader?.email,
        }
      });
    } else {
      setInfoData({
        from: {
          corporateName: data.invoiceHeader?.corporateName,
          address: data.invoiceHeader?.address,
          phone: data.invoiceHeader?.mobileNumber,
          email: data.invoiceHeader?.email,
        },
        to: {
          corporateName: "",
          address: ""
        }
      });
    }
  };

  const onChangeCustomerName = (e) => {
    setInfoData({
      ...infoData,
      to: {
        ...infoData.to,
        corporateName: e.target.value
      }
    });
  };

  const onChangeCustomerAddress = (e) => {
    setInfoData({
      ...infoData,
      to: {
        ...infoData.to,
        address: e.target.value
      }
    });
  };

  const printHandler = () => {
    window.print();
  }

  const downloadPdfHandler = (e) => {
    // e.target.setAttribute('disabled', 'disabled');
    // // getImageData('section-to-print', 'l', function(imageData) {
    // //   setIData(imageData);
    // // });
    // pdfDownload('section-to-print', 'p', 'invoice-' + props.query.ref)
    // e.target.removeAttribute('disabled');
    setIsLoading(true);
    pdfWsDownload(FLIGHT_WS_INVOICE_PDF_DOWNLOAD, { invoiceType: props.type, transactionId: props.query.ref },
      "flight-invoice-" + props.query.ref);
    setTimeout(() => { setIsLoading(false) }, 5000);
  }

  useEffect(() => {
    let id = props.query.ref;
    let type = props.type;
    if (type === "user_invoice") {
      setShowCustomerInfoModal(true);
    }
    let searchParams = {
      invoiceType: type,
      transactionId: id
    }
    apiCall(WebApi.getVoucherFlight, searchParams, (response) => {
      if (response.success === true) {
        setData(response.data);
      }
      setIsLoading(false);
      setIsError(response.success === false);
    });
  }, []);

  useEffect(() => {
    if (data) {
      getInfoData();
    }
  }, [data]);

  return (
    <div style={{ width: "100%", maxWidth: 1200 }}>
      {isLoading && <OaLoading />}
      {isError && <OaError />}
      {!isLoading && !isError &&
        <>
          <OaCard>
            <Chip icon={<PrintIcon />} label="Print" onClick={printHandler} color="primary" style={{ margin: "5px" }} />
            {/* <Chip icon={<PictureAsPdfIcon />} label="PDF" onClick={downloadPdfHandler} color="primary" style={{ margin: "5px" }} /> */}
            <div id="myMm" style={{ height: "1mm" }} />
            <div className={"section-to-print"} id="section-to-print" style={{width: "100%"}}>
              <div style={{ border: `3px solid ${theme.palette.secondary.chineseGray}`, borderRadius: "5px" }}>
                <div style={{ margin: '5px' }}>
                  {data.invoiceHeader.psaLogo && data.invoiceHeader.psaLogo != "" ?
                    <img src={getLogoUrl(data.invoiceHeader.psaLogo)} height="50"/> :
                    <img src={AppConstant.brandUrl} height="50" />
                  }
                </div>

                <div style={{ margin: '20px 5px 5px 5px' }}>
                  <table width="100%">
                    <tbody>
                      <tr>
                        <td>
                          <span style={{ fontWeight: "bold" }}>Invoice No: {data.referenceNo}</span>
                        </td>
                        <td>
                          <span style={{ fontWeight: "bold" }}>Invoice Date: </span> {data.transactionDate}
                        </td>
                        <td>
                          {/* <span style={{fontWeight: "bold"}}>PNR: </span> {data.passengerDetails.eticketNO} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h2 style={{
                  backgroundColor: theme.palette.secondary.chineseGray,
                  fontSize: '1.2em',
                  fontWeight: 'bold',
                  padding: '10px 0',
                  textAlign: 'center'
                }}>
                  INVOICE
                </h2>

                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <div style={{ textAlign: 'left', padding: "5px", maxWidth: 300 }}>
                    <p style={{ fontWeight: 600 }}>From</p>
                    <h4 style={{ fontWeight: "bold", color: theme.palette.text.dark }}>{infoData?.from?.corporateName}</h4>
                    <p>{infoData?.from?.address}</p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>TEL: </span> {infoData?.from?.phone}
                    </p>

                    <p>
                      <span style={{ fontWeight: "bold" }}>EMAIL: </span> {infoData?.from?.email}
                    </p>
                  </div>

                  <div style={{ textAlign: 'left', padding: "5px", maxWidth: 300 }}>
                    <p style={{ fontWeight: 600 }}>To</p>
                    <h4 style={{ fontWeight: "bold", color: theme.palette.text.dark }}>{infoData?.to?.corporateName}</h4>
                    {infoData?.to?.address && <p>{infoData?.to?.address}</p>}
                    {infoData?.to?.phone &&
                      <p>
                        <span style={{ fontWeight: "bold" }}>TEL: </span> {infoData?.to?.phone}
                      </p>
                    }

                    {infoData?.to?.email &&
                      <p>
                        <span style={{ fontWeight: "bold" }}>EMAIL: </span> {infoData?.to?.email}
                      </p>
                    }
                  </div>
                </div>


                <div style={{ align: "center", marginTop: "20px" }} >
                  <table width="100%" style={{ textAlign: "center" }} cellSpacing="0" cellPadding="5" border="0">
                    <thead>
                      <tr>
                        <th className={styles.tdHead}>Airline</th>
                        <th className={styles.tdHead}>Airline No.</th>
                        <th className={styles.tdHead}>From</th>
                        <th className={styles.tdHead}>To</th>
                        <th className={styles.tdHead}>Dep. Date</th>
                        <th className={styles.tdHead}>Arr. Date</th>
                        <th className={styles.tdHead}>Airline PNR</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.flightDetails.map((value, index) => {
                        return <tr key={index}>
                          <td className={styles.tdCell}>{value.airlLine}</td>
                          <td className={styles.tdCell}>{value.flightNo}</td>
                          <td className={styles.tdCell}>{value.origin}</td>
                          <td className={styles.tdCell}>{value.destination}</td>
                          <td className={styles.tdCell}>{value.departureDate}</td>
                          <td className={styles.tdCell}>{value.arrivalDate}</td>
                          <td className={styles.tdCell}>{value.ticket}</td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                </div>

                <div style={{ align: "center", marginTop: "20px" }}>
                  <table width="100%" style={{ textAlign: "center" }} cellSpacing="0" cellPadding="5" border="0">
                    <thead>
                      <tr>
                        <th className={styles.tdHead}>Sr. No.</th>
                        <th className={styles.tdHead}>Ticket No.</th>
                        <th className={styles.tdHead}>Sectors</th>
                        <th className={styles.tdHead}>Pax Name</th>
                        <th className={styles.tdHead}>Type</th>
                        <th className={styles.tdHead}>Baggage</th>
                        <th className={styles.tdHead}>Meal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.passengerDetails.map((value, index) => {
                        return <tr key={index}>
                          <td className={styles.tdCell}>{index + 1}</td>
                          <td className={styles.tdCell}>{value.eticketNO}</td>
                          <td className={styles.tdCell}>{value.origin}</td>
                          <td className={styles.tdCell}>{value.fullName}</td>
                          <td className={styles.tdCell}>{value.passengerType}</td>
                          <td className={styles.tdCell}><br /></td>
                          <td className={styles.tdCell}>{value.mealRequest}</td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                </div>

                <div style={{ marginTop: "20px" }}>
                  <table width="100%" cellSpacing="0" cellPadding="5" border="0">
                    <tbody>
                      <tr>
                        <td width="60%" className={styles.tdcellDividerTop}><br /></td>
                        <td className={styles.tdcellPayment}><span style={{ fontWeight: "bold" }}>Payment Details</span></td>
                        <td className={styles.tdcellPayment}>Amount (INR)</td>
                      </tr>
                      <tr>
                        <td><br /></td>
                        <td className={styles.tdcellLeft}><span>Air Fare</span></td>
                        <td className={styles.tdcellRight}>{currencyFormatter(data.fareDtlForInvoice.totalBaseFare)}</td>
                      </tr>
                      <tr>
                        <td><br /></td>
                        <td className={styles.tdcellLeft}><span>Tax & other charges</span></td>
                        <td className={styles.tdcellRight}>{currencyFormatter(data.fareDtlForInvoice.totalTax)}</td>
                      </tr>
                      {props.type === "user_invoice" && <tr>
                        <td><br /></td>
                        <td className={styles.tdcellLeft}><span>Other Charges</span></td>
                        <td className={styles.tdcellRight}>{currencyFormatter(data.fareDtlForInvoice.agentMarkup)}</td>
                      </tr>}
                      {props.type === "agent_invoice" &&
                        <>
                          <tr>
                            <td><br /></td>
                            <td className={styles.tdcellLeft}><span>Commission(-)</span></td>
                            <td className={styles.tdcellRight}>{currencyFormatter(data.fareDtlForInvoice.agentCommission)}</td>
                          </tr>
                          <tr>
                            <td><br /></td>
                            <td className={cx(styles.tdcellPaymentBottom, styles.tdcellLeft)}><span>TDS on Commission(+)</span></td>
                            <td className={cx(styles.tdcellPaymentBottom, styles.tdcellRight)}>{currencyFormatter(data.fareDtlForInvoice.taxPaidOnCommissionByAgent)}</td>
                          </tr>
                        </>
                      }
                      <tr>
                        <td><br /></td>
                        <td className={cx(styles.tdcellPaymentBottom, styles.tdcellLeft)}><span>Total</span></td>
                        <td className={cx(styles.tdcellPaymentBottom, styles.tdcellRight)}>{currencyFormatter(data.netFare)}</td>
                      </tr>
                      {/* <tr>
                        <td><br/></td>
                        <td style={styles.tdcellPaymentBottom}><span>Total (In Words)</span></td>
                        <td style={styles.tdcellPaymentBottom}>{data.netFareInWords}</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
                <div style={{ padding: "20px", fontWeight: "bold", textAlign: "center" }}>
                  <br /><br /><br /><br /><br />
                  This is a computer generated invoice, hence signature is not required
                  <br />
                </div>
              </div>
            </div>
          </OaCard>
        </>
      }
      <Dialog
        open={showCustomerInfoModal}
        onClose={hideCustomerInfoModal}
        PaperProps={{
          className: styles.dialogPaper,
        }}
        fullScreen={window.innerWidth < 980}
      >
        <div className="dialog-content">
          <button className='btn-close' onClick={hideCustomerInfoModal}>x</button>
          <div className='form-container'>
            <div className='form-element'>
              <label>Customer Name</label>
              <TextField
                value={infoData?.to?.corporateName || ""}
                onChange={onChangeCustomerName}
                variant='outlined'
                fullWidth
              />
            </div>
            <div className='form-element'>
              <label>Address</label>
              <TextField
                value={infoData?.to?.address || ""}
                onChange={onChangeCustomerAddress}
                variant='outlined'
                fullWidth
              />
            </div>
            <div className='form-element'>
              <button className='btn-add' onClick={hideCustomerInfoModal}>Close</button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default InvoiceFlight;
