import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PassengerInformation from "./PassengerInformation";
import SeatMealBag from "../seatMealBag/SeatMealBag";
import { Button, Grid } from "@material-ui/core";
import { useHistory, withRouter } from "react-router-dom";
import FlightDetails from "./FlightDetails";
import OaFormAlerts from "pages/components/OaFormAlerts";
import OaAcceptTermsConditions from "oahoc/OaAcceptTermsConditions";
import flightDetailsIcon from "assets/svg/flight-thumb.svg";

const PaxInfoAndAdditional = (props) => {
  const classes = useStyles();
  const history = useHistory();
  // console.log(props)

  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [updatePaxInfo, setUpdatePaxInfo] = React.useState(false);

  const [paxInfo, setPaxInfo] = React.useState(
    history.location?.state?.paxInfo
  );
  const [execTime, setExecTime] = React.useState(new Date());
  const [acceptTnc, setAcceptTnc] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [hasAdditionals, setHasAdditionals] = React.useState(false);

  const checkAdditionals = (mode) => {
    if (
      mode == "ONE" &&
      (history.location?.state?.onwardResp?.additionalServices?.seatMapping
        ?.length > 0 ||
        history.location?.state?.onwardResp?.additionalServices?.meal?.length >
          1 ||
        history.location?.state?.onwardResp?.additionalServices?.baggage
          ?.length > 1)
    ) {
      return true;
    }

    if (
      mode == "ROUND" &&
      (history.location?.state?.returnResp?.additionalServices?.seatMapping
        ?.length > 0 ||
        history.location?.state?.returnResp?.additionalServices?.meal?.length >
          1 ||
        history.location?.state?.returnResp?.additionalServices?.baggage
          ?.length > 1)
    ) {
      return true;
    }

    return false;
  };

  const onChangeAcceptTnc = (e) => {
    setAcceptTnc(e.target.checked);
  };

  const getPaxInfo = (values) => {
    setPaxInfo(values);
    setUpdatePaxInfo(false);
  };

  const submitPaxInfo = (values) => {
    if (acceptTnc) {
      history.push({
        pathname: "/flight/booking/review-details",
        state: {
          ...history.location.state,
          paxInfo: values,
          couponObj: props.couponobj,
          totalPayable: props.totalpayable,
          convenienceFees: props.convenienceFees,
        },
      });
    } else {
      setUpdatePaxInfo(false);
      setSnackbarOpen(true);
    }
  };

  const proceedToReviewDetails = () => {
    setUpdatePaxInfo(true);
  };

  React.useEffect(() => {
    props.setadditionalfare(paxInfo, execTime);
  }, [paxInfo, execTime]);

  React.useEffect(() => {
    props.setInitFlag(true, "/flight/booking/passenger-info");
    setHasAdditionals(
      checkAdditionals(history.location?.state?.requestObj?.mode)
    );
    props.setCalcFlightConv({
      flag: "revcalc",
      onwConvenienceFees: 0,
      retConvenienceFees: 0,
    });
    return () => {
      setHasAdditionals(false);
    };
  }, []);

  return (
    <div className={classes.root}>
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
        style={{ width: "100%" }}
      />
      <div className="section-container">
        <div className="section-title">
          <TitleComp fa="fa fa-plane" title="Flight Details" />
        </div>
        <div className="accordion__section">
          <FlightDetails {...props} />
        </div>
      </div>
      <div className="section-container">
        <div className="section-title">
          <TitleComp fa="fa fa-users" title="Traveller Details" />
        </div>
        <div className="accordion__section">
          <PassengerInformation
            paxinfo={paxInfo}
            updatepaxinfo={updatePaxInfo}
            setUpdatePaxInfo={setUpdatePaxInfo}
            getpaxinfo={(values) => getPaxInfo(values)}
            submitpax={(values) => submitPaxInfo(values)}
            {...props}
          />
        </div>
      </div>
      {hasAdditionals && (
        <div className="section-container">
          <div className="section-title" style={{ marginBottom: 0 }}>
            <TitleComp
              fa="fa fa-shopping-cart"
              title="Seats Meal and Baggage"
            />
          </div>
          <div className="accordion__section">
            <SeatMealBag
              paxinfo={paxInfo}
              setpaxinfo={(val) => setPaxInfo(val)}
              exectime={execTime}
              setexectime={(val) => setExecTime(val)}
              {...props}
            />
          </div>
        </div>
      )}
      <div className="section-container">
        <div className="terms-and-conditions">
          <div className="terms-and-conditonsNote">
            Note: Airlines may change their prices any minute. This fare is not
            guaranteed until the Airline PNR is generated. Generation of a
            Transaction ID (TID) is the confirmation of booking request and
            payment received by us and under no circumstances, is it equivalent
            to us final purchase price of respective Air ticket. There might be
            situations, where the fare displayed may increase by the time
            purchase is made with the respective Airline. In this event, we will
            revert to you with the new fare or alternate options regarding your
            flight booking.<br></br>{" "}
            <span>
              *There will be a TDS on the discounts / commissions on every
              transaction @5% for Permanent Account Number (PAN) holders and 20%
              if the agent is not having a PAN. Kindly update your PAN to save
              15% on TDS.
            </span>
          </div>
        </div>
      </div>
      <div className="section-container">
        <div className="terms-and-conditions">
          <OaAcceptTermsConditions
            type="flight"
            name="acceptTnc"
            termsAndConditions={props.tnc}
            pricingPolicy={props.ppc}
            value={acceptTnc}
            onChange={onChangeAcceptTnc}
            snackbarOpen={snackbarOpen}
            setSnackbarOpen={setSnackbarOpen}
          />
        </div>
      </div>
      <Grid container item className="button-holder">
        <Button className="action-button" onClick={proceedToReviewDetails}>
          <Typography>Proceed</Typography>
        </Button>
      </Grid>
    </div>
  );
};

export default withRouter(PaxInfoAndAdditional);

const TitleComp = (props) => {
  return (
    <div className="section-title-wrapper">
      <span className="heading-icon">
        <i className={props.fa} />
      </span>
      <Typography className="heading-text">{props.title}</Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "1440px !important",
    "& .heading": {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 500,
    },
    "& .button-holder": {
      marginTop: 20,
      // marginBottom: 20,
      justifyContent: "right",
      [theme.breakpoints.down(450)]: {
        bottom: 15,
        right: 30,
        position: "absolute",
      },
      // paddingRight: "1rem",
      // [theme.breakpoints.down(986)]: {
      //     display: 'none'
      // }
      // marginRight:'2rem'
    },
    "& .section-title-wrapper": {
      display: "flex",
      alignItems: "center",
    },
    "& .heading-icon": {
      borderRadius: "50%",
      background: theme.palette.background.disabled,
      width: 30,
      height: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 10,
      "& i": {
        fontSize: 16,
        color: theme.palette.text.secondary,
      },
    },
    "& .heading-text": {
      textTransform: "uppercase",
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
    "& .action-button": {
      //background: theme.palette.primary.main,
      background: theme.palette.buttons.tertiary,
      padding: "5px",
      textAlign: "center",
      color: theme.palette.buttons.tertiaryContrastText,
      width: 130,
      height: 40,
      borderRadius: 5,
      fontSize: 14,
      fontWeight: 500,
      appearance: "none",
      MozAppearance: "none",
      WebkitAppearance: "none",
      border: "none !important",
      outline: "none !important",
      cursor: "pointer",
      margin: "0 0.5rem",
      "&:hover": {
        //background: theme.palette.primary.active,
      },
    },
    "& .terms-and-conditions": {
      padding: "20px 10px 15px",
      "& .terms-and-conditonsNote": {
        fontSize: 14,
        fontWeight: 500,
        "& span": {
          color: "red",
        },
      },
    },
  },
}));
