import React from "react";
import masterCard from "assets/Gostree/images/master.png";
import american from "assets/Gostree/images/american.png";
//import paypal from "assets/Gostree/images/paypal.png";
import upi from "assets/upi.png";
import visa from "assets/Gostree/images/visa.png";
import rupay from "assets/Gostree/images/rupay.png";
import pci from "assets/Gostree/images/pci-logo.png";
import travlogy_logo from "assets/travlogy/images/travlogy_logo_white.png";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";

//import TAAFI from "assets/Gostree/images/tafi-logo-1.png";
//import IATA from "assets/Gostree/images/iata-logo-1.png";

import IATA from "assets/Gostree/images/home/iata.jpg";
import TAAFI from "assets/Gostree/images/home/taafilogo.jpg";
import TAAI from "assets/Gostree/images/home/taai.jpg";
import irctc from "assets/Gostree/images/irctc.png";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AppConstant } from "appConstant";
import { useHistory } from "react-router-dom";

export const FooterSection = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [currentLink, setCurrentLink] = React.useState(
    flyBackIndiaLinks[0].value
  );

  const isMobile = useMediaQuery(theme.breakpoints.down(760));

  // const loadTawkChat = () => {
  //   window.Tawk_API.popup();
  // };

  // const loadTawkScript = () => {
  //   return new Promise((resolve) => {
  //     const script = document.createElement("script");
  //     script.async = true;
  //     script.src = 'https://embed.tawk.to/5808d25bc7829d0cd36a05d8/1f105kk9m';
  //     script.setAttribute('crossorigin', '*');
  //     const s0 = document.getElementsByTagName("script")[0];
  //     s0.parentNode.insertBefore(script, s0);
  //     script.onload = () => {
  //       resolve(true);
  //     };
  //     script.onerror = () => {
  //       resolve(false);
  //     };
  //   });
  // };

  const onSelectLink = (e) => {
    console.log(e.target.value);
    const selected = flyBackIndiaLinks.filter(
      (v) => v.value === e.target.value
    )[0];
    setCurrentLink(selected.value);
    let anch = document.createElement("a");
    anch.setAttribute("target", "_blank");
    anch.setAttribute("href", selected.link);
    anch.click();
  };

  // React.useEffect(() => {
  //   loadTawkScript().then(loaded => {
  //     if (!loaded) {
  //       return console.log("unable to communicate with Tawkto servers!");
  //     }
  //   });
  // }, []);

  return (
    <div className={classes.root}>
      <div className="footer__section">
        <div className="footerContent">
          <div className="footerContentData">
            <PhoneIcon />
            +91 73899 08892
          </div>
          <div className="footerContentData">
            <MailIcon />
            support@geebaku.com
          </div>
        </div>
        <div className="footerContent">© Geebaku 2024</div>
        <div className="footerContent">
          <div className="ftr_social">
            {/* <img src={IATA} alt="IATA Agent" /> */}
            {/* <img src={TAAFI} alt="TAAFI" /> */}
            <img src={irctc} alt="TAAI" />
          </div>
        </div>
      </div>

      {/* <div className="copyRight">
        <p className="copyright-text">
          Hand Crafted by{" "}
          <a href="http://travlogy.co" target="_blank">
            <img src={travlogy_logo} />
          </a>
        </p>
        <div></div>
        <img src={FooterImg} alt="" />
      </div> */}
    </div>
  );
};

export default FooterSection;

const LinkSelect = (props) => (
  <Select native value={props.value} onChange={props.onChange} {...props}>
    {flyBackIndiaLinks.map((link, ind) => (
      <option
        value={link.value}
        key={ind}
        data-img_src="https://sdtimes.com/wp-content/uploads/2018/09/Java-logo-490x301.jpg"
        // style="background-image:url(travlogy_logo);"
        // style="background-image:url(india.png)"
      >
        {link.label}
      </option>
    ))}
  </Select>
);

const flyBackIndiaLinks = [
  {
    value: "flybackindia-usa",
    label: "Flybackindia-USA",
    link: "https://flybackindia.com",
  },
  {
    value: "flybackindia-can",
    label: "Flybackindia-CANADA",
    link: "https://flybackindia.com",
  },
];

const footerLinks = [
  // {
  //   linkHeader: "OUR PRODUCTS",
  //   links: [
  //     {
  //       title: "Book Flight",
  //       url: "/flights",
  //     },
  //     // {
  //     //     title: "Book Flight",
  //     //     url: "/flights",
  //     // },
  //     {
  //       title: "Search Hotels",
  //       url: "/hotels",
  //     },
  //     {
  //       title: "Search Buses",
  //       url: "/buses",
  //     },
  //     // {
  //     //     title: "Hotel",
  //     //     url: "/flights",
  //     // },
  //     // {
  //     //     title: "Holiday Packages",
  //     //     url: "",
  //     // },
  //   ],
  // },
  // {
  //     linkHeader: "Contact Info",
  //     links: [
  //         {
  //             title: 'info@triumphhtravel.comy',
  //             url: "",
  //         },
  //         {
  //             title: '+91 1141 611 366',
  //             url: "",
  //         },
  //         {
  //             title: '+91 1141 611 366',
  //             url: "",
  //         },
  //         {
  //             title: '609, Vishwa Sadan Building, Janakpuri',
  //             url: "",
  //         },
  //         {
  //             title: 'District Centre, New Delhi - 110058',
  //             url: "",
  //         },
  //     ]
  // },
];

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "auto",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down(980)]: {
      marginTop: 10,
    },
    position: "relative",
    // "& .why-us-outer-container": {
    //     backgroundImage: `url(${FooterHeadBg})`,
    //     backgroundSize: 'contain',
    //     backgroundPosition: 'center'
    // },
    "& .footer__section": {
      maxWidth: "1440px",

      // marginTop: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      flexWrap: "wrap",
      [theme.breakpoints.down(980)]: {
        flexDirection: "column",
      },
      position: "relative",
      backgroundColor: theme.palette.primary.main,
      padding: "0.5rem 1.5rem",
      width: "100%",
      "& .footerContent": {
        padding: "20px 0px",
        color: "#fff",

        "& .footerContentData": {
          display: "flex",
          alignItems: "center",
          gap: "10px",
        },
        "& .ftr_social": {
          display: "flex",
          gap: "20px",
          "& img": {
            height: "80px",
            width: "80px",
            padding: 5,
            backgroundColor: "#fff",
            borderRadius: 15,
            // borderRadius: "50%",
          },
        },
      },
      "& .footer__left": {
        width: "50%",
        [theme.breakpoints.down(980)]: {
          flexDirection: "column",
          width: "100%",
        },
        borderRight: "1px solid #505050",
        padding: "0 0.3rem",
        gap: "0.3rem",
        display: "flex",
        "& .ol-list": {
          width: "100%",
          float: "left",
          color: "#fff",
          "& .ol__header": {
            marginTop: "1rem",
            width: "100%",
            fontSize: "12px",
            fontWeight: "bold",
            fontFamily: theme.palette.font.primary,
          },
          "& ol": {
            marginTop: "0.5rem",
            listStyle: "none",
            padding: "0",
            color: theme.palette.primary.lightText,
            transition: "all 0.8s ease 0s",
            margin: "10px,0",
            "& li": {
              width: "100%",
            },

            "& li a": {
              color: theme.palette.primary.lightText,
              fontSize: "12px",
              transition: "all 0.8s ease 0s",
              fontFamily: theme.palette.font.primary,
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
                marginLeft: "6px",
              },
            },
          },
        },
        "& .link-select-wrapper": {
          maxWidth: 160,
          marginTop: 0,
          "& .MuiSvgIcon-root": {
            color: theme.palette.text.light,
          },
          "& .MuiInputBase-input": {
            color: theme.palette.text.light,
            fontSize: 13.5,
          },
          "& .MuiOutlinedInput-root": {
            border: `solid 1px ${theme.palette.text.light}`,
            height: 40,
            "&:hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "unset",
                borderWidth: 0,
              },
            },
            "& option": {
              backgroundColor: theme.palette.background.dark,
              color: theme.palette.text.light,
              height: 30,
              cursor: "pointer",
            },
          },
        },
      },
      "& .footer__right": {
        width: "40%",
        //borderRight: `1px solid ${theme.palette.primary.lightGray}`,
        padding: "1rem 0",
        color: theme.palette.primary.lightText,
        flex: "wrap",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down(980)]: {
          flexDirection: "column",
          width: "100%",
        },
        "& .ftr_social": {
          width: "100%",
          position: "relative",
          // paddingLeft: "5rem",
          display: "flex",
          // gap:"30px",
          justifyContent: "flex-start",
          // padding: "0 65px",
          [theme.breakpoints.down(980)]: {
            padding: "0 0.5rem",
            flexDirection: "column",
          },
          "& .pay-card": {
            display: "flex",
            flexWrap: "wrap",
            // [theme.breakpoints.down(980)]: {
            //    flexDirection: "column",
            //  },
            [theme.breakpoints.down(1026)]: {
              flexWrap: "wrap",
            },
            gap: "0.5rem",
            marginTop: "1.3rem",
            "& img": {
              width: "56px",
              height: "39px",
            },
          },
          "& .col-right": {
            margin: 20,
            display: "flex",
            justifyContent: "space-between",
            //paddingLeft: 45,
            [theme.breakpoints.down(960)]: {
              margin: "5px 5px 5px -15px",
            },
            maxWidth: 200,
            "& img": {
              border: "4px solid #c2d87e",
              borderRadius: "50%",
              margin: "5px",
              width: "80px",
              height: "80px",
            },
          },
          "& .social-link": {
            display: "flex",
            gap: "0.5rem",
            padding: "0",
            listStyle: "none",
            marginTop: "1.6rem",
            "& li": {
              position: "relative",
              width: "30px",
              height: "30px",
              padding: "0.5rem",
              borderRadius: `1px solid ${theme.palette.primary.lightText}`,
              "& a": {
                textDecoration: "none",
                color: theme.palette.primary.lightText,
                fontSize: "1.3rem",
                // textAlign
              },
            },
          },
        },
      },
    },
    "& .copyRight": {
      width: "100%",
      justifyContent: "space-between",
      display: "flex",
      backgroundColor: theme.palette.primary.darkText,
      padding: "1rem 1.8rem",
      borderTop: `1px solid ${theme.palette.primary.lightGray}`,
      color: theme.palette.primary.lightText,
      "& .copyright-text": {
        fontSize: 9,
        "& img": {
          height: 14,
          margin: "0 0 0px 5px",
        },
      },
      // "& img": {
      //     paddingRight: "6rem",
      //     [theme.breakpoints.down(980)]: {
      //         paddingRight: "3rem",
      //         width: "20rem",
      //     },
      // },
    },
  },
}));
