import React from "react";
import {
  Button,
  Dialog,
  Divider,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import moment from "moment";
import { GET_FILE_UPLOAD } from "api/ApiConstants";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PrintIcon from "@material-ui/icons/Print";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DescriptionIcon from "@material-ui/icons/Description";
import CancelIcon from "@material-ui/icons/Cancel";
import WebApi from "api/ApiConstants";
import { basename } from "api/ApiConstants";
import FareDetails from "./FareDetails";
import Itinerary from "./Itinerary";

const MyBookingsFlight = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [userType, setUserType] = React.useState(null);
  const [flightList, setFlightList] = React.useState(null);
  const [openFareDetails, setOpenFareDetails] = React.useState(false);
  const [openItinerary, setOpenItinerary] = React.useState(false);
  //const [trType, setTrType] = React.useState(null);

  React.useEffect(() => {
    if (props.usertype === "R") {
      // console.log("props.refId gg" , props.location.state.refId);
      WebApi.getB2CFlightList(
        { userType: props.usertype },
        (response) => {
          setFlightList(response.data);
        },
        (error) => {
          console.log("getB2CFlightList error", error);
        }
      );
    } else {
      // console.log("props.refId " , props.location.state.refId);
      WebApi.getB2CFlightList(
        { userType: props.usertype, refId: props.location.state.refId },
        (response) => {
          setFlightList(response.data);
        },
        (error) => {
          console.log("getB2CFlightList error", error);
        }
      );
    }
  }, []);

  React.useEffect(() => {
    if (props.usertype === "G") {
      WebApi.getB2CFlightList(
        { userType: props.usertype, refId: props.location.state.refId },
        (response) => {
          setFlightList(response.data);
        },
        (error) => {
          console.log("getB2CFlightList error", error);
        }
      );
    }
  }, []);

  // console.log(props.usertype,"jkasdhjhsgad")
  // console.log(flightList,"jkasdhjhsgad22")

  return (
    flightList?.length > 0 && (
      <Grid
        item
        md={12}
        sx={12}
        sm={12}
        style={{
          background: "#fff",
          paddingBottom: "6px",
          textAlign: "center",
          // border: "1px solid #bfb6b6",
          padding: isMobile ? 5 : "20px",
          borderRadius: "5px",
        }}
      >
        <Grid
          container
          item
          md={12}
          justifyContent="flex-end"
          alignItems="center"
          style={{ marginBottom: 5 }}
        >
          {/* <Grid item style={{ textAlign: "end" }}>
                      <Typography className={classes.typo10}>
                        Filter/ Sort By
                      </Typography>
                      <select
                        value={value}
                        onChange={(event) => setValue(event.target.value)}
                      >
                        <option value="1">Relevance</option>
                        <option value="2">Booking Date</option>
                      </select>
                    </Grid> */}
        </Grid>

        {flightList &&
          flightList.map((ticket, index) => (
            <Grid
              key={index}
              container
              item
              md={12}
              style={{
                background: "#fff",
                border: "1px solid #dcdcdc",
                borderRadius: "5px",
                boxShadow: "0px 0px 6px -1px #b3b3b3",
                marginBottom: 20,
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{
                  background: "#e2e2e2",
                  padding: "10px",
                }}
              >
                <Grid item container md={4} xs={6}>
                  <Grid item>
                    <i
                      className="fa fa-plane"
                      aria-hidden="true"
                      style={{ opacity: 0.5, fontSize: 22 }}
                    ></i>
                  </Grid>
                  <div>
                    <div className={classes.typo1}>
                      Total Passenger(s):{" "}
                      {ticket.onwardFlightDetails.summary.adult +
                        ticket.onwardFlightDetails.summary.child +
                        ticket.onwardFlightDetails.summary.infant}
                      {/* <span style={{ fontSize: 16, color: "red" }}>
                                {index}
                              </span> */}
                    </div>
                    <div className={classes.typo1} style={{ opacity: 0.7 }}>
                      {`ADULT:${ticket.onwardFlightDetails.summary.adult}  CHILD:${ticket.onwardFlightDetails.summary.child}  INFANT:${ticket.onwardFlightDetails.summary.infant}`}
                    </div>
                  </div>
                </Grid>
                <Grid item container md={6} xs={6} justifyContent="flex-end">
                  <Grid item>
                    <Grid container>
                      <Typography className={classes.typo2}>
                        Departure Date:{" "}
                        {moment(
                          ticket.onwardFlightDetails.summary.deptDate
                        ).format("DD MMM'YY")}
                      </Typography>
                      <Typography className={classes.typo3}>
                        PNR No.
                        {ticket.onwardFlightDetails.summary.pnrNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container style={{ padding: 15 }}>
                <Grid
                  container
                  item
                  xs={12}
                  md={8}
                  style={{
                    borderRight: isMobile ? "none" : "1px solid #ababab",
                    paddingRight: isMobile ? 0 : 25,
                    paddingBottom: 25,
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={6}>
                      <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={6}
                          md={2}
                          style={{ textAlign: "center" }}
                        >
                          <img
                            src={
                              GET_FILE_UPLOAD +
                              "?fileName=" +
                              ticket.onwardFlightDetails.summary.airlineCode +
                              ".png&type=AIRLINE_IMAGES"
                            }
                            width={28}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={10}
                          style={{ textAlign: "left" }}
                        >
                          <Typography className={classes.typo4}>
                            {`${ticket.onwardFlightDetails.summary.airline}(${ticket.onwardFlightDetails.summary.airlineCode} - ${ticket.onwardFlightDetails.summary.flightNumber})`}
                          </Typography>
                          <Typography className={classes.typo5}>
                            {ticket.onwardFlightDetails.summary.bookingClass ===
                            "E"
                              ? "Economy"
                              : "Business"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={3} xs={6}>
                      {/* <Typography className={classes.typo4}>
                                PNR :{" "}
                                {ticket.onwardFlightDetails.summary.pnrNumber}
                              </Typography> */}
                      <Typography className={classes.typo5}>
                        {ticket.onwardFlightDetails.summary.isRefundable}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginTop: "15px" }}
                  >
                    <Grid item xs={5} md={3}>
                      <Typography className={classes.typo6}>
                        {ticket.onwardFlightDetails.summary.deptAirport !==
                          null &&
                          ticket.onwardFlightDetails.summary.deptAirport.split(
                            ","
                          )[1]}
                      </Typography>
                      <Typography className={classes.typo7}>
                        {moment(
                          ticket.onwardFlightDetails.summary.deptDate
                        ).format("DD MMM'YY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      {/* <i
                                className="fa fa-long-arrow-right"
                                aria-hidden="true"
                                style={{ fontSize: "35px", color: "#4e4c4c" }}
                              ></i> */}
                      <ArrowForwardIcon
                        style={{ fontSize: "35px", color: "#4e4c4c" }}
                      />
                    </Grid>
                    <Grid item xs={5} md={3}>
                      <Typography className={classes.typo6}>
                        {ticket.onwardFlightDetails.summary.arrAirport !==
                          null &&
                          ticket.onwardFlightDetails.summary.arrAirport.split(
                            ", "
                          )[1]}
                      </Typography>
                      <Typography className={classes.typo7}>
                        {moment(
                          ticket.onwardFlightDetails.summary.arrDate
                        ).format("DD MMM'YY")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginTop: 15 }}
                  >
                    <Grid item md={3} xs={6}>
                      <Typography className={classes.typo8}>
                        {`${ticket.passengerDetails[0].title}. ${ticket.passengerDetails[0].givenname} ${ticket.passengerDetails[0].surname}`}
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <Typography className={classes.typo8}>
                        Status{" "}
                        <span style={{ color: "#007bff" }}>
                          {ticket.status}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid continer item xs={12} md={4} style={{ paddingLeft: 15 }}>
                  <Grid container justifyContent="space-evenly" spacing={1}>
                    <Grid item>
                      <Button
                        className={classes.button}
                        onClick={() =>
                          // props.history.push("/b2c/faredetails", {
                          //   flightTicketDetails: ticket,
                          //   index,
                          // })
                          setOpenFareDetails(true)
                        }
                      >
                        Fare Details
                      </Button>
                    </Grid>
                    <Grid item>
                      {ticket.status &&
                        (ticket.status != "Pending" &&
                          ticket.status != "SUCCESSCan") && (
                          <Button
                            className={classes.button}
                            // onClick={() =>
                            //   props.history.push({
                            //     pathname: "/b2c/itinerary",
                            //     state: {
                            //       ticket: ticket,
                            //       index: index,
                            //       userType: props.usertype,
                            //     },
                            //   })
                            // }
                            onClick={() => {
                              setOpenItinerary(true);
                            }}
                          >
                            Cancel Ticket
                          </Button>
                        )}
                    </Grid>
                  </Grid>
                  <Dialog
                    open={openFareDetails}
                    onClose={() => {
                      setOpenFareDetails(false);
                    }}
                    fullWidth={true}
                    maxWidth={"md"}
                  >
                    <FareDetails
                      flightTicketDetails={ticket}
                      userType={props.usertype}
                    />
                  </Dialog>
                  <Dialog
                    open={openItinerary}
                    onClose={() => {
                      setOpenItinerary(false);
                    }}
                    fullWidth={true}
                    maxWidth={"md"}
                  >
                    <Itinerary ticket={ticket} />
                  </Dialog>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    {ticket.status &&
                      (ticket.status != "Pending" &&
                        ticket.status != "SUCCESSCan") && (
                        <Button
                          className={classes.button}
                          style={{ width: "11rem" }}
                          onClick={() =>
                            // props.history.push({
                            //   pathname: "/b2c/itinerary",
                            //   state: {
                            //     ticket: ticket,
                            //     index: index,
                            //     userType: props.usertype,
                            //   },
                            // })
                            setOpenItinerary(false)
                          }
                        >
                          Check Refund Status
                        </Button>
                      )}
                  </Grid>
                </Grid>
              </Grid>
              {ticket.status &&
                (ticket.status != "Pending" &&
                  ticket.status != "SUCCESSCan") && (
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                      borderTop: "1px solid #9e9e9e",
                      margin: "0px 15px",
                      padding: "15px 0px",
                    }}
                  >
                    <Grid
                      item
                      style={{ padding: "0px 10px", cursor: "pointer" }}
                      onClick={() => {
                        // props.history.push(
                        //     "/print/flightticket/" + ticket.tid
                        // );
                        window.open(
                          basename + "/print/flightticket/" + ticket.tid,
                          "_blank"
                        );
                      }}
                    >
                      {/* <i
                            className="fa fa-ticket"
                            aria-hidden="true"
                            style={{
                              textAlign: "center",
                              fontSize: "19px",
                              paddingBottom: "7px",
                              color: "#6f6f6f",
                            }}
                          ></i> */}
                      <PrintIcon
                        style={{
                          textAlign: "center",
                          fontSize: "25px",
                          paddingBottom: "7px",
                          color: "#6f6f6f",
                        }}
                      />
                      <Typography className={classes.typo9}>Ticket</Typography>
                    </Grid>

                    <Grid
                      item
                      style={{ padding: "0px 10px", cursor: "pointer" }}
                      onClick={() => {
                        // props.history.push(
                        //     "/print/flightticket/" + ticket.tid
                        // );
                        window.open(
                          basename + "/print/flightticket/" + ticket.tid,
                          "_blank"
                        );
                      }}
                    >
                      {/* <i
                            className="fa fa-download"
                            aria-hidden="true"
                            style={{
                              textAlign: "center",
                              fontSize: "19px",
                              paddingBottom: "7px",
                              color: "#6f6f6f",
                            }}
                          ></i> */}
                      <ArrowDownwardIcon
                        style={{
                          textAlign: "center",
                          fontSize: "25px",
                          paddingBottom: "7px",
                          color: "#6f6f6f",
                        }}
                      />
                      <Typography className={classes.typo9}>Ticket</Typography>
                    </Grid>
                    <Grid
                      item
                      style={{ padding: "0px 10px", cursor: "pointer" }}
                      onClick={() => {
                        // props.history.push(
                        //     "/print/voucher/flight-user/" + ticket.tid
                        // );
                        window.open(
                          basename + "/print/voucher/flight-user/" + ticket.tid,
                          "_blank"
                        );
                      }}
                    >
                      {/* <i
                            className="fa fa-sticky-note-o"
                            aria-hidden="true"
                            style={{
                              textAlign: "center",
                              fontSize: "19px",
                              paddingBottom: "7px",
                              color: "#6f6f6f",
                            }}
                          ></i> */}
                      <DescriptionIcon
                        style={{
                          textAlign: "center",
                          fontSize: "25px",
                          paddingBottom: "7px",
                          color: "#6f6f6f",
                        }}
                      />
                      <Typography className={classes.typo9}>
                        Print Invoice
                      </Typography>
                    </Grid>
                    {/* <Grid
                      item
                      style={{ padding: "0px 10px", cursor: "pointer" }}
                      onClick={() => {
                        props.history.push({
                          pathname: "/b2c/itinerary",
                          state: {
                            ticket: ticket,
                            index: index,
                            userType: props.usertype,
                          },
                        });
                      }}
                    >
                    
                      <CancelIcon
                        style={{
                          textAlign: "center",
                          fontSize: "25px",
                          paddingBottom: "7px",
                          color: "#6f6f6f",
                        }}
                      />
                      <Typography className={classes.typo9}>
                        Cancel Ticket
                      </Typography>
                    </Grid> */}
                    {/* <Grid item style={{ padding: "0px 10px" }}>
                          <i
                            className="fa fa-commenting-o"
                            aria-hidden="true"
                            style={{
                              textAlign: "center",
                              fontSize: "19px",
                              paddingBottom: "7px",
                              color: "#6f6f6f",
                            }}
                          ></i>
                          <Typography className={classes.typo9}>
                            SMS/Email Ticket
                          </Typography>
                        </Grid> */}
                  </Grid>
                )}
            </Grid>
          ))}
      </Grid>
    )
  );
};

export default MyBookingsFlight;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: "auto",
    //paddingTop: "40px",
    paddingBottom: "40px",
    //marginTop: 100,
    "& .MuiBox-root": {
      padding: 0,
    },
    "& .MuiTab-textColorInherit": {
      "&.Mui-selected": {
        color: theme.palette.secondary.main,
      },
    },
    "& .PrivateTabIndicator-colorSecondary": {
      backgroundColor: theme.palette.secondary.main,
    },
    "& .b2c-tab-buttons": {
      background: theme.palette.secondary.disabled,
      position: "relative",
      width: "100%",
    },
    "& .b2c-tab-panel": {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  backButton: {
    position: "absolute",
    right: 10,
    top: 10,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.main,
    "& .MuiSvgIcon-root": {
      fontSize: 16,
      marginRight: 5,
    },
    "&:hover": {
      color: theme.palette.primary.sub,
    },
  },
  typo1: {
    fontSize: "11px",
    fontWeight: 500,
    lineHeight: "1.5",
    color: "#212529",
    marginLeft: 5,
    width: "fit-content",
    letterSpacing: "0.5px !important",
  },

  typo2: {
    fontSize: "13px",
    paddingRight: "10px",
    fontWeight: 700,
    color: "#212529",
    letterSpacing: "0.5px !important",
    borderRight: "1px solid",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
    },
  },
  typo3: {
    fontSize: "13px",
    fontWeight: 700,
    color: "#212529",
    letterSpacing: "0.5px !important",
    paddingLeft: "10px",
  },
  typo4: {
    fontSize: 12,
    color: "#000",
    fontWeight: 500,
  },
  typo5: {
    fontSize: 11,
    color: "#000",
    fontWeight: 300,
    opacity: 0.7,
  },
  typo6: {
    fontSize: 16,
    color: "#000",
    fontWeight: 400,
  },
  typo7: {
    fontSize: 12,
    color: "grey",
    fontWeight: 400,
  },
  typo8: {
    fontSize: 11,
    fontWeight: 300,
  },
  typo9: {
    color: "#6f6f6f",
    fontSize: 12,
  },
  typo10: {
    fontSize: 12,
    color: "#828080",
    fontWeight: 500,
  },
  button: {
    background: theme.palette.secondary.main,
    color: "#fff",
    fontSize: 13,
    textAlign: "center",
    padding: 8,
    borderRadius: 10,
    textTransform: "capitalize",
    "&:hover": {
      background: "#2b5a85",
    },
  },
}));
