import React, { useEffect, useState } from "react";
import WebApi from "api/ApiConstants";
import FlightItinerary from "./FlightItinerary";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { Button, makeStyles, Typography } from "@material-ui/core";
import {
  CheckCircle,
  CloudDownloadOutlined,
  EmailOutlined,
  PrintOutlined,
} from "@material-ui/icons";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { useHistory } from "react-router-dom";
import { pdfDownload, getImageData } from "oautils/oaPdfUtils";
import { apiCall } from "oautils/oaDataUtils";
import { localforageGetItem, localforageSetItem } from "oautils/oaForageUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AppConstant } from "appConstant";
import { DOWNLOAD_FLIGHT_TICKET_PDF } from "api/ApiConstants";
import OaButtonDownloadPDF from "pages/components/OaButtonDownloadPDF";

const PaymentSuccess = (props) => {
  const maxRetryCount = 8;
  const classes = useStyles();
  const history = useHistory();
  const [bookingProcess, setBookingProcess] = useState("confirmed");

  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [refId, setRefId] = React.useState(history.location.state.Tid);
  const [flightData, setFlightData] = React.useState(null);
  const [state, setState] = React.useState({
    isAddressHide: false,
    isFareHide: false,
    isPerPassenger: false,
    isGSTHide: false,
  });
  const [userDetails, setUserDetails] = React.useState(null);
  const isMounted = React.useRef(false);
  const [retryCount, setRetryCount] = useState(0);

  const flightTicketDownloadRequest = {
    flightDetailReq: {
      loggedInUserId: "0",
      transactionId: history.location.state.Tid,
    },
    currency: "INR",
    isAddressHide: false,
    isFareHide: false,
    isGSTHide: true,
    isPerPassenger: true,
  };

  const printTicket = () => {
    history.push({
      pathname:
        "/print/flightticket/" +
        history.location.state.Tid +
        "/" +
        state?.isAddressHide +
        "/" +
        state?.isFareHide +
        "/" +
        state?.isPerPassenger +
        "/" +
        state?.isGSTHide,
    });
  };

  const getTicketData = (transactionId) => {
    return new Promise((resolve, reject) => {
      WebApi.getTicketData(
        { transactionId: transactionId },
        (response) => {
          if (response !== null && response.success === true) {
            resolve(response.data);
          }
        },
        (error) => {
          reject([]);
          // console.log(error);
        }
      );
    });
  };

  // useEffect(() => {
  //   let tid = history.location.state.Tid
  //     ? history.location.state.Tid
  //     : props.match.params?.tid;
  //   // getUserDetails(isMounted.current);
  //   if (tid > 0) {
  //     let counter = 0
  //     initiateTicketStatus(tid, counter);
  //   }
  // }, [isMounted.current]);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault(); // Required for some browsers
  //     event.returnValue = ""; // This triggers the browser's confirmation dialog
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  // useEffect(() => {
  //   const preventReload = (event) => {
  //     // Block F5
  //     if (event.key === "F5" || (event.ctrlKey && event.key === "r")) {
  //       event.preventDefault();
  //       console.log("Reload prevented");
  //     }
  //   };

  //   const preventBeforeUnload = (event) => {
  //     // Prevent page reload via navigation or close
  //     event.preventDefault();
  //     event.returnValue = ""; // Required by some browsers
  //   };

  //   window.addEventListener("keydown", preventReload);
  //   window.addEventListener("beforeunload", preventBeforeUnload);

  //   return () => {
  //     window.removeEventListener("keydown", preventReload);
  //     window.removeEventListener("beforeunload", preventBeforeUnload);
  //   };
  // }, []);

  const downloadEticket = (event) => {
    event.target.setAttribute("disabled", "disabled");
    pdfDownload(
      "section-to-print",
      "p",
      "eticket-" + history.location.state.Tid
    );
    event.target.removeAttribute("disabled");
  };

  //   function downloadETicket(e){
  //     e.target.setAttribute('disabled', 'disabled');
  //     pdfDownload('section-to-print', 'p', 'eticket-' + ticketId)
  //     e.target.removeAttribute('disabled');
  // }

  const emailETicket = () => {
    flightData &&
      getImageData("section-to-print", "p", function (imageData) {
        apiCall(
          WebApi.emailETicket,
          {
            email: flightData.emailId,
            body:
              "<!DOCTYPE html><html><body><img src='" +
              imageData +
              "'/></body></html> ",
          },
          function (response) {
            if (response.success === true) {
              setIsError(false);
              setIsSuccess(true);
              setIsSaving(false);
              // setInfoText("Send email request registered");
              setInfoText(`Email Sent to ${flightData.emailId}`);
            } else {
              setIsError(true);
              setIsSaving(false);
              setInfoText("Email server busy try after sometime");
            }
          }
        );
      });
  };

  const getValuesFromLocalStorage = () => {
    localforageGetItem("user-details", function (err, value) {
      if (value) {
        setUserDetails(value);
      }
    });
  };

  const refreshBalanceBalance = (usrDetails) => {
    apiCall(WebApi.getDashboardData, {}, function (response) {
      let mainAccountBalance = response.data.mainAccountBalance;
      localforageSetItem("user-details", {
        ...usrDetails,
        cashBalance: mainAccountBalance,
      });
    });
  };

  function sendEmail() {
    setIsSaving(true);
    getImageData("section-to-print", "p", function (imageData) {
      apiCall(
        WebApi.emailETicket,
        {
          email: "vikas.kumawat66@gmail.com",
          body:
            "<!DOCTYPE html><html><body><img src='" +
            imageData +
            "'/></body></html> ",
        },
        function (response) {
          if (response.success === true) {
            setIsError(false);
            setIsSuccess(true);
            setIsSaving(false);
            setInfoText("Send email request registered");
          } else {
            setIsError(true);
            setIsSaving(false);
            setInfoText("Email server busy try after sometime");
          }
        }
      );
    });
  }

  const getFlightTicketData = () => {
    let tid = history.location.state.Tid
      ? history.location.state.Tid
      : props.match.params?.tid;
    if (userDetails) {
      WebApi.getTicketData(
        { transactionId: tid },
        (response) => {
          // console.log("response", response);
          if (response.success == true) {
            setRefId(response.data.bookingRef);
            setFlightData(response.data);
            if (userDetails?.partnerId === 1002 && retryCount < 1) {
              refreshBalanceBalance(userDetails);
            }
          }
        },
        (error) => console.log("error", error)
      );
    }
  };

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (isMounted.current) getValuesFromLocalStorage();
  }, [isMounted.current]);

  React.useEffect(() => {
    getFlightTicketData();
  }, [userDetails]);

  React.useEffect(() => {
    if(flightData && retryCount <= maxRetryCount) {
      if(flightData.bookingStatus.toLowerCase() === "successtkd") {
        setBookingProcess("confirmed");
      } else if(flightData.bookingStatus.toLowerCase() === "success" && retryCount < maxRetryCount) {
        setBookingProcess("processing");
        setTimeout(() => {
          setRetryCount(prevState => (prevState + 1));
          getFlightTicketData();
        }, 3500);
      } else if(flightData.bookingStatus.toLowerCase() !== "successtkd" && 
        flightData.bookingStatus.toLowerCase() !== "success" && 
        retryCount === maxRetryCount) {
        setBookingProcess("failed");
      }
    }
  }, [retryCount, flightData]);

  return (
    <div className={classes.root}>
      <div className="section-container" style={{ width: "100%" }}>
        {/* <div>
          <div className="icon-group">
            <CheckCircle />
            <Typography>
              Reference id: <b>{refId}</b>
            </Typography>
            <Typography className="ticketDetailsText">
              Booking Successful.{" "}
              <a
                style={{ cursor: "pointer" }}
                onClick={() =>
                  history.push(
                    "/print/flightticket/" + history.location.state.Tid
                  )
                }
              >
                Go to ticket details
              </a>
            </Typography>
          </div>
        </div> */}

        <div className="bookingStatusContainer">
          <div
            style={{
              backgroundColor:
                bookingProcess === "confirmed"
                  ? "#E5FFDA"
                  : bookingProcess === "processing"
                    ? "#fffec8"
                    : bookingProcess === "failed"
                      ? "#ffb09c"
                      : "#E5FFDA",
            }}
            className="bookingStatusInfoContainer"
          >
            <div className="bookStatusInfoTop">
              <div className="bookStatusHeader">
                Booking Status:{" "}
                {bookingProcess === "confirmed"
                  ? "Booking Confirmed"
                  : bookingProcess === "processing"
                    ? "Pending From Airline"
                    : bookingProcess === "failed"
                      ? "Booking Failed"
                      : "Unknown Status"}
              </div>
              <div className="bookStatusBookingId">Booking ID: {refId}</div>
            </div>
            <div className="bookStatusStatusInfo">
              {bookingProcess === "confirmed" ? (
                <CheckCircle style={{ color: "green" }} />
              ) : bookingProcess === "processing" ? (
                <AccessTimeIcon style={{ color: "grey" }} />
              ) : bookingProcess === "failed" ? (
                "Booking Failed"
              ) : (
                "Unknown Status"
              )}
              . Thanks for transacting with {AppConstant.name}.{" "}
              {bookingProcess === "confirmed"
                ? "We have sent an e-ticket to your registered email address"
                : bookingProcess === "processing"
                  ? "Your e-ticket will be delivered to your registered email automatically after the airline confirms your reservation."
                  : bookingProcess === "failed"
                    ? ""
                    : "Unknown Status"}
            </div>
          </div>
        </div>

        <div className="bookingStatusStepContainer">
          <div className="bookingStatusProcess">
            <div className="bookingStatusStep">
              <div className="stepIcon">
                {" "}
                <CheckCircle style={{ color: "#48AB64" }} />
              </div>
              <div className="stepInfoTextContainer">
                <div className="stepInfoHeader">Payment Received</div>
                <div className="stepInfoText">
                  We have received your payment
                </div>
              </div>
            </div>
            <div
              className="progressBar"
              style={{
                backgroundColor:
                  bookingProcess === "confirmed"
                    ? "#48AB64"
                    : bookingProcess === "processing"
                      ? "#eabb31"
                      : bookingProcess === "failed"
                        ? "#ffb09c"
                        : "#E5FFDA",
              }}
            ></div>
            <div className="bookingStatusStep">
              <div
                className="stepIcon"
                style={{
                  backgroundColor:
                    bookingProcess === "confirmed"
                      ? "transparent"
                      : bookingProcess === "processing"
                        ? "#eabb31"
                        : bookingProcess === "failed"
                          ? "#ffb09c"
                          : "#E5FFDA",
                }}
              >
                {bookingProcess === "processing" ? (
                  "2"
                ) : (
                  <CheckCircle style={{ color: "#48AB64" }} />
                )}
              </div>
              <div className="stepInfoTextContainer">
                <div className="stepInfoHeader">Confirming From Airline</div>
                <div className="stepInfoText">
                  It will take maximum 30 to 45mins
                </div>
              </div>
            </div>
            <div
              className="progressBar"
              style={{
                backgroundColor:
                  bookingProcess === "confirmed"
                    ? "#48AB64"
                    : bookingProcess === "processing"
                      ? "#d6792d"
                      : bookingProcess === "failed"
                        ? "#ffb09c"
                        : "#E5FFDA",
              }}
            ></div>
            <div className="bookingStatusStep">
              <div
                className="stepIcon"
                style={{
                  backgroundColor:
                    bookingProcess === "confirmed"
                      ? "transparent"
                      : bookingProcess === "processing"
                        ? "#d6792d"
                        : bookingProcess === "failed"
                          ? "#ffb09c"
                          : "#E5FFDA",
                }}
              >
                {bookingProcess === "confirmed" ? (
                  <CheckCircle style={{ color: "#48AB64" }} />
                ) : bookingProcess === "processing" ? (
                  "3"
                ) : bookingProcess === "failed" ? (
                  "3"
                ) : (
                  "3"
                )}
              </div>
              <div className="stepInfoTextContainer">
                <div className="stepInfoHeader">
                  {bookingProcess === "confirmed" 
                    ? "Booking Confirmed" : 
                      bookingProcess === "processing" 
                        ? "Confirmation pending" 
                          : "Booking Failed"
                  }
                </div>
                <div className="stepInfoText">
                  {bookingProcess === "confirmed" 
                    ? "Your Booking is Confirmed" : 
                      bookingProcess === "processing" 
                        ? retryCount < maxRetryCount 
                          ? "Booking confirmation pending" 
                            : "Please check back later." 
                              : "Your booking has failed"
                  }
                </div>
              </div>
            </div>
          </div>
          {bookingProcess === "processing" && retryCount < maxRetryCount && (
            <div className="bookingStatusStepContainerprocess">
              <div className="bookingStatusProcessContainer">
                <CircularProgress size={20} className="loader" />{" "}
                <div className="stepInfoHeader">
                  Booking Status: Processing..{" "}
                </div>
                <div className="stepInfoText">
                  We are yet to receive booking confirmation from airline's end.
                </div>
              </div>
            </div>
          )}
        </div>

        {bookingProcess === "confirmed" && (
          <div className="bookingProcessAssets">
            {/* <Button onClick={sendEmail}>Email</Button> */}
            <Button
              onClick={() =>
                window.open(
                  "/print/flightticket/" + history.location.state.Tid,
                  "_blank"
                )
              }
            >
              VIEW ETICKET
            </Button>

            <OaButtonDownloadPDF
              btnLabel="Download ETicket"
              api={DOWNLOAD_FLIGHT_TICKET_PDF}
              searchParams={flightTicketDownloadRequest}
              defaultFileName="flight-eticket"
            />
          </div>
        )}

        <div className="flex-start">
          <OaFormAlerts
            isSaving={isSaving}
            isSuccess={isSuccess}
            isError={isError}
            infoText={infoText}
            setIsError={setIsError}
            setIsSuccess={setIsSuccess}
          />
          <div className="itin-section">
            <div className="action-group">
              {/*flightData?.bookingStatus != "SUCCESS" && flightData?.bookingStatus != "SUCCESSCan" && */
                flightData?.bookingStatus == "SuccessTkd" && (
                  <Button onClick={printTicket}>
                    <PrintOutlined /> Print
                  </Button>
                )}
            </div>

            <div className="itin-contents">
              <FlightItinerary flightdata={flightData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    "& .progressBar": {
      width: 40,
      height: 5,
      borderRadius: 4,
    },

    "& .ticketDetailsText": {
      [theme.breakpoints.down(980)]: {
        fontSize: 15,
      },
    },
    "& .section-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      "&.flex-start": {
        alignItems: "flex-start",
      },
    },
    "& .bookingStatusContainer": {
      padding: "10px 15px",
      width: "100%",

      "& .bookingStatusInfoContainer": {
        border: "1px solid #D8D8D8",
        padding: 10,
        backgroundColor: "#E5FFDA",
      },
      "& .bookStatusInfoTop": {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 10,
      },
      "& .bookStatusHeader": {
        fontSize: 17,
        fontWeight: 700,
      },
      "& .bookStatusBookingId": {
        fontSize: 15,
        fontWeight: 500,
      },
      "& .MuiSvgIcon-root": {
        height: 40,
        width: 40,
        color: theme.palette.primary.success,
      },
      "& .bookStatusStatusInfo": {
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        fontSize: 13,
      },
      "& .bookingStatusStep": {
        display: "flex",
      },
    },
    "& .bookingStatusStepContainer": {
      padding: "5px 15px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    " & .bookingStatusStepContainerprocess": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .bookingStatusProcess": {
      display: "flex",
      alignItems: "center",
      border: "1px solid #D8D8D8",
      padding: "10px 15px",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down(700)]: {
        flexDirection: "column",
        alignItems: "center",
        gap: 10,
      },
      "& .bookingStatusStep": {
        display: "flex",
        alignItems: "center",
        gap: 10,
        [theme.breakpoints.down(700)]: {
          width: 240,
          marginBottom: 10,
        },
      },
      "& .stepIcon": {
        // backgroundColor: "#48AB64",
        height: 30,
        width: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        borderRadius: 50,
        fontWeight: 700,
        "& svg": {
          width: 35,
          height: 35,
        },
      },
      "& .stepInfoHeader": {
        fontWeight: 500,
        fontSize: 16,
        display: "flex",
        alignItems: "center",
      },
      "& .stepInfoText": {
        fontWeight: 400,
        fontSize: 12,
      },
    },
    "& .bookingStatusProcessContainer": {
      // border: "1px solid #D8D8D8",
      padding: "10px 15px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      "& .stepInfoHeader": {
        fontWeight: 500,
        fontSize: 16,
        display: "flex",
        alignItems: "center",
        gap: 10,
      },
      "& .stepInfoText": {
        fontWeight: 400,
        fontSize: 12,
      },
    },
    "& .itin-section": {
      padding: 0,
      width: "100%",
      "& .itin-contents": {
        padding: "10px 15px",
      },
    },
    "& .bookingProcessAssets": {
      display: "flex",
      justifyContent: "flex-end",
      gap: 15,
      padding: "0px 15px",
      width: "100%",
      "& .MuiSvgIcon-root": {
        marginRight: 5,
      },
      "& .MuiButton-root": {
        backgroundColor: "transparent",
        boxShadow: "none",
        color: "#171c5e",
        fontWeight: 700,
        fontSize: 14,
        cursor: "pointer",
        borderBottom: "1px solid #171c5e",
        padding: "10px 0px",
        width: "fit-content",
        borderRadius: 0,
        height: 30,
        marginTop: 10,
      },
    },

    "& .icon-group": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "25px 0",
      [theme.breakpoints.down(420)]: {
        marginLeft: 10,
      },
      [theme.breakpoints.down(350)]: {
        marginLeft: 0,
      },
      "& .MuiSvgIcon-root": {
        fontSize: "5rem",
        color: theme.palette.primary.success,
      },
      "& p": {
        fontWeight: 500,
        fontFamily: theme.palette.font.primary,
        marginTop: 20,
      },
    },
    "& .action-group": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: 10,
      "& button": {
        cursor: "pointer",
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        margin: "2px 6px",
      },
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        marginRight: 5,
      },
    },
  },
}));
