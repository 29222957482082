import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import React, { useContext, useEffect, useState } from "react";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Menu from "pages/b2c/component/Menu";
import Flight from "assets/img/6E.png";
import Profile from "./component/Profile";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { AppStateContext } from "layouts/AppStateProvider";
import { connect } from "react-redux";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PrintIcon from "@material-ui/icons/Print";
import DescriptionIcon from "@material-ui/icons/Description";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { ArrowBack } from "@material-ui/icons";
import { GET_FILE_UPLOAD } from "api/ApiConstants";
import { localforageGetItem } from "oautils/oaForageUtils";

const MuiAccordion = withStyles({
  root: {
    width: "100%",
    border: "0px",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion);

const MuiAccordionSummary = withStyles({
  root: {
    backgroundColor: "white",
    padding: 0,
    margin: 0,
    minHeight: "auto",
  },
  expanded: {},
})(AccordionSummary);

const MuiAccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    background: "white",
  },
}))(AccordionDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: "auto",
    paddingTop: "40px",
    paddingBottom: "40px",
    //marginTop: 100,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  rightGrid: {
    background: "#fff",
    paddingBottom: "6px",
    textAlign: "center",
    border: `1px solid ${theme.palette.primary.disabled}`,
    padding: "20px",
    borderRadius: "5px",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 15,
      marginRight: 15,
      marginTop: 10,
      padding: 5,
    },
  },
  typo1: {
    fontSize: "11px",
    fontWeight: 500,
    lineHeight: "1.5",
    color: theme.palette.primary.darkText,
    marginLeft: 5,
    letterSpacing: "0.5px !important",
  },
  typo2: {
    fontSize: "13px",
    paddingRight: "10px",
    fontWeight: 700,
    color: theme.palette.primary.darkText,
    letterSpacing: "0.5px !important",
    borderRight: "1px solid",
  },
  typo3: {
    fontSize: "13px",
    fontWeight: 700,
    color: theme.palette.primary.darkText,
    letterSpacing: "0.5px !important",
    paddingLeft: "10px",
  },
  typo4: {
    fontWeight: 500,
    lineHeight: 1.5,
    color: theme.palette.primary.darkText,
  },
  typo5: {
    fontSize: 12,
    color: theme.palette.primary.darkText,
    fontWeight: 500,
  },
  fai: {},
  typo6: {
    textAlign: "center",
    color: theme.palette.primary.darkText,
    fontSize: "12px",
    padding: "0px 10px",
  },
  img: {
    width: "26px",
    marginTop: "5px",
    verticalAlign: " baseline",
  },
  typo7: {
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.primary.darkText,
  },
  typo8: {
    color: "gray",
    fontSize: "13px",
  },

  typo9: {
    lineHeight: 1.5,
    color: theme.palette.primary.darkText,
    fontSize: "12px",
  },
  typo10: {
    fontSize: 12,
    color: theme.palette.primary.darkText,
    fontWeight: 400,
  },
  heading2: {
    fontSize: 18,
    fontWeight: 500,
    marginTop: 10,
    marginBottom: 10,
  },
  table: {
    width: "100%",
    marginBottom: "1rem",
    color: theme.palette.primary.darkText,
    borderCollapse: "collapse",

    "& thead": {
      "& tr": {
        "& th": {
          border: `1px solid ${theme.palette.primary.disabled} !important`,
          fontSize: "12px !important",
          fontWeight: "500 !important",

          padding: ".75rem",
          color: theme.palette.primary.darkText,
          [theme.breakpoints.down("sm")]: {
            padding: "0px",
            fontSize: "7px !important",
          },
        },
      },
    },
    "& tbody": {
      "& tr": {
        "& td": {
          border: `1px solid ${theme.palette.primary.disabled} !important`,
          fontSize: "12px !important",
          fontWeight: "400 !important",
          padding: ".75rem",
          [theme.breakpoints.down("sm")]: {
            padding: "0px",
            fontSize: "7px !important",
          },
        },
      },
    },
  },

  bookingGrid: {
    marginLeft: "60%",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px",
    },
  },
  Grid1: {
    borderRight: `1px solid ${theme.palette.primary.gray}`,
    marginTop: 10,
    [theme.breakpoints.down("xs")]: {
      borderRight: "none",
    },
  },
  Grid2: {
    paddingLeft: 15,
    paddingBottom: 25,
    paddingRight: 25,
    [theme.breakpoints.down("xs")]: {
      paddingRight: 15,
    },
  },
  backButton: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.main,
    "& .MuiSvgIcon-root": {
      fontSize: 16,
      marginRight: 5,
    },
    "&:hover": {
      color: theme.palette.primary.sub,
    },
  },
}));

const Itinerary = (props) => {
  const classes = useStyles();
  // console.log("ticket");
  // console.log("ticket");

  const [onwardCancelPassengers, setOnwardCancelPassengers] = useState([]);
  const [returnCancelPassengers, setReturnCancelPassengers] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [onwardExpanded, setOnwardExpanded] = useState(false);
  const [returnExpanded, setReturnExpanded] = useState(false);
  const [value, setValue] = useState("");
  const [valueReturn, setValueReturn] = useState("");
  const [cancellationType, setCancellationType] = useState("");
  const [cancellationTypeReturn, setCancellationTypereturn] = useState("");

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const { setIsCancelled } = useContext(AppStateContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    setConfirmation,
    isConfirmed,
    setIsConfirmed,
    setOpen,
  } = React.useContext(AppStateContext);

  // console.log(props.location.state.index);
  //let userType = "";
  // console.log("itin props",props);
  // if (props.history.location.state && props.history.location.state.userType) {
  //  userType = props.history.location.state.userType;
  // }

  const handleChangeRadio = (event) => {
    setValue(event.target.value);
  };

  const handleChangeRadioReturn = (event) => {
    setValueReturn(event.target.value);
  };

  const handleChangeCancellation = (event) => {
    setCancellationType(event.target.value);
  };
  const handleChangeCancellationReturn = (event) => {
    setCancellationTypereturn(event.target.value);
  };

  useEffect(() => {
    if (isConfirmed) {
      setOpen(false);
      cancelRequest();
    }
  }, [isConfirmed]);

  // const ticket = props.flightList[props.location?.state?.index];
  const ticket = props.location?.state?.ticket || props.ticket;

  //const userType=props.location?.state?.userType;
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [userTypeAbv, setUserTypeAbv] = React.useState(null);

  // console.log("ticket", ticket);

  const handleChange = (ev, passenger) => {
    const temp = [...onwardCancelPassengers];
    const index = temp.findIndex((a) => a === passenger);
    if (index !== -1) {
      temp.splice(temp.indexOf(index), 1);
    } else {
      temp.push(passenger);
    }

    // if (ev.target.checked) {
    //   setOnwardCancelPassengers([...onwardCancelPassengers, passenger]);
    // } else {
    //   setOnwardCancelPassengers(value => value.filter(obj => { return obj.uid !== passenger.uid }));
    // }

    setOnwardCancelPassengers(temp);
  };
  const handleReturnChange = (passenger) => {
    const temp = [...returnCancelPassengers];
    const index = temp.findIndex((a) => a === passenger);
    if (index !== -1) {
      temp.splice(temp.indexOf(index), 1);
    } else {
      temp.push(passenger);
    }
    // console.log("returnCancelPassengers", temp);
    setReturnCancelPassengers(temp);
  };

  let passengerReturn = [];
  returnCancelPassengers.length > 0 &&
    returnCancelPassengers.map((value, index) => {
      let returnPassanger = {
        paxUId: value.uid,
        journeyType: ticket.journeyType,
        flightUId: ticket.returnFlightDetails.details[0].uid,
      };
      passengerReturn.push(returnPassanger);
    });

  let passenger = [];
  onwardCancelPassengers.length > 0 &&
    onwardCancelPassengers.map((value, index) => {
      let temp = {
        paxUId: value.uid,
        journeyType: ticket.journeyType,
        flightUId: ticket.onwardFlightDetails.details[0].uid,
      };
      passenger.push(temp);
    });
  // console.log("passender array", passenger);

  const cancelRequest = () => {
    setConfirmation("Are you sure want to proceed?");
    setOpen(false);
    apiCall(
      WebApi.b2CFlightCancellation,
      {
        passenger,
        remark: cancellationType,
        tid: ticket.tid,
        transType: 1,
        typeOfCancellation: value,
      },
      (response) => {
        // console.log(response);
        if (response.succes) {
          apiCall(
            WebApi.getB2CFlightList,
            { userId: 1, type: "completed" },
            (response) => {
              props.setB2CFlightList(response.data);
              // console.log("itetete");
            }
          );
        }
        if (response.success) {
          setConfirmation("Succesfully cancelled");
        } else {
          // setConfirmation("Cancellation Failed");
        }
        setIsError(!response.success);
        // setIsSuccess(response.success);

        setIsSaving(false);
        setInfoText(response.message);
        setOpen(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setTimeout(() => {
          setOpen(false);
          props.history.goBack();
        }, 3000);
      }
    );
    // setIsCancelled(true)
  };

  const cancelRequestReturn = () => {
    let passenger = passengerReturn;
    apiCall(
      WebApi.b2CFlightCancellation,
      {
        passenger,
        remark: cancellationTypeReturn,
        tid: ticket.tid,
        transType: 1,
        typeOfCancellation: valueReturn,
      },
      (response) => {
        // console.log(response);
        if (response.succes) {
          apiCall(
            WebApi.getB2CFlightList,
            { userId: 1, type: "completed" },
            (response) => {
              props.setB2CFlightList(response.data);
              // console.log("itetete");
            }
          );
        }
        setIsError(!response.success);
        setIsSuccess(response.success);

        setIsSaving(false);
        setInfoText(response.message);

        window.scrollTo({ top: 0, behavior: "smooth" });

        setTimeout(() => {
          props.history.goBack();
        }, 3000);
      }
    );
    // setIsCancelled(true)
  };

  // console.log("passengerDetails",ticket.passengerDetails);
  // let userType = "Registered";
  // console.log("props.location.state.userType ", props.location.state.userType);

  let userType = props?.location?.state?.userType || props.userType;
  console.log("props.location.state.userType userType ", userType);

  return (
    <div className={classes.root}>
      {/* <div >close</div> */}
      <Grid container spacing={isMobile ? 0 : 2} justifyContent="center">
        {/* {userType != "GUEST" && userType != "agent" && userType != "direct_agent" &&  */}

        {userType === "R" && (
          <>
            <Grid item md={3} xs={12} style={{ padding: 0 }}>
              <Menu />
              <div style={{ marginLeft: 15, marginRight: 15, marginTop: 10 }}>
                <Profile />
              </div>
            </Grid>
          </>
        )}

        {/* } */}

        <Grid item md={8} xs={12} style={{ padding: isMobile && 15 }}>
          <Grid
            container
            item
            md={12}
            xs={12}
            sm={12}
            style={{
              background: "#fff",
              border: `1px solid ${theme.palette.primary.disabled}`,
              // border: "1px solid #eee",
              borderRadius: "5px",
              boxShadow: `0px 0px 6px -1px ${theme.palette.primary.disabled}`,
            }}
          >
            <Grid item md={12}>
              <OaFormAlerts
                isSaving={isSaving}
                isSuccess={isSuccess}
                isError={isError}
                infoText={infoText}
                setIsError={setIsError}
                setIsSuccess={setIsSuccess}
              />
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{
                background: theme.palette.primary.disabled,
                padding: "10px",
              }}
            >
              <Grid item container md={4} xs={6}>
                <Grid item>
                  <i
                    className="fa fa-plane"
                    aria-hidden="true"
                    style={{ opacity: 0.5, fontSize: 22 }}
                  ></i>
                </Grid>
                <Grid item>
                  <Typography className={classes.typo1}>
                    Total Passenger(s):{" "}
                    {ticket.onwardFlightDetails.summary.adult +
                      ticket.onwardFlightDetails.summary.child +
                      ticket.onwardFlightDetails.summary.infant}
                  </Typography>
                  <Typography
                    className={classes.typo1}
                    style={{ opacity: 0.7 }}
                  >
                    {`ADULT:${ticket.onwardFlightDetails.summary.adult}  CHILD:${ticket.onwardFlightDetails.summary.child}  INFANT:${ticket.onwardFlightDetails.summary.infant}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container md={6} xs={6}>
                <Grid item>
                  <Grid container justifyContent="flex-end">
                    <Typography className={classes.typo2}>
                      Departure Date:{" "}
                      {moment(
                        ticket.onwardFlightDetails.summary.deptDate
                      ).format("DD MMM'YY")}
                    </Typography>
                    <Typography className={classes.typo3}>
                      PNR No.
                      {ticket.onwardFlightDetails.summary.pnrNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ display: "none" }}>
                <a
                  onClick={() => props.history.goBack()}
                  className={classes.backButton}
                >
                  <ArrowBack /> Back
                </a>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={8} xs={12} className={classes.Grid1}>
                <Grid
                  container
                  justifyContent="space-between"
                  className={classes.Grid2}
                >
                  <Grid item>
                    <Typography className={classes.typo4}>
                      {
                        ticket.onwardFlightDetails.summary.deptAirport.split(
                          ", "
                        )[1]
                      }
                    </Typography>
                    <Typography className={classes.typo10}>
                      {moment(
                        ticket.onwardFlightDetails.summary.deptDate
                      ).format("DD MMM'YY")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {/* <i
                      className="fa fa-long-arrow-right"
                      aria-hidden="true"
                      style={{ fontSize: "35px", color: "#4e4c4c" }}
                    ></i> */}
                    <ArrowForwardIcon
                      style={{ fontSize: "35px", color: "#4e4c4c" }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.typo4}>
                      {
                        ticket.onwardFlightDetails.summary.arrAirport.split(
                          ", "
                        )[1]
                      }
                    </Typography>
                    <Typography className={classes.typo10}>
                      {moment(
                        ticket.onwardFlightDetails.summary.arrDate
                      ).format("DD MMM'YY")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} xs={12} style={{ marginTop: 10 }}>
                <Typography
                  className={classes.typo5}
                  style={{ fontWeight: 500 }}
                >
                  PNR Status :{" "}
                  <span style={{ fontWeight: 400, color: "grey" }}>
                    {ticket.status}
                  </span>
                </Typography>
                <Typography
                  className={classes.typo5}
                  style={{ fontWeight: 500 }}
                >
                  PNR:{" "}
                  <span className={classes.typo5}>
                    {ticket.onwardFlightDetails.summary.pnrNumber}
                  </span>
                </Typography>
                <Typography className={classes.typo10}>
                  {ticket.onwardFlightDetails.summary.isRefundable}
                </Typography>
              </Grid>

              <Grid
                item
                md={12}
                xs={12}
                sm={12}
                style={{
                  borderTop: "1px solid #9e9e9e",
                  margin: "0px 15px",
                  padding: "15px 0px",
                }}
              >
                <Grid container justifyContent="space-between">
                  <Grid
                    item
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      props.history.push("/print/flightticket/" + ticket.tid);
                    }}
                  >
                    {/* <i
                      className="fa fa-ticket"
                      aria-hidden="true"
                      style={{
                        textAlign: "center",
                        fontSize: "19px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}
                    ></i> */}
                    <PrintIcon
                      style={{
                        textAlign: "center",
                        fontSize: "25px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}
                    />
                    <Typography className={classes.typo6}>
                      Print Ticket
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      props.history.push("/print/flightticket/" + ticket.tid);
                    }}
                  >
                    {/* <i
                      className="fa fa-download"
                      aria-hidden="true"
                      style={{
                        textAlign: "center",
                        fontSize: "19px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}
                    ></i> */}
                    <ArrowDownwardIcon
                      style={{
                        textAlign: "center",
                        fontSize: "25px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}
                    />
                    <Typography className={classes.typo6}>
                      Download Ticket
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      props.history.push(
                        "/print/voucher/flight-agent/" + ticket.tid
                      );
                    }}
                  >
                    {/* <i
                      className="fa fa-sticky-note-o"
                      aria-hidden="true"
                      style={{
                        textAlign: "center",
                        fontSize: "19px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}
                    ></i> */}
                    <DescriptionIcon
                      style={{
                        textAlign: "center",
                        fontSize: "25px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}
                    />
                    <Typography className={classes.typo6}>
                      Print Invoice
                    </Typography>
                  </Grid>
                  {/* <Grid item>
                    <i
                      className="fas fa-ban"
                      aria-hidden="true"
                      style={{
                        textAlign: "center",
                        fontSize: "19px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}
                    ></i>
                    <Typography className={classes.typo6}>
                    Cancel Ticket
                    </Typography>
                  </Grid> */}
                  {/* <Grid item>
                    <i
                      className="fa fa-commenting-o"
                      aria-hidden="true"
                      style={{
                        textAlign: "center",
                        fontSize: "19px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}
                    ></i>
                    <Typography className={classes.typo6}>
                      SMS/Email Ticket
                    </Typography>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {ticket.cancellationDetails.length > 0 && (
            <Grid
              container
              style={{
                marginTop: "15px",
                border: `1px solid ${theme.palette.primary.disabled}`,
                borderRadius: "5px",
                boxShadow: `0px 0px 6px -1px ${theme.palette.primary.disabled}`,
                paddingTop: "12px",
                borderTop: `3px solid ${theme.palette.primary.main}`,
                padding: 15,
              }}
            >
              <Grid item xs={12}>
                <Typography className={classes.heading2}>
                  Cancellation Request Details
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                {ticket.cancellationDetails.map((cancel, index) => (
                  <MuiAccordion
                    key={index}
                    square
                    expanded={expanded}
                    onChange={() => setExpanded(!expanded)}
                  >
                    <MuiAccordionSummary>
                      <Grid item xs={12}>
                        <a className={classes.aTag}>
                          <table cellSpacing={0} className={classes.table}>
                            <thead>
                              <tr>
                                <th>ID - {cancel.canId}</th>
                                <th>Status - {cancel.canStatus}</th>
                                <th>Refund - {cancel.refundStatus}</th>
                                <th>
                                  {expanded ? (
                                    <ExpandLess />
                                  ) : (
                                    <ExpandMoreIcon />
                                  )}
                                </th>
                              </tr>
                            </thead>
                          </table>
                        </a>
                      </Grid>
                    </MuiAccordionSummary>
                    <MuiAccordionDetails>
                      <Grid container>
                        <Grid item xs={12}>
                          <table
                            cellSpacing={0}
                            cellPadding={5}
                            className={classes.table}
                          >
                            <tbody>
                              <tr className={classes.tableRow}>
                                <td
                                  className={classes.tableCell3}
                                  style={{ width: "30%" }}
                                >
                                  Passenger Name
                                </td>
                                <td className={classes.tableCell3}>Flight</td>
                                <td className={classes.tableCell3}>
                                  Ticket No
                                </td>
                              </tr>
                              {cancel.cancellationPassenger.map(
                                (passenger, secIndex) => (
                                  <tr key={secIndex}>
                                    <td
                                      className={classes.tableCell3}
                                      style={{ width: "30%" }}
                                    >
                                      {`${secIndex + 1}. ${
                                        passenger.passengerName
                                      }`}
                                    </td>
                                    <td className={classes.tableCell3}>
                                      {passenger.flightNo}
                                    </td>
                                    <td className={classes.tableCell3}>
                                      {passenger.eticketNo}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </Grid>
                        <Grid item xs={12}>
                          <table
                            cellSpacing={0}
                            cellPadding={5}
                            className={classes.table}
                          >
                            <tbody>
                              <tr className={classes.tableRow}>
                                <td
                                  className={classes.tableCell3}
                                  style={{ width: "30%" }}
                                >
                                  Cancel Status : {cancel.canStatus}
                                </td>
                                <td colSpan="2" className={classes.tableCell3}>
                                  Cancelation Request DateTime :{" "}
                                  {moment(cancel.canReqDatetime).format(
                                    "DD MMM'YY"
                                  )}
                                </td>
                              </tr>
                              <tr className={classes.tableRow}>
                                <td
                                  className={classes.tableCell3}
                                  style={{ width: "30%" }}
                                >
                                  Refund Status : {cancel.refundStatus}
                                </td>
                                <td className={classes.tableCell3}>
                                  Refund Request DateTime :{" "}
                                  {cancel.refundDatetime}
                                </td>
                                <td className={classes.tableCell3}>
                                  Refund Amount: Rs{" "}
                                  {Number(cancel.refundAmount).toFixed(2)}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  className={classes.tableCell3}
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "1.5em",
                                  }}
                                  colSpan="3"
                                >
                                  Remarks : {cancel.canReqRemark}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Grid>
                      </Grid>
                    </MuiAccordionDetails>
                  </MuiAccordion>
                ))}
              </Grid>
            </Grid>
          )}
          <Grid
            container
            style={{
              marginTop: "15px",
              border: `1px solid ${theme.palette.primary.disabled}`,
              borderRadius: "5px",
              boxShadow: `0px 0px 6px -1px ${theme.palette.primary.disabled}`,
              paddingTop: "12px",
              borderTop: `3px solid ${theme.palette.primary.main}`,
            }}
          >
            <Grid item md={12} xs={12}>
              <Typography className={classes.typo4} style={{ padding: 10 }}>
                {`${ticket.onwardFlightDetails.summary.deptAirport !== null &&
                  ticket.onwardFlightDetails.summary.deptAirport.split(
                    ", "
                  )[1]} - ${ticket.onwardFlightDetails.summary.arrAirport !==
                  null &&
                  ticket.onwardFlightDetails.summary.arrAirport.split(
                    ", "
                  )[1]}`}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12} sm={12} style={{ padding: "15px" }}>
              <MuiAccordion
                square
                expanded={onwardExpanded}
                onChange={() => setOnwardExpanded(!onwardExpanded)}
              >
                <MuiAccordionSummary>
                  <GridContainer
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item style={{ textAlign: "center" }}>
                      <Grid container>
                        <Grid item>
                          <img
                            src={
                              GET_FILE_UPLOAD +
                              "?fileName=" +
                              ticket.onwardFlightDetails.summary.airlineCode +
                              ".png&type=AIRLINE_IMAGES"
                            }
                            alt="flight"
                            className={classes.img}
                          />
                        </Grid>
                        <Grid item>
                          <Typography className={classes.typo7}>
                            {ticket.onwardFlightDetails.summary.airline}
                          </Typography>
                          <Typography className={classes.typo8}>
                            {`(${ticket.onwardFlightDetails.summary.airlineCode} - ${ticket.onwardFlightDetails.summary.flightNumber})`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.typo7}>
                        {ticket.onwardFlightDetails.bookingClass === "E"
                          ? "Economy"
                          : "Business"}
                      </Typography>
                      <Typography className={classes.typo8}>
                        PNR :{ticket.onwardFlightDetails.summary.pnrNumber}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.typo7}>
                        {
                          ticket.onwardFlightDetails.summary.deptAirport.split(
                            ", "
                          )[1]
                        }
                      </Typography>
                      <Typography className={classes.typo8}>
                        {
                          ticket.onwardFlightDetails.summary.deptDate.split(
                            "T"
                          )[0]
                        }{" "}
                        {
                          ticket.onwardFlightDetails.summary.deptDate.split(
                            "T"
                          )[1]
                        }
                      </Typography>
                    </Grid>
                    <Grid item>
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.typo7}>
                        {
                          ticket.onwardFlightDetails.summary.arrAirport.split(
                            ", "
                          )[1]
                        }
                      </Typography>
                      <Typography className={classes.typo8}>
                        {
                          ticket.onwardFlightDetails.summary.arrDate.split(
                            "T"
                          )[0]
                        }{" "}
                        {
                          ticket.onwardFlightDetails.summary.arrDate.split(
                            "T"
                          )[1]
                        }
                      </Typography>
                    </Grid>
                  </GridContainer>
                </MuiAccordionSummary>
                {/* {ticket.returnFlightDetails.summary.stop > 0 && (
                  <MuiAccordionDetails>
                    {ticket.onwardFlightDetails.details.map((detail, index) => (
                      <GridContainer
                        key={index}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          <Grid container>
                            <Grid item>
                              <img
                                src={Flight}
                                alt="flight"
                                className={classes.img}
                              />
                            </Grid>
                            <Grid item>
                              <Typography className={classes.typo7}>
                                {detail.airline}
                              </Typography>
                              <Typography className={classes.typo8}>
                                {`(${detail.airlineCode} - ${detail.flightNumber})`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.typo7}>
                            {detail.bookingClass === "E"
                              ? "Economy"
                              : "Business"}
                          </Typography>
                          <Typography className={classes.typo8}>
                            PNR : {detail.pnrNumber}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.typo7}>
                            {detail.deptAirport.split(", ")[1]}
                          </Typography>
                          <Typography className={classes.typo8}>
                            {detail.deptDate.split("T")[0]}{" "}
                            {detail.deptDate.split("T")[1]}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <i
                            className="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.typo7}>
                            {detail.arrAirport.split(", ")[1]}
                          </Typography>
                          <Typography className={classes.typo8}>
                            {detail.arrDate.split("T")[0]}{" "}
                            {detail.arrDate.split("T")[1]}
                          </Typography>
                        </Grid>
                      </GridContainer>
                    ))}
                  </MuiAccordionDetails>
                )} */}
              </MuiAccordion>
              <Grid
                container
                style={{
                  marginTop: "20px",
                  background: "#adadad",
                  padding: "10px",
                  borderRadius: "17px",
                  textAlign: "left",
                  marginBottom: "20px",
                }}
              >
                {/* <Grid item>
                  <Typography className={classes.typo9}>
                    Layover : New Delhi | 3Hr 20Min
                  </Typography>
                  <Typography className={classes.typo9}>
                    Connecting flight may depart from a different terminal.
                  </Typography>
                </Grid> */}
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12}>
                  {/* <Card xs={12}> */}
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th scope="col">Passenger Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Seat No.(s)</th>
                        <th scope="col">Baggage</th>
                        <th scope="col">Meal</th>
                        <th scope="col">Others</th>
                        <th scope="col">Cancel</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ticket.passengerDetails.map((passenger, index) => (
                        <tr key={index}>
                          <td>{`${passenger.title}. ${passenger.givenname} ${passenger.surname}`}</td>
                          <td>{ticket.status}</td>
                          <td>N/A</td>
                          <td>{ticket.onwardFlightDetails.summary.baggage}</td>
                          <td>{passenger.mealRequest}</td>
                          <td>N/A</td>

                          <td>
                            {passenger.isOnwardCancelled ? (
                              <Checkbox
                                disabled={passenger.isOnwardCancelled}
                                checked={passenger.isOnwardCancelled}
                                onChange={(ev) => handleChange(ev, passenger)}
                                name="checkedB"
                                color="primary"
                              />
                            ) : (
                              <Checkbox
                                onChange={(ev) => handleChange(ev, passenger)}
                                name="checkedB"
                                color="primary"
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* </Card> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {onwardCancelPassengers.length > 0 && (
            <Grid
              item
              xs={12}
              container
              style={{
                marginTop: "15px",
                border: `1px solid ${theme.palette.primary.disabled}`,
                borderRadius: "5px",
                boxShadow: `0px 0px 6px -1px ${theme.palette.primary.disabled}`,
                paddingTop: "12px",
                borderTop: `3px solid ${theme.palette.primary.main}`,
                padding: 15,
              }}
            >
              {/* <Grid item> */}
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} md={3}>
                  <Typography style={{ color: "red" }}>
                    Type of Cancellation:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <FormControl component="fieldset" style={{ fontSize: 12 }}>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="1"
                      value={value}
                      onChange={handleChangeRadio}
                    >
                      <FormControlLabel
                        control={<Radio color="primary" />}
                        label="No Show"
                        value="1"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio color="primary" />}
                        label="Flight Cancelled"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio color="primary" />}
                        label="Others/Regular Cancellation"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ marginTop: 10 }}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={6} md={3}>
                  <Typography>Cancellation Type</Typography>
                </Grid>
                <Grid item xs={6}>
                  <textarea
                    style={{ width: "100%", height: 50 }}
                    onChange={handleChangeCancellation}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.lightText,
                      borderRadius: 10,
                    }}
                    onClick={() => {
                      setConfirmation("Are you sure want to proceed?");
                      setOpen(true);
                    }}
                  >
                    Cancel Selected
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            // </Grid>
          )}
          {ticket.journeyType === "R" && (
            <>
              <Grid
                container
                style={{
                  marginTop: "15px",
                  border: `1px solid ${theme.palette.primary.disabled}`,
                  borderRadius: "5px",
                  boxShadow: `0px 0px 6px -1px ${theme.palette.primary.diabled}`,
                  paddingTop: "12px",
                  borderTop: `3px solid ${theme.palette.primary.main}`,
                }}
              >
                <Grid item md={12} xs={12}>
                  <Typography className={classes.typo4} style={{ padding: 10 }}>
                    {`${
                      ticket.returnFlightDetails.summary.deptAirport.split(
                        ", "
                      )[1]
                    } - ${
                      ticket.returnFlightDetails.summary.arrAirport.split(
                        ", "
                      )[1]
                    }`}
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12} sm={12} style={{ padding: "15px" }}>
                  <MuiAccordion
                    square
                    expanded={returnExpanded}
                    onChange={() => setReturnExpanded(!returnExpanded)}
                  >
                    <MuiAccordionSummary>
                      <GridContainer
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item style={{ textAlign: "center" }}>
                          <Grid container>
                            <Grid item>
                              <img
                                src={
                                  GET_FILE_UPLOAD +
                                  "?fileName=" +
                                  ticket.returnFlightDetails.summary
                                    .airlineCode +
                                  ".png&type=AIRLINE_IMAGES"
                                }
                                alt="flight"
                                className={classes.img}
                              />
                            </Grid>
                            <Grid item>
                              <Typography className={classes.typo7}>
                                {ticket.returnFlightDetails.summary.airline}
                              </Typography>
                              <Typography
                                style={{ padding: 10 }}
                                className={classes.typo8}
                              >
                                {`(${ticket.returnFlightDetails.summary.airlineCode} - ${ticket.returnFlightDetails.summary.flightNumber})`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.typo7}>
                            {ticket.status}
                          </Typography>
                          <Typography className={classes.typo8}>
                            PNR : {ticket.returnFlightDetails.summary.pnrNumber}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.typo7}>
                            {
                              ticket.returnFlightDetails.summary.deptAirport.split(
                                ", "
                              )[1]
                            }
                          </Typography>
                          <Typography className={classes.typo8}>
                            {
                              ticket.returnFlightDetails.summary.deptDate.split(
                                "T"
                              )[0]
                            }{" "}
                            {
                              ticket.returnFlightDetails.summary.deptDate.split(
                                "T"
                              )[1]
                            }
                          </Typography>
                        </Grid>
                        <Grid item>
                          <i
                            className="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.typo7}>
                            {
                              ticket.returnFlightDetails.summary.arrAirport.split(
                                ", "
                              )[1]
                            }
                          </Typography>
                          <Typography className={classes.typo8}>
                            {
                              ticket.returnFlightDetails.summary.arrDate.split(
                                "T"
                              )[0]
                            }{" "}
                            {
                              ticket.returnFlightDetails.summary.arrDate.split(
                                "T"
                              )[1]
                            }
                          </Typography>
                        </Grid>
                      </GridContainer>
                    </MuiAccordionSummary>
                    {ticket.returnFlightDetails.summary.stop > 0 && (
                      <MuiAccordionDetails>
                        {ticket.returnFlightDetails.details.map(
                          (detail, index) => (
                            <GridContainer
                              key={index}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item style={{ textAlign: "center" }}>
                                <Grid container>
                                  <Grid item>
                                    <img
                                      src={
                                        GET_FILE_UPLOAD +
                                        "?fileName=" +
                                        ticket.returnFlightDetails.summary
                                          .airlineCode +
                                        ".png&type=AIRLINE_IMAGES"
                                      }
                                      alt="flight"
                                      className={classes.img}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Typography className={classes.typo7}>
                                      {detail.airline}
                                    </Typography>
                                    <Typography className={classes.typo8}>
                                      {`(${detail.airlineCode} - ${detail.flightNumber})`}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Typography className={classes.typo7}>
                                  {detail.bookingClass === "E"
                                    ? "Economy"
                                    : "Business"}
                                </Typography>
                                <Typography className={classes.typo8}>
                                  PNR : {detail.pnrNumber}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography className={classes.typo7}>
                                  {detail.deptAirport.split(", ")[1]}
                                </Typography>
                                <Typography className={classes.typo8}>
                                  {detail.deptDate.split("T")[0]}{" "}
                                  {detail.deptDate.split("T")[1]}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                ></i>
                              </Grid>
                              <Grid item>
                                <Typography className={classes.typo7}>
                                  {detail.arrAirport.split(", ")[1]}
                                </Typography>
                                <Typography className={classes.typo8}>
                                  {detail.arrDate.split("T")[0]}{" "}
                                  {detail.arrDate.split("T")[1]}
                                </Typography>
                              </Grid>
                            </GridContainer>
                          )
                        )}
                      </MuiAccordionDetails>
                    )}
                  </MuiAccordion>
                  <Grid
                    container
                    style={{
                      marginTop: "20px",
                      background: "#adadad",
                      padding: "10px",
                      borderRadius: "17px",
                      textAlign: "left",
                      marginBottom: "20px",
                    }}
                  >
                    <Grid item>
                      <Typography className={classes.typo9}>
                        Layover : New Delhi | 3Hr 20Min
                      </Typography>
                      <Typography className={classes.typo9}>
                        Connecting flight may depart from a different terminal.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      {/* <Card xs={12}> */}
                      <table className={classes.table}>
                        <thead>
                          <tr>
                            <th scope="col">Passenger Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Seat No.(s)</th>
                            <th scope="col">Baggage</th>
                            <th scope="col">Meal</th>
                            <th scope="col">Others</th>
                            <th scope="col">Cancel</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ticket.passengerDetails.map((passenger, index) => (
                            <tr key={index}>
                              <td>{`${passenger.title}. ${passenger.givenname} ${passenger.surname}`}</td>
                              <td>
                                {ticket.returnFlightDetails.summary
                                  .pnrNumber !== ""
                                  ? "Success"
                                  : "Processing"}
                              </td>
                              <td>N/A</td>
                              <td>
                                {ticket.returnFlightDetails.summary.baggage}
                              </td>
                              <td>{passenger.mealRequest}</td>
                              <td>N/A</td>
                              <td>
                                {passenger.isReturnCancelled ? (
                                  <Checkbox
                                    disabled={passenger.isReturnCancelled}
                                    checked={passenger.isReturnCancelled}
                                    onChange={() =>
                                      handleReturnChange(passenger)
                                    }
                                    name="checkedB"
                                    color="primary"
                                  />
                                ) : (
                                  <Checkbox
                                    onChange={() =>
                                      handleReturnChange(passenger)
                                    }
                                    name="checkedB"
                                    color="primary"
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* </Card> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {returnCancelPassengers.length > 0 && (
                <Grid
                  item
                  xs={12}
                  container
                  style={{
                    marginTop: "15px",
                    border: `1px solid ${theme.palette.primary.disabled}`,
                    borderRadius: "5px",
                    boxShadow: `0px 0px 6px -1px ${theme.palette.primary.disabled}`,
                    paddingTop: "12px",
                    borderTop: `3px solid ${theme.palette.primary.main}`,
                    padding: 15,
                  }}
                >
                  {/* <Grid item> */}
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={3}>
                      <Typography style={{ color: "red" }}>
                        Type of Cancellation:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <FormControl
                        component="fieldset"
                        style={{ fontSize: 12 }}
                      >
                        <RadioGroup
                          row
                          aria-label="position"
                          name="position"
                          defaultValue="1"
                          value={valueReturn}
                          onChange={handleChangeRadioReturn}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label="No Show"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio color="primary" />}
                            label="Flight Cancelled"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio color="primary" />}
                            label="Others/Regular Cancellation"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    style={{ marginTop: 10 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={6} md={3}>
                      <Typography>Cancellation Type</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <textarea
                        style={{ width: "100%", height: 50 }}
                        onChange={handleChangeCancellationReturn}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        style={{
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.lightText,
                          borderRadius: 10,
                        }}
                        type="submit"
                        onClick={cancelRequestReturn}
                      >
                        Cancel Selected
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                // </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <style>{`
      .triumph-b2c .oa-navbar-menu a {
        color: ${theme.palette.primary.darkText} !important;
      }
      .triumph-b2c .oa-navbar-sticky .oa-navbar-menu a {
        color: ${theme.palette.primary.lightText} !important;
      }
      `}</style>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    flightList: state.flightList.flightList,
  };
}
export default withRouter(connect(mapStateToProps)(Itinerary));
