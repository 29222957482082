import React, { useState, useEffect } from "react";
// @material-ui/core components
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { currencyFormatter } from "oautils/oaCommonUtils";
import { GET_FILE_UPLOAD } from "api/ApiConstants";
import { localforageGetItem } from "oautils/oaForageUtils";
import { AppConstant } from "appConstant";
import FlightTakeoffIcon from "assets/img/plane-takeoff.png";
import FlightLandingIcon from "assets/img/plane-landing.png";
// import { ftstyles } from 'assets/css/FlightTicketStyles';

// style for this view
//import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

//import 'assets/css/FlightTicket.css';
import "assets/css/ticket-custom-div.css";

// const useStyles = makeStyles(ftstyles);

function FlightTicketResult(props) {
  // const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState("0.00");
  const [basefare, setBasefare] = useState(0.0);
  const [taxes, setTaxes] = useState(0.0);
  const [userId, setUserId] = useState(0);
  const [total, setTotal] = useState(0.0);
  const [gst, setGst] = useState(0.0);
  const theme = useTheme();
  const [mealbaggage, setMealBaggage] = useState(0.0);

  const [baggage, setBaggage] = useState("");
  const [meal, setMeal] = useState("");
  const [seat, setSeat] = useState("");

  const { isAddressHide, isFareHide, isPerPassenger, isGSTHide } = props.state;

  localforageGetItem("user-id", function(err, value) {
    setUserId(value);
  });

  const getRefundStatus = (data) => {
    const refund = data.includes("Refundable");
    const nRefund = data.includes("Non Refundable");
    if (refund) return "Refundable";
    else if (nRefund) return "Non Refundable";
    return "Refundable";
  };

  const getClass = (data) => {
    var n = data.indexOf("Booking Class : "); // 16 character string
    n = n + 16;
    return data.substring(n, n + 1);
  };

  const getTimeDiffrence = (fromDataTime, toDataTime) => {
    var date1 = new Date(fromDataTime);
    var date2 = new Date(toDataTime);

    var diff = date2.getTime() - date1.getTime();

    var msec = diff;
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;

    return hh + ":" + mm;
  };

  // const css = `
  // @page {
  //   margin: 0px;
  //   size: A4 portrait;
  //   orphans: 2;
  //   window: 2;
  // }`;

  // const head = document.head || document.getElementsByTagName('head')[0];
  // const style = document.createElement('style');
  // style.media = 'print';
  // style.appendChild(document.createTextNode(css));

  let filename = "";

  useEffect(() => {
    filename =
      props.data.agentInfo && props.data.agentInfo.agentLogo != "NA"
        ? props.data.agentInfo.agentLogo
        : "company_logo.png";

    if (isPerPassenger) {
      let _airFare =
        props.passenger.type === "Adult"
          ? props.data.adultFareBreakUpOneWay.airFare
          : props.passenger.type === "Child"
          ? props.data.childFareBreakUpOneWay.airFare
          : props.data.infantFareBreakUpOneWay.airFare;
      let _airFare_return =
        props.passenger.type === "Adult"
          ? props.data.adultFareBreakUpReturn.airFare
          : props.passenger.type === "Child"
          ? props.data.childFareBreakUpReturn.airFare
          : props.data.infantFareBreakUpReturn.airFare;

      let _taxes =
        props.passenger.type === "Adult"
          ? props.data.adultFareBreakUpOneWay.taxes
          : props.passenger.type === "Child"
          ? props.data.childFareBreakUpOneWay.taxes
          : props.data.infantFareBreakUpOneWay.taxes;
      let _taxes_return =
        props.passenger.type === "Adult"
          ? props.data.adultFareBreakUpReturn.taxes
          : props.passenger.type === "Child"
          ? props.data.childFareBreakUpReturn.taxes
          : props.data.infantFareBreakUpReturn.taxes;

      let _gst =
        props.passenger.type === "Adult"
          ? props.data.adultFareBreakUpOneWay.otherTaxes !== null &&
            props.data.adultFareBreakUpOneWay.otherTaxes.K3
          : props.passenger.type === "Child"
          ? props.data.childFareBreakUpOneWay.otherTaxes !== null &&
            props.data.childFareBreakUpOneWay.otherTaxes.K3
          : props.data.infantFareBreakUpOneWay.otherTaxes !== null &&
            props.data.infantFareBreakUpOneWay.otherTaxes.K3;

      let _gst_return = 0;
      let noOfJourney = 1 * props.data.passengers.length;

      if (
        props.data.adultFareBreakUpReturn.otherTaxes &&
        props.data.adultFareBreakUpReturn.otherTaxes !== null
      ) {
        _gst_return =
          props.passenger.type === "Adult"
            ? props.data.adultFareBreakUpReturn.otherTaxes.K3
            : props.passenger.type === "Child"
            ? props.data.childFareBreakUpReturn.otherTaxes.K3
            : props.data.infantFareBreakUpReturn.otherTaxes.K3;

        noOfJourney = 2 * props.data.passengers.length;
      }

      let _mealbaggage = 0;
      let _mealbaggage_return = 0;

      let _meal = "";
      let _meal_return = "";

      let _baggage = "";
      let _baggage_return = "";

      let _seat = "";
      let _seat_return = "";

      if (props.journeyType === "return") {
        _airFare = 0;
        _taxes = 0;
        _gst = 0;
        _mealbaggage = 0;
        _meal = "";
        _baggage = "";
        _seat = "";

        _mealbaggage_return += parseInt(props.passenger.return_meal);
        _mealbaggage_return += parseInt(props.passenger.return_baggage);
        _mealbaggage_return += parseInt(props.passenger.return_seat);

        setMealBaggage(parseInt(_mealbaggage_return));

        _meal_return = props.passenger.return_meal_description;
        _baggage_return = props.passenger.return_baggage_description;
        _seat_return = props.passenger.return_seat_description;

        setMeal(_meal_return);
        setBaggage(_baggage_return);
        setSeat(_seat_return);
      } else if (props.journeyType === "onward") {
        _airFare_return = 0;
        _taxes_return = 0;
        _gst_return = 0;
        _mealbaggage_return = 0;
        _meal_return = "";
        _baggage_return = "";
        _seat_return = "";

        _mealbaggage += parseInt(props.passenger.onward_meal);
        _mealbaggage += parseInt(props.passenger.onward_baggage);
        _mealbaggage += parseInt(props.passenger.onward_seat);
        setMealBaggage(parseInt(_mealbaggage));

        _meal = props.passenger.onward_meal_description;
        _baggage = props.passenger.onward_baggage_description;
        _seat = props.passenger.onward_seat_description;
        setMeal(_meal);
        setBaggage(_baggage);
        setSeat(_seat);
      }

      let _total =
        parseInt(_airFare) +
        parseInt(_airFare_return) +
        parseInt(_taxes) +
        parseInt(_taxes_return) +
        parseInt(_mealbaggage) +
        parseInt(_mealbaggage_return);

      setBasefare(parseInt(_airFare) + parseInt(_airFare_return));
      setTaxes(
        parseInt(_taxes) +
          parseInt(_taxes_return) -
          (parseInt(_gst) + parseInt(_gst_return)) +
          parseInt(props.markup / noOfJourney)
      );
      setGst(parseInt(_gst) + parseInt(_gst_return));
      setMealBaggage(parseInt(_mealbaggage) + parseInt(_mealbaggage_return));

      setTotal(parseInt(_total) + parseInt(props.markup / noOfJourney)); // divided it by noOfJourney (2) so that half of it in onward flight ticket and half in return
    } else if (props.journeyType === "onward") {
      let noOfJourney = 1;
      if (props.data.adultFareBreakUpReturn.otherTaxes) {
        noOfJourney = 2;
      }

      let _airFare = 0;
      let _taxes = 0;
      let _gst = 0;
      let _meal = "";
      let _baggage = "";
      let _seat = "";
      let _mealbaggage = 0;
      props.data.passengers.map((passenger, index) => {
        _airFare += parseInt(
          passenger.type === "Adult"
            ? props.data.adultFareBreakUpOneWay.airFare
            : passenger.type === "Child"
            ? props.data.childFareBreakUpOneWay.airFare
            : props.data.infantFareBreakUpOneWay.airFare
        );
        _taxes += parseInt(
          passenger.type === "Adult"
            ? props.data.adultFareBreakUpOneWay.taxes
            : passenger.type === "Child"
            ? props.data.childFareBreakUpOneWay.taxes
            : props.data.infantFareBreakUpOneWay.taxes
        );
        if (
          props.data.adultFareBreakUpOneWay.otherTaxes &&
          props.data.adultFareBreakUpOneWay.otherTaxes !== null &&
          parseInt(
            passenger.type === "Adult"
              ? props.data.adultFareBreakUpOneWay.otherTaxes.K3
              : passenger.type === "Child"
              ? props.data.childFareBreakUpOneWay.otherTaxes !== null &&
                props.data.childFareBreakUpOneWay.otherTaxes.K3
              : props.data.infantFareBreakUpOneWay.otherTaxes !== null &&
                props.data.infantFareBreakUpOneWay.otherTaxes.K3
          ) > 0
        ) {
          _gst += parseInt(
            passenger.type === "Adult"
              ? props.data.adultFareBreakUpOneWay.otherTaxes !== null &&
                  props.data.adultFareBreakUpOneWay.otherTaxes.K3
              : passenger.type === "Child"
              ? props.data.childFareBreakUpOneWay.otherTaxes !== null &&
                props.data.childFareBreakUpOneWay.otherTaxes.K3
              : props.data.infantFareBreakUpOneWay.otherTaxes !== null &&
                props.data.infantFareBreakUpOneWay.otherTaxes.K3
          );
        }

        _mealbaggage += parseInt(passenger.onward_meal);
        _mealbaggage += parseInt(passenger.onward_baggage);
        _mealbaggage += parseInt(passenger.onward_seat);

        _meal = passenger.onward_meal_description;
        _baggage = passenger.onward_baggage_description;
        _seat = passenger.onward_seat_description;
      });

      let _total =
        parseInt(_airFare) + parseInt(_taxes) + parseInt(_mealbaggage);

      setBasefare(parseInt(_airFare));
      setTaxes(
        parseInt(_taxes) + parseInt(props.markup / noOfJourney) - parseInt(_gst)
      );
      setGst(parseInt(_gst));
      setMealBaggage(parseInt(_mealbaggage));

      setMeal(_meal);
      setBaggage(_baggage);
      setSeat(_seat);

      setTotal(parseInt(_total) + parseInt(props.markup / noOfJourney)); // divided it by noOfJourney (2) so that half of it in onward flight ticket and half in return
    } else if (props.journeyType === "return") {
      let noOfJourney = 2;

      let _airFare_return = 0;
      let _taxes_return = 0;
      let _gst_return = 0;

      let _meal_return = "";
      let _baggage_return = "";
      let _seat_return = "";
      let _mealbaggage_return = 0;
      props.data.passengers.map((passenger, index) => {
        _airFare_return += parseInt(
          passenger.type === "Adult"
            ? props.data.adultFareBreakUpReturn.airFare
            : passenger.type === "Child"
            ? props.data.childFareBreakUpReturn.airFare
            : props.data.infantFareBreakUpReturn.airFare
        );
        _taxes_return += parseInt(
          passenger.type === "Adult"
            ? props.data.adultFareBreakUpReturn.taxes
            : passenger.type === "Child"
            ? props.data.childFareBreakUpReturn.taxes
            : props.data.infantFareBreakUpReturn.taxes
        );
        if (
          props.data.adultFareBreakUpReturn.otherTaxes &&
          parseInt(
            passenger.type === "Adult"
              ? props.data.adultFareBreakUpReturn.otherTaxes.K3
              : passenger.type === "Child"
              ? props.data.childFareBreakUpReturn.otherTaxes.K3
              : props.data.infantFareBreakUpReturn.otherTaxes.K3
          ) > 0
        ) {
          _gst_return += parseInt(
            passenger.type === "Adult"
              ? props.data.adultFareBreakUpReturn.otherTaxes.K3
              : passenger.type === "Child"
              ? props.data.childFareBreakUpReturn.otherTaxes.K3
              : props.data.infantFareBreakUpReturn.otherTaxes.K3
          );
        }
        _mealbaggage_return += parseInt(passenger.return_meal);
        _mealbaggage_return += parseInt(passenger.return_baggage);
        _mealbaggage_return += parseInt(passenger.return_seat);

        _meal_return = passenger.return_meal_description;
        _baggage_return = passenger.return_baggage_description;
        _seat_return = passenger.return_seat_description;
      });

      let _total_return =
        parseInt(_airFare_return) +
        parseInt(_taxes_return) +
        parseInt(_mealbaggage_return);

      setBasefare(parseInt(_airFare_return));
      setTaxes(
        parseInt(_taxes_return) -
          parseInt(_gst_return) +
          parseInt(props.markup / noOfJourney)
      );
      setGst(parseInt(_gst_return));
      setMealBaggage(parseInt(_mealbaggage_return));
      setMeal(_meal_return);
      setBaggage(_baggage_return);
      setSeat(_seat_return);

      setTotal(parseInt(_total_return) + parseInt(props.markup / noOfJourney)); // divided it by noOfJourney (2) so that half of it in onward flight ticket and half in return
    } else {
      let _airFare = props.data.flightSummary.basefare;
      let _taxes = props.data.flightSummary.taxes;
      let _managementFee = props.data.flightSummary.surcharge;
      let _total = _airFare + _taxes + _managementFee;

      let _gst = props.data.flightSummary.gst;

      setBasefare(_airFare);
      setTaxes(_taxes + _managementFee - _gst);
      setGst(parseInt(_gst));
      setTotal(parseInt(_total) + parseInt(props.markup));
    }

    // head.appendChild(style);
  }, []);

  return (
    <div>
      <div
        className="container-print"
        style={{
          boxSizing: `unset`,
          border: `solid 1px ${theme.palette.background.dark}`,
        }}
      >
        <div
        // className={classes.ticketUiWrapper}
        >
          <div
            className="redline"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {/* {(props.data.bookingStatus === 'SUCCESSCan' || props.data.bookingStatus === 'SUCCESS') && */}
            <p style={{ color: theme.palette.text.dark, fontWeight: 500 }}>
              {props.data.bookingStatus === "SuccessTkd"
                ? "E-Ticket"
                : "This is not an e-ticket"}
            </p>
            {/* } */}
          </div>
          <div className="content-wrapper">
            <section className="header-section">
              <div>
                <div>
                  {props.data &&
                  props.data.agentInfo &&
                  userId &&
                  props.data.agentInfo.agentLogo != "" &&
                  props.data.agentInfo.agentLogo != "NA" &&
                  props.data.agentInfo.agentLogo != "N/A" &&
                  props.data.agentInfo.agentLogo != null ? (
                    <img
                      className="logo"
                      src={
                        GET_FILE_UPLOAD +
                        "?fileName=" +
                        props.data.agentInfo.agentLogo +
                        "&type=AGENT_LOGO&loggedInUserId=" +
                        userId
                      }
                      style={{ width: "unset", height: 100 }}
                    />
                  ) : (
                    <>
                      <img
                        className="logo"
                        src={props.companylogo}
                        height={100}
                      />
                    </>
                  )}
                </div>
                <div className="booking-info">
                  <div className="bold company-brand-name">
                    {props.data.agentInfo && props.data.agentInfo.agentCorpName}
                  </div>
                  <table className="table1 ticket-header-info">
                    <tbody>
                      <tr className="tr1">
                        <td className="align-text-left td1" width="27%">
                          <p className="p1">Booking Time:</p>
                        </td>

                        <td
                          className="align-text-left td1 booking-info-txt"
                          width="10%"
                        >
                          <p className="p1" style={{ textAlign: "left" }}>
                            {props.data.bookingDate}
                          </p>
                        </td>
                      </tr>
                      <tr className="tr1">
                        <td className="align-text-left td1" width="27%">
                          <p className="p1">Booking ID:</p>
                        </td>
                        <td
                          className="align-text-left td1 booking-info-txt"
                          width="10%"
                        >
                          <p className="p1" style={{ textAlign: "left" }}>
                            {props.data.bookingRef}
                          </p>
                        </td>
                      </tr>
                      {props.data.bookingStatus === "SuccessTkd" && (
                        <tr className="tr1">
                          <td className="align-text-left td1" width="27%">
                            <p className="p1">GDS PNR:</p>
                          </td>
                          <td
                            className="align-text-left td1 booking-info-txt"
                            width="10%"
                          >
                            <p className="p1" style={{ textAlign: "left" }}>
                              {props.data.pnrNo}
                            </p>
                          </td>
                        </tr>
                      )}
                      <tr className="tr1">
                        <td className="align-text-left td1" width="27%">
                          <p className="p1">BOOKING STATUS:</p>
                        </td>
                        {/* <td className="align-text-left td1 booking-info-txt" width="10%">
                        <p className="p1" style={{ textAlign: 'left' }}>{(props.data.bookingStatus === 'SUCCESSCan' ||  props.data.bookingStatus === 'SUCCESS') ? 'Not Confirmed' : 'Confirmed'}</p>
                      </td> */}
                        <td
                          className="align-text-left td1 booking-info-txt"
                          width="8%"
                          style={{ minWidth: 80 }}
                        >
                          <p className="p1" style={{ textAlign: "left" }}>
                            {props.isStatusLoading === false
                              ? props.data.bookingStatus === "SUCCESSCan"
                                ? "Cancelled"
                                : props.data.bookingStatus === "SuccessTkd"
                                ? "Confirmed"
                                : "Under Process"
                              : "updating ..."}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            {props.journey &&
              props.journey.map((data, index) => {
                return (
                  <section className="itinerary-section" key={index}>
                    {index > 0 &&
                      props.journey[index - 1] != props.journey[index] && (
                        <div
                          style={{
                            paddingRight: "3%",
                            textAlign: "right",
                            fontWeight: 600,
                            marginTop: 10,
                          }}
                        >
                          Plane Change at {data.originAirportDesc} | Layover{" "}
                          {getTimeDiffrence(
                            props.journey[index - 1].arrivalDateTimeRaw,
                            data.departureDateTimeRaw
                          )}{" "}
                          Hours
                        </div>
                      )}

                    {/*  <div style={{paddingRight: '3%', textAlign: 'right', fontWeight: 600}}>&nbsp;&nbsp;</div> */}

                    <div
                      className={`pnr-section`}
                      style={index > 0 ? { marginTop: 17 } : {}}
                    >
                      {/* ${index > 0 ? '-repeat' : ''} */}
                      {props.data.bookingStatus === "SuccessTkd" && (
                        <>
                          <p
                            className="red-text p1"
                            style={{ fontWeight: 500 }}
                          >
                            Your PNR
                          </p>
                          <p
                            className="red-text bold p1"
                            style={{
                              fontSize: 22,
                              marginTop: "-0.3em !important",
                            }}
                          >
                            {data.pnrNo === " " ? "NA" : data.pnrNo}
                          </p>
                        </>
                      )}
                      <hr className="hr1" />{" "}
                      <img
                        height="35"
                        src={
                          GET_FILE_UPLOAD +
                          "?fileName=" +
                          data.flightName +
                          ".png&type=AIRLINE_IMAGES"
                        }
                        alt={data.flightName + " icon"}
                      />
                      <p
                        className="bold p1"
                        style={{ marginTop: "-11px !important" }}
                      >
                        {data.flightName}-{data.flightCode}
                      </p>
                      <p
                        className="bold p1"
                        style={{ marginTop: "-11px !important" }}
                      >
                        ({data.carrierName})
                      </p>
                    </div>
                    <div className="backrow-flightinfo">
                      <div className="city" style={{ marginTop: 30 }}>
                        <p className="bold p1">
                          {data.originCityName} ({data.departureStation})
                        </p>
                        <p className="bold p1">To</p>
                        <p className="bold p1">
                          {data.destinationCityName} ({data.arrivalStation})
                        </p>
                      </div>
                      <div
                        className="flight-info-right-column"
                        style={{ marginTop: 25 }}
                      >
                        <div
                          className="flight-info-coloumn"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <img className="plane-icon" src={FlightTakeoffIcon} />
                          <div className="arrival-header">
                            {/* <img className="plane-icon" src={FlightTakeoffIcon} /> */}
                            <p className="bold p1">Departure</p>
                          </div>
                          <hr
                            className="hr1"
                            style={{
                              border: `1px solid ${theme.palette.secondary.taupeGray}`,
                              background: theme.palette.secondary.taupeGray,
                            }}
                          />
                          <p className="red-text bold p1 arrival-header-time">
                            {data.departureDateTime.substring(0, 16)}
                            <br />
                            {data.departureDateTime.substring(17, 25)}
                          </p>
                        </div>
                        <div className="flight-info-coloumn">
                          <div className="red flight-info-duration">
                            <p className="p1">
                              {" "}
                              Duration:
                              <br />
                              {data.journeyTime}
                            </p>
                          </div>
                        </div>
                        <div
                          className="flight-info-coloumn"
                          style={{
                            float: "right",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <img className="plane-icon" src={FlightLandingIcon} />
                          <div className="arrival-header">
                            <p className="bold p1">Arrival</p>
                          </div>
                          <hr
                            className="hr1"
                            style={{
                              border: `1px solid ${theme.palette.secondary.taupeGray}`,
                              background: theme.palette.secondary.taupeGray,
                            }}
                          />
                          <p className="red-text bold p1 arrival-header-time">
                            {data.arrivalDateTime.substring(0, 16)}
                            <br />
                            {data.arrivalDateTime.substring(17, 25)}
                          </p>
                        </div>
                      </div>
                      <div className="additional-flight-info">
                        <p className="p1">
                          {data.departureTerminal == " "
                            ? ""
                            : "DEPARTURE TERMINAL - " +
                              data.departureTerminal +
                              " |"}{" "}
                          {data.arrivalTerminal == " "
                            ? ""
                            : "ARRIVAL TERMINAL - " +
                              data.arrivalTerminal +
                              " |"}{" "}
                          {data.flightBaggageInfo == " "
                            ? ""
                            : "Check-in Baggage" +
                              data.flightBaggageInfo +
                              " | "}{" "}
                          Cabin baggage - 7 Kg | {props.data.requestedFareType}
                        </p>
                      </div>
                    </div>
                  </section>
                );
              })}
            <section className="passengers-section">
              <div className="label-print">
                <p className="bold p1">PASSENGER(S) DETAIL</p>
              </div>
              <div className="row1">
                <table className="table1">
                  <thead className="gray thead1">
                    <tr className="tr1">
                      <th className="th1">
                        <p className="p1">S.No</p>
                      </th>
                      <th className="th1">
                        <p className="p1">Title</p>
                      </th>
                      <th className="th1">
                        <p className="p1">First Name</p>
                      </th>
                      <th className="th1">
                        <p className="p1">Surname</p>
                      </th>
                      <th className="th1">
                        <p className="p1">Type</p>
                      </th>
                      <th className="th1">
                        <p className="p1">FF No.</p>
                      </th>
                      {props.data.bookingStatus === "SuccessTkd" && (
                        <>
                          <th className="th1">
                            <p className="p1">E-Ticket No.</p>
                          </th>
                          <th className="th1">
                            <p className="p1">Airline PNR</p>
                          </th>
                        </>
                      )}
                      <th className="th1">
                        <p className="p1">Add On</p>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {!isPerPassenger &&
                      props.data.passengers &&
                      props.data.passengers.map((data1, index) => {
                        return (
                          <tr className="tr1" key={index}>
                            <td className="td1">
                              <p className="p1">{index + 1}</p>
                            </td>
                            <td className="td1">
                              <p className="p1">
                                <strong> {data1.title} </strong>
                              </p>
                            </td>
                            <td className="td1">
                              <p className="p1">
                                <strong> {data1.firstName} </strong>
                              </p>
                            </td>
                            <td className="td1">
                              <p className="p1">
                                <strong> {data1.lastName} </strong>
                              </p>
                            </td>
                            <td className="td1">
                              <p className="p1">{data1.type}</p>
                            </td>
                            <td className="td1">
                              <p className="p1">{data1.oneffno}</p>
                            </td>
                            {props.data.bookingStatus === "SuccessTkd" && (
                              <>
                                <td className="td1">
                                  <p className="p1">{data1.eticketNo}</p>
                                </td>
                                <td className="td1">
                                  <p className="p1">
                                    <strong>{data1.airlinePNR}</strong>
                                  </p>
                                </td>
                              </>
                            )}
                            <td className="passenger-addon-detail td1">
                              {props.journeyType === "onward" ? (
                                <>
                                  {data1.onward_baggage_description == ""
                                    ? ""
                                    : data1.onward_baggage_description
                                        .split(",")
                                        .map((bag, index) => {
                                          return (
                                            <p style={{ fontSize: 10 }}>
                                              Addon Baggage ({bag.split("|")[0]}
                                              ) - {bag.split("|")[1]} KG.
                                            </p>
                                          );
                                        })}

                                  {data1.onward_meal_description == ""
                                    ? ""
                                    : data1.onward_meal_description
                                        .split(",")
                                        .map((d, index) => {
                                          return (
                                            <p style={{ fontSize: 10 }}>
                                              Meal ({d.split("|")[0]}) -{" "}
                                              {d.split("|")[1]}
                                            </p>
                                          );
                                        })}

                                  {data1.onward_seat_description == ""
                                    ? ""
                                    : data1.onward_seat_description
                                        .split(",")
                                        .map((d, index) => {
                                          return (
                                            <p style={{ fontSize: 10 }}>
                                              Seat Number ({d.split("|")[0]}) -{" "}
                                              {d.split("|")[1]}
                                            </p>
                                          );
                                        })}
                                </>
                              ) : (
                                <>
                                  {data1.return_baggage_description == ""
                                    ? ""
                                    : data1.return_baggage_description
                                        .split(",")
                                        .map((bag, index) => {
                                          return (
                                            <p style={{ fontSize: 10 }}>
                                              Addon Baggage ({bag.split("|")[0]}
                                              ) - {bag.split("|")[1]} KG.
                                            </p>
                                          );
                                        })}

                                  {data1.return_meal_description == ""
                                    ? ""
                                    : data1.return_meal_description
                                        .split(",")
                                        .map((d, index) => {
                                          return (
                                            <p style={{ fontSize: 10 }}>
                                              Meal ({d.split("|")[0]}) -{" "}
                                              {d.split("|")[1]}
                                            </p>
                                          );
                                        })}

                                  {data1.return_seat_description == ""
                                    ? ""
                                    : data1.return_seat_description
                                        .split(",")
                                        .map((d, index) => {
                                          return (
                                            <p style={{ fontSize: 10 }}>
                                              Seat Number ({d.split("|")[0]}) -{" "}
                                              {d.split("|")[1]}
                                            </p>
                                          );
                                        })}
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}

                    {isPerPassenger && (
                      <tr className="tr1">
                        <td className="td1">
                          <p className="p1">{1}</p>
                        </td>
                        <td className="td1">
                          <p className="p1">
                            <strong>
                              {" "}
                              {props.passenger.title}{" "}
                              {props.passenger.firstName}{" "}
                              {props.passenger.lastName}{" "}
                            </strong>
                          </p>
                        </td>
                        <td className="td1">
                          <p className="p1">{props.passenger.type}</p>
                        </td>
                        <td className="td1">
                          <p className="p1">{props.passenger.oneffno}</p>
                        </td>
                        {props.data.bookingStatus === "SuccessTkd" && (
                          <>
                            <td className="td1">
                              <p className="p1">{props.passenger.eticketNo}</p>
                            </td>
                            <td className="td1">
                              <p className="p1">
                                <strong>{props.passenger.airlinePNR}</strong>
                              </p>
                            </td>
                          </>
                        )}
                        <td className="passenger-addon-detail td1">
                          {/* {baggage == ""? '': <p>Addon Baggage – {baggage} KG.</p>}
                      {meal == ""? '': <p>Meal –{meal } </p>}
                      {seat== ""? '': <p>Seat Number – {seat} </p>} */}

                          {props.passenger.onward_baggage_description == ""
                            ? ""
                            : props.passenger.onward_baggage_description
                                .split(",")
                                .map((bag, index) => {
                                  return (
                                    <p style={{ fontSize: 10 }}>
                                      Addon Baggage ({bag.split("|")[0]}) -{" "}
                                      {bag.split("|")[1]} KG.
                                    </p>
                                  );
                                })}

                          {props.passenger.onward_meal_description == ""
                            ? ""
                            : props.passenger.onward_meal_description
                                .split(",")
                                .map((d, index) => {
                                  return (
                                    <p style={{ fontSize: 10 }}>
                                      Meal ({d.split("|")[0]}) -{" "}
                                      {d.split("|")[1]}
                                    </p>
                                  );
                                })}

                          {props.passenger.onward_seat_description == ""
                            ? ""
                            : props.passenger.onward_seat_description
                                .split(",")
                                .map((d, index) => {
                                  return (
                                    <p style={{ fontSize: 10 }}>
                                      Seat Number ({d.split("|")[0]}) -{" "}
                                      {d.split("|")[1]}
                                    </p>
                                  );
                                })}
                        </td>
                      </tr>
                    )}
                    <tr className="tr1">
                      <td
                        className="td1"
                        colSpan={10}
                        width="85%"
                        style={{
                          background:
                            theme.palette.background.mournMountainSnow,
                          fontWeight: 700,
                        }}
                      >
                        <p className="p1">
                          Passenger Contact Number:{" "}
                          <a href={`tel:` + props.data.mobileNumber}>
                            {props.data.mobileNumber}
                          </a>{" "}
                          | Mail:
                          <a href={`mailto:` + props.data.emailId}>
                            {props.data.emailId}
                          </a>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>

            {!isGSTHide && props.data.gstInfo && props.data.gstInfo.gstNo && (
              <section className="passengers-section">
                <div className="label-print">
                  <p className="bold p1">GST DETAIL</p>
                </div>
                <div className="row1">
                  <table className="table1">
                    <thead className="gray thead1">
                      <tr className="tr1">
                        <th className="th1">
                          <p className="p1">GST No</p>
                        </th>
                        <th className="th1">
                          <p className="p1">Name</p>
                        </th>
                        <th className="th1">
                          <p className="p1">Address</p>
                        </th>
                        <th className="th1">
                          <p className="p1">Email</p>
                        </th>
                        <th className="th1">
                          <p className="p1">Mobile</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr1">
                        <td className="td1">
                          <p className="p1">
                            <strong>{props.data.gstInfo.gstNo}</strong>
                          </p>
                        </td>
                        <td className="td1">
                          <p className="p1">
                            {props.data.gstInfo.gstCompanyName}
                          </p>
                        </td>
                        <td className="td1">
                          <p className="p1">
                            {props.data.gstInfo.gstCompanyAddress}
                          </p>
                        </td>
                        <td className="td1">
                          <p className="p1">{props.data.gstInfo.gstEmail}</p>
                        </td>
                        <td className="td1">
                          <p className="p1">{props.data.gstInfo.gstMobile}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            )}

            {props.data.flightSummary && (
              <section
                className="fare-section"
                style={{
                  height: isFareHide ? (isAddressHide ? `5em` : `9em`) : `14em`,
                }}
              >
                {!isFareHide && (
                  <div className="label-print">
                    <p className="bold p1">FARE DETAIL</p>
                  </div>
                )}

                <div className="row1">
                  {!isFareHide && (
                    <div className="w2 left fare-brakeup col">
                      <table className="table1">
                        <thead className="gray thead1">
                          <tr className="tr1">
                            <th colSpan={2} className="th1">
                              <p className="p1" style={{ fontSize: "8pt" }}>
                                {/* THIS IS REGULAR FARE | THIS IS NON-REFUNDABLE FARE */}
                                {props.data.onwardJourney[0].spMessage == ""
                                  ? "This is regular fare"
                                  : "This is " +
                                    props.data.onwardJourney[0].spMessage.toLowerCase()}
                              </p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="tr1">
                            <td className="align-text-left bold td1">
                              <p className="p1">Base Fare</p>
                            </td>
                            <td className="align-text-right td1">
                              <p className="p1">
                                {currencyFormatter(basefare)}
                              </p>
                            </td>
                          </tr>
                          <tr className="tr1">
                            <td className="align-text-left bold td1">
                              <p className="p1">Airline Taxes and Fees</p>
                            </td>
                            <td className="align-text-right td1">
                              <p className="p1">
                                {currencyFormatter(parseInt(taxes))}
                              </p>
                            </td>
                          </tr>
                          <tr className="tr1">
                            <td className="align-text-left bold td1">
                              <p className="p1">Airline GST</p>
                            </td>
                            <td className="align-text-right td1">
                              <p className="p1">{currencyFormatter(gst)}</p>
                            </td>
                          </tr>
                          <tr className="tr1">
                            <td className="align-text-left bold td1">
                              <p className="p1">
                                Meal/Seat/Baggage/Misc Charges
                              </p>
                            </td>
                            <td className="align-text-right td1">
                              <p className="p1">
                                {currencyFormatter(mealbaggage)}
                              </p>
                            </td>
                          </tr>
                          <tr className="tr1">
                            <td className="td1">
                              <p className="align-text-left bold p1">Total</p>
                            </td>
                            <td className="align-text-right td1">
                              <p className="p1">
                                {currencyFormatter(parseInt(total))}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                  <div
                    className="w2 right col"
                    style={{ width: isFareHide ? `100%` : `48%` }}
                  >
                    <div
                      className="row1"
                      style={{
                        display: isFareHide && isAddressHide ? "flex" : "block",
                        justifyContent:
                          isFareHide && isAddressHide ? "center" : "unset",
                      }}
                    >
                      {!isAddressHide && props.data.agentInfo && (
                        <table
                          className="agency-contact right table1"
                          style={{
                            float: isFareHide ? "left" : "right",
                            width: !isFareHide ? `100%` : `48%`,
                          }}
                        >
                          <thead className="red thead1">
                            <tr className="tr1">
                              <th className="th1">
                                <p>Agency Contact Detail</p>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="tr1">
                              <td className="td1">
                                <p className="p1">
                                  {props.data.agentInfo.agentAddress},{" "}
                                  {props.data.agentInfo.agentCity}
                                  <br />
                                  {props.data.agentInfo.agentState},
                                  {props.data.agentInfo.agentCountry},{" "}
                                  {props.data.agentInfo.agentPincode}
                                </p>
                              </td>
                            </tr>
                            <tr className="tr1">
                              <td className="td1">
                                <p className="p1">
                                  <a
                                    href={
                                      `tel:` +
                                      props.data.agentInfo.agentMobileNumber
                                    }
                                  >
                                    {props.data.agentInfo.agentMobileNumber}
                                  </a>
                                </p>
                              </td>
                            </tr>
                            <tr className="tr1">
                              <td className="td1">
                                <p className="p1">
                                  <a
                                    href={
                                      `mailto:` +
                                      props.data.agentInfo.agentEmail
                                    }
                                  >
                                    {props.data.agentInfo.agentEmail}
                                  </a>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}

                      <table
                        className="airline-contact right table1"
                        style={{
                          float: isFareHide && isAddressHide ? "left" : "right",
                          width: !isFareHide ? `100%` : `48%`,
                        }}
                      >
                        <thead className="gray thead1">
                          <tr className="tr1">
                            <th colSpan={2} className="th1">
                              <p className="p1">Airline Contact Detail</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.journeyType === "onward" &&
                            props.data.onwardJourney &&
                            props.data.onwardJourney.length > 0 && (
                              <tr className="tr1">
                                <td style={{ width: "12em" }} className="td1">
                                  <img
                                    height="20"
                                    src={
                                      GET_FILE_UPLOAD +
                                      "?fileName=" +
                                      props.data.onwardJourney[0].flightName +
                                      ".png" +
                                      "&type=AIRLINE_IMAGES"
                                    }
                                    alt={
                                      props.data.onwardJourney[0].flightName +
                                      " icon"
                                    }
                                  />{" "}
                                </td>
                                <td className="td1">
                                  <p className="p1">
                                    {props.data.onwardJourney[0].tollfree}
                                  </p>
                                </td>
                              </tr>
                            )}

                          {props.journeyType === "return" &&
                            props.data.returnJourney &&
                            props.data.returnJourney.length > 0 && (
                              <tr className="tr1">
                                <td style={{ width: "12em" }} className="td1">
                                  <img
                                    height="20"
                                    src={
                                      GET_FILE_UPLOAD +
                                      "?fileName=" +
                                      props.data.returnJourney[0].flightName +
                                      ".png" +
                                      "&type=AIRLINE_IMAGES"
                                    }
                                    alt={
                                      props.data.returnJourney[0].flightName +
                                      " icon"
                                    }
                                  />{" "}
                                </td>
                                <td className="td1">
                                  <p className="p1">
                                    {props.data.returnJourney[0].tollfree}
                                  </p>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            )}

            <section className="footer-section">
              <div className="footer-ribbon-redline-wrap">
                {/* <hr className="hr2" /> */}
                <div
                  className="footer-ribbon-redline"
                  // className="hr2-over"
                >
                  <p className="p1">
                    *Please verify departure time with airline before your
                    flight
                  </p>
                </div>
              </div>

              <h1 style={{ textDecoration: "underline", fontWeight: 700 }}>
                Terms and Conditions
              </h1>
              <ul className="ul1">
                <li className="li1">
                  All Travel timings are as per the local time in 24 hrs format
                </li>
                <li className="li1">
                  Check passenger name and Travel Itinerary details carefully
                </li>
                <li className="li1">
                  Names provided should be with Valid Government Approvaed Photo
                  ID/ Passport
                </li>
                <li className="li1">
                  Automated Ticketing time limit provided as per airline
                </li>
                <li className="li1">
                  Ensure Sufficient Funds are available for confirmation of
                  services
                </li>
                <li className="li1">
                  Fares and Taxes are subject to availability at the time of
                  issuance
                </li>
                <li className="li1">
                  We are not liable if any increase occurs due to ROE
                  fluctuation and taxes
                </li>
                <li className="li1">
                  Check all Visa requirement for Destination and Transit country
                  with the respective airline and embassy
                </li>
                <li className="li1">
                  You must web check-in on the airline website and obtain a
                  boarding pass
                </li>
                <li className="li1">
                  Reach the terminal at least 2 hours prior to the departure for
                  domestic flight and 4 hours prior to the departure of
                  international flight
                </li>
                <li className="li1">
                  For departure terminal please check with the airline first
                </li>
                <li className="li1">
                  Date & Time is calculated based on the local time of the
                  city/destination
                </li>
                <li className="li1">
                  Use the Airline PNR for all Correspondence directly with the
                  Airline
                </li>
                <li className="li1">
                  For rescheduling/cancellation within 4 hours of the departure
                  time contact the airline directly
                </li>
                <li className="li1">
                  Your ability to travel is at the sole discretion of the
                  airport authorities and we shall not be held responsible
                </li>
              </ul>
            </section>

            <section className="footer-section">
              <div className="restricted-items-div">
                <h3 style={{ color: "red", fontWeight: 600 }}>
                  RESTRICTED ITEMS
                </h3>
                <img
                  src={`${GET_FILE_UPLOAD}?fileName=restricted_items.png&type=EMAIL_IMAGES`}
                />
              </div>
            </section>
          </div>

          {props.data.returnJourney &&
          props.data.returnJourney.length === 0 &&
          !isPerPassenger ? (
            <div className="redline bottom-footer">
              <p className="p1 upper-case">
                THANK YOU FOR CHOOSING{" "}
                {AppConstant.showBrandNameTicketHeader
                  ? AppConstant.ccName
                  : "US"}
                . HAVE A SAFE AND HAPPY JOURNEY
              </p>
            </div>
          ) : (
            <div className="redline bottom-footer">
              <p className="p1 upper-case">
                THANK YOU FOR CHOOSING{" "}
                {AppConstant.showBrandNameTicketHeader
                  ? AppConstant.ccName
                  : "US"}
                . HAVE A SAFE AND HAPPY JOURNEY
              </p>
            </div>
          )}
        </div>
      </div>
      <style>{printStyles(theme)}</style>
    </div>
  );
}

export default FlightTicketResult;

const printStyles = (theme) => {
  return `
  .redline, .red, .th1 {
    background: ${
      AppConstant.coloredTicket ? theme.palette.primary.main : "#DDDDDD"
    };
    color: ${
      AppConstant.coloredTicket ? theme.palette.primary.contrastText : "#333333"
    };
  }

  .redline p, .red p {
    color: ${
      AppConstant.coloredTicket ? theme.palette.primary.contrastText : "#333333"
    } !important;
  }
  `;
};
