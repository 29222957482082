import { makeStyles } from "@material-ui/core";
import React from "react";
import { localforageGetItem } from "oautils/oaForageUtils";
import { useSelector, useDispatch } from "react-redux";
import WebApi from "api/ApiConstants";
//import OffersSection from "./OffersSection";
import TopFlightRoutes from "./TopFlightRoutes";
import FlightSection from "./forms/FlightSection";
import MobileHotelSection from "components/LandingPage/for-mobile/MobileHotelSection";
import MobileFlightSection from "components/LandingPage/for-mobile/MobileFlightSection";
import MobileBusSection from "components/LandingPage/for-mobile/MobileBusSection";
import HotelSection from "./forms/HotelSection";
import BusSection from "./forms/BusSection";
import InsuranceSection from "./forms/InsuranceSection";
import TrainSearch from "./forms/Train/TrainSearch";
import { refreshUserSession } from "oautils/oaAuthUtils";
// import InsuranceSearch from "pages/module/insurance/InsuranceSearch";
import OaLoading from "pages/components/OaLoading";
import SearchEngineBg from "assets/Gostree/images/home/search-background-image.png";
import { AppStateContext } from "layouts/AppStateProvider";
import { Switch, Route } from "react-router-dom";
import HeaderFlightIcon from "assets/Gostree/images/icons/flight-take-off-icon-dark.png";
import HeaderHotelIcon from "assets/Gostree/images/icons/hotel-icon-dark.png";
import HeaderBusIcon from "assets/Gostree/images/icons/bus-icon-dark.png";
import InsuranceIcon from "assets/Gostree/images/icons/insurance-icon-dark.png";
import DmtIcon from "assets/Gostree/images/icons/moneytransferover.png";
import RechargeIcon from "assets/Gostree/images/icons/rechargeover.png";
import AadhaarpayIcon from "assets/Gostree/images/icons/aadhaarpayover.png";
import TrainIcon from "assets/Gostree/images/icons/trainover.png";

import Partners from "components/LandingPage/Gostree/Partners";
import WhyWithUs from "components/LandingPage/Gostree/WhyWithUs";
import IdleTimeoutEnquiryForm from "pages/module/IdleTimeoutEnquiryForm";
import cx from "classnames";
import DmtSection from "./forms/DmtSection";
import RechargeSection from "./forms/RechargeSection";
import AadhaarServicesSection from "./forms/AadhaarServicesSection";
import Login from "./LoginPage";

const HomePage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMounted = React.useRef(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [usertype, setUsertype] = React.useState(null);
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [corporateType, setCorporateType] = React.useState(null);
  const [serviceOptions, setServiceOptions] = React.useState([]);
  const [showServices, setShowServices] = React.useState(
    props.hideServices ? props.hideServices : props.showServices
  );
  const [routesItem, setRoutesItem] = React.useState([]);
  const [offers, setOffers] = React.useState([]);

  const LoaderView = () => {
    return (
      <div className="loader-view">
        <OaLoading loadertext="..." />
        <div>
          <span className="loader-text"></span>
        </div>
      </div>
    );
  };

  const FlightSearch = () => {
    return (
      <div className="section-content">
        <h3>
          {" "}
          <span>
            <HeaderServiceIcon src={HeaderFlightIcon} />
          </span>{" "}
          Find inexpensive airfare options for your travel
        </h3>
        <div className="search__box">
          {width < breakpoint ? <MobileFlightSection /> : <FlightSection />}
        </div>
      </div>
    );
  };

  const HotelSearch = () => {
    return (
      <div className="section-content">
        <h3>
          {" "}
          <span>
            <HeaderServiceIcon src={HeaderHotelIcon} />
          </span>{" "}
          Discover the collection of affordable luxury hotels
        </h3>
        <div className="search__box">
          {width < breakpoint ? <MobileHotelSection /> : <HotelSection />}
        </div>
      </div>
    );
  };

  const BusSearch = () => {
    return (
      <div className="section-content">
        <h3>
          {" "}
          <span>
            <HeaderServiceIcon src={HeaderBusIcon} />
          </span>{" "}
          Book Tickets Online and Enjoy Hassle Free Bus Journey
        </h3>
        <div className="search__box">
          {width < breakpoint ? <MobileBusSection /> : <BusSection />}
        </div>
      </div>
    );
  };

  const InsuranceSearch = () => {
    return (
      <div className="section-content">
        <h3>
          {" "}
          <span>
            <HeaderServiceIcon src={InsuranceIcon} />
          </span>{" "}
          Secure Your Journey & Travel Stress Free
        </h3>
        <div className="search__box">
          <InsuranceSection />
        </div>
      </div>
    );
  };

  const DmtSearch = () => {
    return (
      <div className="section-content">
        <h3>
          {" "}
          <span>
            <HeaderServiceIcon src={DmtIcon} />
          </span>{" "}
          Money Transfer
        </h3>
        <div className="search__box">
          <DmtSection />
        </div>
      </div>
    );
  };

  const RechargeSearch = () => {
    return (
      <div className="section-content">
        <h3>
          {" "}
          <span>
            <HeaderServiceIcon src={RechargeIcon} />
          </span>{" "}
          Recharge
        </h3>
        <div className="search__box">
          <RechargeSection />
        </div>
      </div>
    );
  };

  const AadhaarpaySearch = () => {
    return (
      <div className="section-content">
        <h3>
          {" "}
          <span>
            <HeaderServiceIcon src={AadhaarpayIcon} />
          </span>{" "}
          Aadhaarpay Services
        </h3>
        <div className="search__box">
          <AadhaarServicesSection />
        </div>
      </div>
    );
  };

  const TrainSearchComponent = () => {
    return (
      <div className="section-content">
        <h3>
          {" "}
          <span>
            <HeaderServiceIcon src={TrainIcon} />
          </span>{" "}
          IRCTC
        </h3>
        <div className="search__box">
          <TrainSearch />
        </div>
      </div>
    );
  };

  const callbackRefreshToken = (flag) => {
    setIsLoading(flag);
  };

  const getUserDetails = (mountStatus) => {
    localforageGetItem("user-details", function(err, value) {
      if (mountStatus && value) {
        if (value.userTypeAbv == "S") {
          setIsLoggedIn(true);
        }

        setShowServices(
          value.userType == "distributor"
            ? false
            : showServices != null
            ? showServices
            : true
        );

        setServiceOptions(value.serviceOption);

        setUsertype(value.userType);
        setUserTypeb2c(value.userType);
        setCorporateType(value.corporateType);
        setIsLoading(false);
      } else {
        console.log("error getting user details", err);
      }
    });
  };

  React.useEffect(() => {
    isMounted.current = true;
    refreshUserSession(dispatch, callbackRefreshToken);
    return () => (isMounted.current = false);
  }, []);

  // React.useEffect(() => {
  //   let mounted = true;
  //   if (mounted) {
  //     WebApi.getPopularRoutes(
  //       {
  //         callFrom: "web",
  //       },
  //       (resp) => {
  //         if (resp != null && resp.success) {
  //           // console.log(resp.data, "get pop");
  //           setRoutesItem(resp.data);
  //         } else {
  //           console.log("error resp null", resp);
  //         }
  //       },
  //       (error) => console.log("error", error)
  //     );
  //   }
  //   return () => (mounted = false);
  // }, []);

  React.useEffect(() => {
    getUserDetails(isMounted.current);
    if (isMounted.current) {
      WebApi.getExclusiveOffer(
        {
          callFrom: "web",
          offerCategory: "",
        },
        (resp) => {
          if (resp != null && resp.success) {
            // console.log(resp.data, "get pop");
            setOffers(resp.data);
            // setDataIs(true);
          } else {
            console.log("error resp null", resp);
          }
        },
        (error) => console.log("error", error)
      );
    }
  }, [isMounted.current]);

  const width = window.innerWidth;
  const breakpoint = 980;
  const currentURL = window.location.href; // returns the absolute URL of a page

  return (
    <div className={classes.root}>
      {/* <IdleTimeoutEnquiryForm /> */}
      {isLoggedIn ? (
        <div className="search__section">
          <Switch>
            <Route path="/flights" component={FlightSearch} />
            <Route path="/buses" component={BusSearch} />
            <Route path="/hotels" component={HotelSearch} />
            <Route path="/insurances" component={InsuranceSearch} />
            <Route path="/dmt" component={DmtSearch} />
            <Route path="/recharges" component={RechargeSearch} />
            <Route path="/aeps" component={AadhaarpaySearch} />
            <Route path="/aadhaar_services" component={AadhaarpaySearch} />
            <Route path="/trains" component={TrainSearchComponent} />
            <Route component={FlightSearch} />
          </Switch>
        </div>
      ) : (
        <Login />
      )}

      {/* <Partners /> */}
      {/* <WhyWithUs /> */}

      {/* <OffersSection offers={offers} /> */}
      {/* <TopFlightRoutes routes={routesItem} /> */}
    </div>
  );
};

export default HomePage;

const HeaderServiceIcon = (props) => (
  <img
    className={cx("header-service-icon", props.lg ? "icon-lg" : "")}
    src={props.src}
  />
);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0,
    flexGrow: 1,
    display: "flex",

    "& .temp-container": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        width: "100%",
        maxWidth: 1280,
      },
    },
    "& .search__section": {
      width: "100%",
      flexGrow: 1,
      minHeight: "230px",
      position: "relative",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      paddingTop: 30,
      opacity: 1,
      // backgroundColor: theme.palette.background.lightYellowGreen,
      // backgroundImage: `url(${SearchEngineBg})`,
      // background: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)), url(${SearchEngineBg})`,

      // height: "500px",
      backgroundSize: "cover",
      minHeight: 359,
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage: `url(${SearchEngineBg})`,
        backgroundPosition: "center",
        backgroundSize: "contain",
        opacity: 0.3, // Adjust opacity for the background image
        zIndex: -100,
      },
      // backgroundHeight: "100% 100px",
      // [theme.breakpoints.down(980)]: {
      //     display: 'block',
      // },
      [theme.breakpoints.down(1450)]: {
        minHeight: 371,
        backgroundSize: "cover",
      },

      "& .section-content": {
        width: 1200,
        padding: "16px 0",
        "& h3": {
          textAlign: "center",
          //color: "#fff",
          color: "#665757",

          fontWeight: 400,
          // marginTop: "2.3rem",
          marginBottom: 0,
          fontSize: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // color: theme.palette.primary.darkText,
          "& span": {
            display: "inline-block",
            margin: "0 5px 5px",
            "& .MuiSvgIcon-root": {
              fontSize: "30px",
            },
          },
        },
      },
    },
    "& .search__box": {
      // width: '1200px',
      minHeight: "130px",
      padding: "0px 0",
      [theme.breakpoints.down(980)]: {
        width: "100%",
      },
      [theme.breakpoints.down(1026)]: {
        width: "100%",
      },
    },

    "& .login__Div": {
      float: "right",
      position: "relative",
    },
    "& .loader-view": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      flexDirection: "column",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 103,
      background: "rgba(0,0,0,0.5)",
      "& img": {
        height: "7rem",
      },
      "& .loader-text": {
        color: theme.palette.primary.main,
        fontWeight: 600,
        textAlign: "center",
        marginLeft: 10,
      },
    },
    "& .header-service-icon": {
      height: 40,
      marginRight: 10,
      "&.lg": {
        height: 40,
      },
    },
  },
}));
