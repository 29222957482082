import React from "react";
import flightIcon from "assets/icons/flight_checkin_icon.svg";
import passengerIcon from "assets/icons/passenger_icon.svg";
import reviewDetailsIcon from "assets/icons/review_details_icon.svg";
import paymentDetailsIcon from "assets/icons/payment_details_icon.svg";
import tickIcon from "assets/icons/tick_icon.svg";
import ReviewDetails from "./checkinReview/ReviewDetails";
import PaymentDetails from "./checkinReview/PaymentDetails";
import Collapse from "@material-ui/core/Collapse";
import WebApi from "api/ApiConstants";
import PaxInfoAndAdditional from "./checkinReview/PaxInfoAndAdditional";
import Coupons from "pages/components/Coupons";
import PaymentSuccess from "./checkinReview/PaymentSuccess";
import FlightFareBreakup from "./FlightFareBreakup";
import { localforageGetItem } from "oautils/oaForageUtils";
import { Grid, Hidden, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import OaDiscountCouponSearch from "oahoc/OaDiscountCouponSearch";

const FlightBookingProcess = (props) => {
  const classes = useStyles();

  const [flightDetailsIcon, setFlightDetailsIcon] = React.useState(flightIcon);
  const [passengerDetailsBg, setPassengerDetailsBg] = React.useState("#e8e8e8");
  const [passengerDetailsIcon, setPassengerDetailsIcon] = React.useState(
    passengerIcon
  );
  const [reviewDetailsBg, setReviewDetailsBg] = React.useState("#e8e8e8");
  const [resultDetailsBg, setResultDetailsBg] = React.useState("#e8e8e8");

  const [reviewDtlsIcon, setReviewDtlsIcon] = React.useState(reviewDetailsIcon);
  const [paymentDetailsBg, setPaymentDetailsBg] = React.useState("#e8e8e8");
  const [paymentDtlsIcon, setPaymentDtlsIcon] = React.useState(
    paymentDetailsIcon
  );
  const [isPassengerDetails, setIsPassengerDetails] = React.useState(false);
  const [isReviewDetails, setIsReviewDetails] = React.useState(false);
  const [isPaymentDetails, setIsPaymentDetails] = React.useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = React.useState(false);
  const [paxDetailsDone, setPaxDetailsDone] = React.useState(false);
  const [reviewDetailsDone, setReviewDetailsDone] = React.useState(false);
  const [paymentDetailsDone, setPaymentDetailsDone] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(true);
  const [onwardFare, setOnwardFare] = React.useState(null);
  const [returnFare, setReturnFare] = React.useState(null);
  const [grandTotal, setGrandTotal] = React.useState(0);
  const [userDetails, setUserDetails] = React.useState(null);
  const [openPromo, setOpenPromo] = React.useState(true);
  const [getCoupons, setGetCoupons] = React.useState(null);
  const [couponObj, setCouponObj] = React.useState(
    props?.history?.location?.state?.couponObj
  );
  const [addDisCoup, setAddDisCoup] = React.useState(null);
  const [totalPayable, setTotalPayable] = React.useState(
    props?.history?.location?.state?.totalPayable
  );

  const [couponStatus, setCouponStatus] = React.useState({
    error: false,
    success: false,
    message: "",
  });

  const [convenienceFees, setConvenienceFees] = React.useState({
    conveyanceFeesActual: 0,
    conveyanceFeesActualReturn: 0,
    conveyanceFeesPercentage: 0,
    conveyanceFeesPercentageReturn: 0,
    insertUpdateFlag: false,
    partnerId: props.history.location.state.requestObj?.partnerId,
    serviceType: props.history.location.state.requestObj?.isDomestic ? 1 : 5,
  });

  const [calcFlConv, setCalcFlightConv] = React.useState({
    flag: "nocalc",
    onwConvenienceFees: 0,
    retConvenienceFees: 0,
  });

  const path = props.history.location.pathname;
  const propState = props.history.location.state;
  const requestObj = propState?.requestObj;

  const responseDepartRequest = props?.history?.location?.state?.onwardResp;
  const responseReturnRequest = props?.history?.location?.state?.returnResp;

  const adultPaxCount = requestObj?.adultPax;
  const childPaxCount = requestObj?.childPax;
  const infantPaxCount = requestObj?.infantPax;
  const theme = useTheme();

  const LoaderView = () => {
    return (
      <div className="loader-view">
        {/* <OaLoading loadertext="please wait ..." /> */}
        <div className="loader-wrapper">
          <img src={props.comloader} />
          <span className="loader-text">Please Wait</span>
          <span className="loader-text">Processing Your Request</span>
        </div>
        <div></div>
      </div>
    );
  };

  const getFlightFare = (fareData, addn) => {
    let tMarkup = 0;
    let tds = 0;

    if (fareData?.tmarkup != null) {
      tMarkup = parseFloat(fareData?.tmarkup);
    }

    if (userDetails?.partnerId == 1002) {
      tds = parseFloat(fareData?.tds);
    }

    {
      /* adult fare start */
    }
    let k3Adult = 0;
    let otherTaxesAdult = 0;
    let mfAdult = 0;
    let mftAdult = 0;
    let yrAdult = 0;
    let psfAdult = 0;
    let udfAdult = 0;
    let ocAdult = 0;

    if (
      fareData &&
      fareData.adults &&
      fareData.adults.tax_Breakup &&
      fareData.adults.tax_Breakup.length > 0
    ) {
      fareData.adults.tax_Breakup.map((value) => {
        if (value.chargeType == "K3") {
          k3Adult = value.chargeValue;
        }
        if (value.chargeType == "OtherTaxes") {
          otherTaxesAdult = value.chargeValue;
        }
        if (value.chargeType == "MF") {
          mfAdult = value.chargeValue;
        }
        if (value.chargeType == "MFT") {
          mftAdult = value.chargeValue;
        }
        if (value.chargeType == "YR") {
          yrAdult = value.chargeValue;
        }
        if (value.chargeType == "PSF") {
          psfAdult = value.chargeValue;
        }
        if (value.chargeType == "UDF") {
          udfAdult = value.chargeValue;
        }
        if (value.chargeType == "OTHERCHARGE") {
          ocAdult = value.chargeValue;
        }
      });
    }

    const airlineGstAdult = parseFloat(
      fareData?.adults?.airportTax * adultPaxCount
    );
    const airportTaxAdult = parseFloat(
      fareData?.adults?.airportTax * adultPaxCount
    );
    const baseFareAdult = parseFloat(
      fareData?.adults?.baseFare * adultPaxCount
    );
    const convFeesAdult =
      parseFloat(fareData?.adults?.conveyanceFees) * adultPaxCount;
    {
      /* adult fare end */
    }

    {
      /* child fare start */
    }

    let airlineGstChild = 0;
    let otherTaxesChildTotal = 0;
    let airportTaxChild = 0;
    let baseFareChild = 0;
    let yqTaxChild = 0;
    let discountChild = 0;
    let commissionChild = 0;
    let convFeesChild = 0;

    let totalFareChild = 0;

    if (
      fareData &&
      fareData.child &&
      fareData.child.tax_Breakup &&
      fareData.child.tax_Breakup.length > 0
    ) {
      let k3Child = 0;
      let otherTaxesChild = 0;
      let mfChild = 0;
      let mftChild = 0;
      let yrChild = 0;
      let psfChild = 0;
      let udfChild = 0;
      let ocChild = 0;

      fareData.child.tax_Breakup.map((value) => {
        if (value.chargeType == "K3") {
          k3Child = value.chargeValue;
        }
        if (value.chargeType == "OtherTaxes") {
          otherTaxesChild = value.chargeValue;
        }
        if (value.chargeType == "MF") {
          mfChild = value.chargeValue;
        }
        if (value.chargeType == "MFT") {
          mftChild = value.chargeValue;
        }
        if (value.chargeType == "YR") {
          yrChild = value.chargeValue;
        }
        if (value.chargeType == "PSF") {
          psfChild = value.chargeValue;
        }
        if (value.chargeType == "UDF") {
          udfChild = value.chargeValue;
        }
        if (value.chargeType == "OTHERCHARGE") {
          ocChild = value.chargeValue;
        }
      });

      airlineGstChild = parseFloat(k3Child * childPaxCount);
      otherTaxesChildTotal = parseFloat(otherTaxesChild * childPaxCount);
      airportTaxChild = parseFloat(fareData?.child?.airportTax * childPaxCount);
      baseFareChild = parseFloat(fareData?.child?.baseFare * childPaxCount);
      yqTaxChild = parseFloat(fareData?.child?.yqTax * childPaxCount);
      discountChild = parseFloat(fareData?.child?.discount * childPaxCount);
      commissionChild = parseFloat(fareData?.child?.commission * childPaxCount);
      convFeesChild =
        parseFloat(fareData?.child?.conveyanceFees) * childPaxCount;

      totalFareChild =
        baseFareChild +
        airportTaxChild +
        yqTaxChild +
        commissionChild +
        convFeesChild -
        discountChild;
    }

    {
      /* child fare end */
    }

    {
      /* infant fare start */
    }

    let airlineGstInfant = 0;
    let otherTaxesInfantTotal = 0;
    let airportTaxInfant = 0;
    let baseFareInfant = 0;
    let yqTaxInfant = 0;
    let discountInfant = 0;
    let commissionInfant = 0;
    let convFeesInfant = 0;

    let totalFareInfant = 0;

    if (
      fareData &&
      fareData.infant &&
      fareData.infant.tax_Breakup &&
      fareData.infant.tax_Breakup.length > 0
    ) {
      let k3Infant = 0;
      let otherTaxesInfant = 0;
      let mfInfant = 0;
      let mftInfant = 0;
      let yrInfant = 0;
      let psfInfant = 0;
      let udfInfant = 0;
      let ocInfant = 0;

      fareData.infant.tax_Breakup.map((value) => {
        if (value.chargeType == "K3") {
          k3Infant = value.chargeValue;
        }
        if (value.chargeType == "OtherTaxes") {
          otherTaxesInfant = value.chargeValue;
        }
        if (value.chargeType == "MF") {
          mfInfant = value.chargeValue;
        }
        if (value.chargeType == "MFT") {
          mftInfant = value.chargeValue;
        }
        if (value.chargeType == "YR") {
          yrInfant = value.chargeValue;
        }
        if (value.chargeType == "PSF") {
          psfInfant = value.chargeValue;
        }
        if (value.chargeType == "UDF") {
          udfInfant = value.chargeValue;
        }
        if (value.chargeType == "OTHERCHARGE") {
          ocInfant = value.chargeValue;
        }
      });

      airlineGstInfant = parseFloat(k3Infant * infantPaxCount);
      otherTaxesInfantTotal = parseFloat(otherTaxesInfant * infantPaxCount);
      airportTaxInfant = parseFloat(
        fareData?.infant?.airportTax * infantPaxCount
      );
      baseFareInfant = parseFloat(fareData?.infant?.baseFare * infantPaxCount);
      yqTaxInfant = parseFloat(fareData?.infant.yqTax * infantPaxCount);
      discountInfant = parseFloat(fareData?.tsdiscount * infantPaxCount);
      commissionInfant = parseFloat(fareData?.agentCommission * infantPaxCount);
      convFeesInfant =
        parseFloat(fareData?.infant.conveyanceFees) * infantPaxCount;

      totalFareInfant =
        baseFareInfant +
        airportTaxInfant +
        yqTaxInfant +
        commissionInfant +
        convFeesInfant -
        discountInfant;
    }

    {
      /* infant fare end */
    }

    const additionalTotal = addn?.seat + addn?.meal + addn?.baggage;
    const fareTotal =
      parseFloat(fareData?.baseFare) +
      parseFloat(fareData?.tax) +
      parseFloat(fareData?.agentCommission) +
      parseFloat(fareData?.tcharge) +
      parseFloat(fareData?.scharge) +
      parseFloat(fareData?.tmarkup) +
      parseFloat(additionalTotal) -
      parseFloat(fareData?.tdiscount + fareData?.tsdiscount);

    const flightFareObj = {
      baseFare: fareData?.baseFare.toFixed(2),
      baseFareAdult: baseFareAdult.toFixed(2),
      baseFareChild: baseFareChild.toFixed(2),
      baseFareInfant: baseFareInfant.toFixed(2),
      airlineTaxAndFees: {
        total: fareData?.tax.toFixed(2),
        airlineGst: (
          airlineGstAdult +
          airlineGstChild +
          airlineGstInfant
        ).toFixed(2),
        airportTax: (
          airportTaxAdult +
          airportTaxChild +
          airportTaxInfant
        ).toFixed(2),
        conveyanceFees: (
          convFeesAdult +
          convFeesChild +
          convFeesInfant
        ).toFixed(2),
        otherTaxes: fareData?.agentCommission.toFixed(2),
      },
      additional: {
        seat: parseFloat(addn?.seat).toFixed(2),
        meal: parseFloat(addn?.meal).toFixed(2),
        baggage: parseFloat(addn?.baggage).toFixed(2),
        total: parseFloat(additionalTotal).toFixed(2),
      },
      totalFare: fareTotal.toFixed(2),
      netFare: (
        fareTotal +
        parseFloat(tds) -
        parseFloat(fareData?.agentCommission) -
        parseFloat(tMarkup)
      ).toFixed(2),
      totalCommission: fareData?.agentCommission.toFixed(2),
      tMarkup: tMarkup.toFixed(2),
      tds: tds.toFixed(2),
    };

    return flightFareObj;
  };

  const initVars = (path) => {
    setResultDetailsBg(theme.palette.primary.active);
    switch (path) {
      case "/flight/booking": {
        setPassengerDetailsBg(theme.palette.primary.faddedGray);
        setReviewDetailsBg(
          paxDetailsDone
            ? theme.palette.primary.active
            : theme.palette.background.lightGrayFaded
        );
        setPaymentDetailsBg(
          reviewDetailsDone
            ? theme.palette.primary.active
            : theme.palette.background.lightGrayFaded
        );
        setFlightDetailsIcon(tickIcon);
        setIsPassengerDetails(true);
        setIsReviewDetails(false);
        setIsPaymentDetails(false);
        break;
      }

      case "/flight/booking/passenger-info": {
        setPassengerDetailsBg(theme.palette.primary.active);
        setReviewDetailsBg(
          paxDetailsDone
            ? theme.palette.primary.active
            : theme.palette.primary.faddedGray
        );
        setPaymentDetailsBg(
          reviewDetailsDone
            ? theme.palette.primary.active
            : theme.palette.primary.faddedGray
        );
        setIsPassengerDetails(true);
        setIsReviewDetails(false);
        setIsPaymentDetails(false);
        break;
      }

      case "/flight/booking/review-details": {
        setPassengerDetailsBg(theme.palette.primary.active);
        setReviewDetailsBg(theme.palette.primary.faddedGray);
        setPaymentDetailsBg(
          reviewDetailsDone
            ? theme.palette.primary.active
            : theme.palette.background.lightGrayFaded
        );
        setPassengerDetailsIcon(tickIcon);
        setPaxDetailsDone(true);
        setIsReviewDetails(true);
        setIsPaymentDetails(false);
        break;
      }

      case "/flight/booking/payment-details": {
        setPassengerDetailsBg(theme.palette.primary.active);
        setReviewDetailsBg(theme.palette.primary.active);
        setPaymentDetailsBg(theme.palette.primary.faddedGray);
        setPassengerDetailsIcon(tickIcon);
        setReviewDtlsIcon(tickIcon);
        setReviewDetailsDone(true);
        setIsReviewDetails(false);
        setIsPaymentDetails(true);
        break;
      }

      case "/flight/booking/payment-success": {
        setPassengerDetailsBg(theme.palette.primary.active);
        setReviewDetailsBg(theme.palette.primary.active);
        setPaymentDetailsBg(theme.palette.primary.active);
        setPassengerDetailsIcon(tickIcon);
        setReviewDtlsIcon(tickIcon);
        setPaymentDtlsIcon(tickIcon);
        setPaymentDetailsDone(true);
        setIsPaymentSuccess(true);
        break;
      }
    }
  };

  const triggerInit = (flag, url) => {
    //console.log("received trigger flag ************ ", flag);
    //console.log("received trigger URL ************ ", url);
    if (flag) {
      initVars(url);
    }
  };

  const backToSearchResults = () => {
    // props.history.goBack();
    // console.log("request obj", propState.requestObj);

    setIsLoading(true);
    WebApi.getAvailPriceResponse(
      { sessionId: propState.availSessionId },
      (response) => {
        setIsLoading(false);
        if (response.isSuccess) {
          if (
            propState.requestObj.isDomestic &&
            propState.requestObj.mode != "MULTICITY"
          ) {
            props.history.push({
              pathname: "/flight/search-result",
              state: { ...response, requestObj: propState.requestObj },
            });
          } else {
            props.history.push({
              pathname: "/flight/international/search-result",
              state: { ...response, requestObj: propState.requestObj },
            });
          }
        } else {
          props.history.push({
            pathname: "/flight/search-progress",
            state: propState.requestObj,
          });
        }
      },
      (error) => console.log("error", error)
    );
  };

  const goToProcess = (url) => {
    props.history.push({
      pathname: url,
      state: {
        ...props.history.location.state,
        couponObj: props?.history?.location?.state?.couponObj,
        totalPayable: props?.history?.location?.state?.totalPayable,
      },
    });
  };

  const applyCoupon = (val) => {
    if (val) {
      setIsLoading(true);
      WebApi.validateDiscountVoucherUsage(
        { voucherCode: val.voucher_code },
        (response) => {
          if (response.success) {
            if (response.data.usageLimitExceeded) {
              console.error(
                `Discount coupon ${val.voucher_code} usage limit exceeded! Please try another.`
              );
              setCouponStatus({
                ...couponStatus,
                error: true,
                message: `Discount coupon ${val.voucher_code} usage limit exceeded! Please try another.`,
              });
            } else {
              setCouponObj(val);
              setAddDisCoup(true);
              // console.log("discount coupon applied successfully");
              setCouponStatus({
                ...couponStatus,
                success: true,
                message: "discount coupon applied successfully",
              });
            }
          }
          setIsLoading(false);
        },
        (error) => {
          console.error("error validating discount coupon usage", error);
          setIsLoading(false);
        }
      );
    }
  };

  const updateAdditionalFare = (paxDetails, tripType) => {
    if (props.history.location.state.requestObj.mode == "ROUND") {
      if (paxDetails) {
        responseDepartRequest.fares[0] &&
          setOnwardFare(
            getFlightFare(
              responseDepartRequest.fares[0],
              paxDetails.additionalFare
            )
          );
        responseReturnRequest.fares[0] &&
          setReturnFare(
            getFlightFare(
              responseReturnRequest.fares[0],
              paxDetails.additionalFareRt
            )
          );
      }
    } else {
      paxDetails &&
        responseDepartRequest.fares[0] &&
        setOnwardFare(
          getFlightFare(
            responseDepartRequest.fares[0],
            paxDetails.additionalFare
          )
        );
    }
  };

  const fetchFlightConvenienceFees = () => {
    WebApi.getB2cFlightConvenienceFees(
      {
        partnerId: requestObj.partnerId,
        serviceType: requestObj.isDomestic ? 1 : 5,
      },
      (response) => {
        if (response.success) {
          setConvenienceFees(response.data);
        } else {
          console.error("failed getting b2cFlightConvenience fees", response);
        }
      },
      (error) => {
        console.error("getB2cFlightConvenienceFees error", error);
      }
    );
  };

  const calculateFlightConvenienceFees = () => {
    if (onwardFare) {
      setOnwardFare((prvState) => {
        return {
          ...prvState,
          totalFare: (
            parseFloat(prvState.totalFare) +
            parseFloat(calcFlConv?.onwConvenienceFees)
          ).toFixed(2),
          netFare: (
            parseFloat(prvState.netFare) +
            parseFloat(calcFlConv?.onwConvenienceFees)
          ).toFixed(2),
          airlineTaxAndFees: {
            ...prvState.airlineTaxAndFees,
            conveyanceFees: parseFloat(calcFlConv?.onwConvenienceFees),
          },
        };
      });
    }
    if (
      returnFare &&
      props.history.location.state.requestObj?.mode === "ROUND"
    ) {
      setReturnFare((prvState) => {
        return {
          ...prvState,
          totalFare: (
            parseFloat(prvState.totalFare) +
            parseFloat(calcFlConv?.retConvenienceFees)
          ).toFixed(2),
          netFare: (
            parseFloat(prvState.netFare) +
            parseFloat(calcFlConv?.retConvenienceFees)
          ).toFixed(2),
          airlineTaxAndFees: {
            ...prvState.airlineTaxAndFees,
            conveyanceFees: parseFloat(calcFlConv?.retConvenienceFees),
          },
        };
      });
    }
  };

  const revCalcFlightConvenienceFees = () => {
    if (onwardFare) {
      setOnwardFare((prvState) => {
        return {
          ...prvState,
          totalFare: (
            parseFloat(prvState.totalFare) +
            parseFloat(calcFlConv?.onwConvenienceFees)
          ).toFixed(2),
          netFare: (
            parseFloat(prvState.netFare) +
            parseFloat(calcFlConv?.onwConvenienceFees)
          ).toFixed(2),
          airlineTaxAndFees: {
            ...prvState.airlineTaxAndFees,
            conveyanceFees: parseFloat(calcFlConv?.onwConvenienceFees),
          },
        };
      });
    }
    if (
      returnFare &&
      props.history.location.state.requestObj?.mode === "ROUND"
    ) {
      setReturnFare((prvState) => {
        return {
          ...prvState,
          totalFare: (
            parseFloat(prvState.totalFare) +
            parseFloat(calcFlConv?.retConvenienceFees)
          ).toFixed(2),
          netFare: (
            parseFloat(prvState.netFare) +
            parseFloat(calcFlConv?.retConvenienceFees)
          ).toFixed(2),
          airlineTaxAndFees: {
            ...prvState.airlineTaxAndFees,
            conveyanceFees: parseFloat(calcFlConv?.retConvenienceFees),
          },
        };
      });
    }
  };

  const getDiscountCoupons = () => {
    WebApi.getCoupons(
      {
        allVoucher: false,
        loggedInUserId: props.history.location.state.requestObj.loggedInUserId,
        voucherCode: "",
        onwardFareType:
          responseDepartRequest.fares[0].fareTypeOriginal === ""
            ? "Normal"
            : responseDepartRequest.fares[0].fareTypeOriginal,
        returnFareType: requestObj?.isDomestic
          ? requestObj?.mode === "ROUND"
            ? responseReturnRequest.fares[0].fareTypeOriginal === ""
              ? "Normal"
              : responseReturnRequest.fares[0].fareTypeOriginal
            : ""
          : "",
        voucherUID: 0,
        service: "Flight",
      },
      (resp) => {
        if (resp && resp.success) {
          setGetCoupons(resp.data);
        }
      },
      (error) => {
        console.log("coupons", error);
      }
    );
  };

  React.useEffect(() => {
    // console.log("flight booking process props", props);
    fetchFlightConvenienceFees();
    initVars(path);

    localforageGetItem("user-details", function(err, details) {
      //console.log("User details", details);
      if (details) {
        setUserDetails(details);
      } else {
        console.error("userDetails error", err);
      }
    });

    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    if (userDetails && userDetails.partnerId) {
      // console.log("props flight booking process", props)
      if (responseDepartRequest?.fares[0]) {
        const flightFareOne = getFlightFare(
          responseDepartRequest?.fares[0],
          props?.history?.location?.state?.paxInfo?.additionalFare
        );
        setOnwardFare(flightFareOne);
        const onwardTotalFare = parseFloat(flightFareOne?.totalFare).toFixed(2);
        setGrandTotal(onwardTotalFare);
        setTotalPayable(onwardTotalFare);

        if (requestObj?.mode == "ROUND" || requestObj?.mode == "ROUND-SP") {
          const flightFareRet = getFlightFare(
            responseReturnRequest.fares[0],
            props?.history?.location?.state?.paxInfo?.additionalFareRt
          );
          setReturnFare(flightFareRet);
          const returnTotalFare = requestObj?.isDomestic
            ? (
                parseFloat(flightFareOne?.totalFare) +
                parseFloat(flightFareRet?.totalFare)
              ).toFixed(2)
            : 0.0;
          if (requestObj?.isDomestic) {
            setGrandTotal(returnTotalFare);
            setTotalPayable(returnTotalFare);
          }
        }
      }

      // console.log("onward fare ", responseDepartRequest.fares[0]);
      // if (requestObj?.mode === "ROUND" && requestObj?.isDomestic) {
      //   console.log("fare", responseReturnRequest.fares[0]);
      // }
      getDiscountCoupons();
    }
  }, [userDetails]);

  React.useEffect(() => {
    // grandTotal &&
    //   couponObj &&
    //   setTotalPayable(
    //     addDisCoup == true
    //       ? (
    //           parseFloat(grandTotal) - parseFloat(couponObj.voucher_value)
    //         ).toFixed(2)
    //       : grandTotal
    //   );
    if (couponObj && onwardFare && returnFare) {
      let gTotal =
        (requestObj?.mode == "ROUND" || requestObj?.mode == "ROUND-SP") &&
        requestObj?.isDomestic
          ? parseFloat(returnFare?.totalFare) +
            parseFloat(onwardFare?.totalFare)
          : parseFloat(onwardFare?.totalFare);
      let vValue = couponObj?.voucher_value;
      setGrandTotal(parseFloat(gTotal).toFixed(2));
      setTotalPayable(parseFloat(gTotal - (vValue || 0)).toFixed(2));
    } else {
      let gTotal =
        requestObj?.mode == "ROUND" || requestObj?.mode == "ROUND-SP"
          ? returnFare?.totalFare + onwardFare?.totalFare
          : onwardFare?.totalFare;
      let vValue = couponObj?.voucher_value;
      setGrandTotal(parseFloat(gTotal).toFixed(2));
      setTotalPayable(parseFloat(gTotal - (vValue || 0)).toFixed(2));
      //console.log("couponObj", couponObj);
      //console.log("onwardFare", onwardFare);
      //console.log("returnFare", returnFare);
    }
  }, [couponObj, addDisCoup, onwardFare, returnFare]);

  React.useEffect(() => {
    if (calcFlConv?.flag === "docalc") {
      calculateFlightConvenienceFees();
    }
    if (calcFlConv?.flag === "revcalc") {
      revCalcFlightConvenienceFees();
    }
  }, [calcFlConv]);

  return (
    <Grid container className={classes.rootContainer}>
      <Helmet>
        <script
          src="https://kit.fontawesome.com/eb67f5d49a.js"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <div className="inner-section-wrapper">
          <div className="section-left booking-details-section">
            <div className="checkout-stepper">
              <div
                className="progress-icon-wrapper"
                style={{ cursor: "pointer" }}
                onClick={() => backToSearchResults()}
              >
                <span
                  className="progress-icon"
                  style={{ background: resultDetailsBg }}
                >
                  <img src={reviewDtlsIcon} />
                </span>
                <span className="progress-label">
                  <Typography style={{ fontWeight: 600 }}>
                    Result Page
                  </Typography>
                </span>
              </div>
              <span className="progress-icon-separator"></span>

              <div
                className="progress-icon-wrapper"
                style={{ cursor: paxDetailsDone ? "pointer" : "default" }}
                onClick={
                  paxDetailsDone
                    ? () => {
                        goToProcess("/flight/booking");
                      }
                    : () => {}
                }
              >
                <span
                  className="progress-icon"
                  style={{ background: passengerDetailsBg }}
                >
                  <img src={passengerDetailsIcon} />
                </span>
                <span className="progress-label">
                  <Typography style={{ fontWeight: 600 }}>
                    Passenger Information
                  </Typography>
                </span>
              </div>
              <span className="progress-icon-separator"></span>
              <div
                className="progress-icon-wrapper"
                style={{ cursor: reviewDetailsDone ? "pointer" : "default" }}
                onClick={
                  reviewDetailsDone
                    ? () => {
                        goToProcess("/flight/booking/review-details");
                      }
                    : () => {}
                }
              >
                <span
                  className="progress-icon"
                  style={{ background: reviewDetailsBg }}
                >
                  <img src={reviewDtlsIcon} />
                </span>
                <span className="progress-label">
                  <Typography style={{ fontWeight: 600 }}>
                    Review Details
                  </Typography>
                </span>
              </div>
              <span className="progress-icon-separator"></span>
              <div className="progress-icon-wrapper">
                <span
                  className="progress-icon"
                  style={{ background: paymentDetailsBg }}
                >
                  <img src={paymentDtlsIcon} />
                </span>
                <span className="progress-label">
                  <Typography style={{ fontWeight: 600 }}>
                    Payment Details
                  </Typography>
                </span>
              </div>
            </div>
            {/* <div className="separator2">
                  &nbsp;
            </div> */}
            <div className="component-wrapper">
              <Switch>
                <Route
                  path="/flight/booking/review-details"
                  render={(prop) => (
                    <ReviewDetails
                      setInitFlag={(flag, url) => {
                        triggerInit(flag, url);
                      }}
                      setCalcFlightConv={setCalcFlightConv}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/flight/booking/payment-details"
                  render={(prop) =>
                    onwardFare && (
                      <PaymentDetails
                        setInitFlag={(flag, url) => {
                          triggerInit(flag, url);
                        }}
                        onwardfare={onwardFare}
                        returnfare={returnFare}
                        setCalcFlightConv={setCalcFlightConv}
                        {...props}
                      />
                    )
                  }
                />
                <Route
                  path="/flight/booking/payment-success"
                  render={(prop) => (
                    <PaymentSuccess
                      setInitFlag={(flag, url) => {
                        triggerInit(flag, url);
                      }}
                      {...props}
                      {...prop}
                    />
                  )}
                />
                <Route
                  render={(prop) => (
                    <PaxInfoAndAdditional
                      setInitFlag={(flag, url) => {
                        triggerInit(flag, url);
                      }}
                      setadditionalfare={(paxInfo, tripType) =>
                        updateAdditionalFare(paxInfo, tripType)
                      }
                      couponobj={couponObj}
                      totalpayable={totalPayable}
                      convenienceFees={convenienceFees}
                      setCalcFlightConv={setCalcFlightConv}
                      {...props}
                    />
                  )}
                />
              </Switch>
            </div>
          </div>
          <div className="section-right fare-breakup">
            <div className="fare-breakup-wrapper">
              <FlightFareBreakup
                onwardFare={onwardFare}
                returnFare={returnFare}
                adultPaxCount={adultPaxCount}
                childPaxCount={childPaxCount}
                infantPaxCount={infantPaxCount}
                flightMode={requestObj?.mode}
                isdomestic={requestObj?.isDomestic}
                calcFlConv={calcFlConv}
              ></FlightFareBreakup>

              <div className="fare-breakup-component">
                <Grid container className="breakup-header">
                  <Grid item md={6} xs={6}>
                    <Typography>Fare Summary</Typography>
                  </Grid>
                  {/* <Grid item md={6} xs={6}>
                <div className="anch-btn-holder">
                  <span
                    className="anch-btn"
                    onClick={() => backToSearchResults()}
                  >
                    Back to search result
                  </span>
                </div>
              </Grid> */}
                </Grid>
                <Grid container className="breakup-title">
                  <Grid item md={7} sx={7}>
                    <Typography>Grand Total</Typography>
                  </Grid>
                  <Grid item md={5} sx={5}>
                    <Typography style={{ textAlign: "right" }}>
                      <Rs />{" "}
                      {(
                        grandTotal -
                        parseFloat(
                          onwardFare?.airlineTaxAndFees?.conveyanceFees
                        )
                      ).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                {parseFloat(onwardFare?.airlineTaxAndFees?.conveyanceFees) >
                  0 && (
                  <>
                    <Grid
                      container
                      className="fare-breakup-collapse"
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Grid item md={7} sx={7}>
                        <Typography>Convenience Fee</Typography>
                      </Grid>
                      <Grid item md={5} sx={5}>
                        <Typography style={{ textAlign: "right" }}>
                          <Rs /> {onwardFare?.airlineTaxAndFees?.conveyanceFees}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}

                {couponObj && couponObj.voucher_value > 0 && (
                  <Grid container className="breakup-title">
                    <Grid item md={7} sx={7}>
                      <Typography style={{ fontSize: 13, marginRight: 10 }}>
                        Discount{" "}
                        <b style={{ marginLeft: 10, fontSize: 12 }}>
                          {couponObj?.voucher_code}
                        </b>
                        {isPassengerDetails == true &&
                          isReviewDetails == false &&
                          isPaymentDetails == false && (
                            <a
                              className="coupon-remove"
                              onClick={() => {
                                setCouponObj({
                                  voucher_code: "",
                                  voucher_value: 0,
                                  description: "",
                                });
                                setAddDisCoup(false);
                              }}
                              title="remove discount"
                            >
                              <i className="fa fa-times"></i>
                            </a>
                          )}
                      </Typography>
                    </Grid>
                    <Grid item md={5} sx={5}>
                      <Typography style={{ textAlign: "right" }}>
                        <Rs /> {parseFloat(couponObj?.voucher_value).toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {/* <Grid container className="separator-wrapper">
                  <Grid item md={7} sx={7} className="separator1"></Grid>
                  <Grid item md={5} sx={5} className="separator1"></Grid>
                </Grid> */}
                <Grid container className="breakup-title total-payable">
                  <Grid item md={7} sx={7}>
                    <Typography>Total Payable</Typography>
                  </Grid>
                  <Grid item md={5} sx={5}>
                    <Typography style={{ textAlign: "right" }}>
                      <Rs /> {totalPayable}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              {/* ============= coupon code start  ========== */}

              {// console.log(getCoupons?.length)
              getCoupons && getCoupons.length > 0 && (
                <>
                  {isPassengerDetails == true &&
                    isReviewDetails == false &&
                    isPaymentDetails == false && (
                      <div style={{ marginTop: "0.5rem" }}>
                        <Grid
                          container
                          onClick={() => setOpenPromo(!openPromo)}
                          className="breakup-title-collapse"
                        >
                          <Grid item md={7} xs={7}>
                            <div className={classes.promoCollapseToggle}>
                              <Typography
                                style={{ fontSize: 15, marginRight: 10 }}
                              >
                                PROMO CODE
                              </Typography>
                              <span>
                                <i
                                  className={
                                    openPromo
                                      ? "fas fa-chevron-up"
                                      : "fas fa-chevron-down"
                                  }
                                  style={{ marginTop: 6, marginLeft: 10 }}
                                ></i>
                              </span>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <OaDiscountCouponSearch
                            applyDiscountCoupon={applyCoupon}
                            couponStatus={couponStatus}
                            setCouponStatus={setCouponStatus}
                            service="Flight"
                          />
                          <Collapse in={openPromo}>
                            {getCoupons?.map(
                              (item, index) =>
                                item.couponDisplay === "auto" && (
                                  <Coupons
                                    item={item}
                                    key={index}
                                    applycoupon={(val) => applyCoupon(val)}
                                    selectedcoupon={couponObj}
                                    {...props}
                                  />
                                )
                            )}
                          </Collapse>
                        </Grid>
                      </div>
                    )}
                </>
              )}
              {/* ============= coupon code end here ========== */}
            </div>
          </div>
        </div>
      </Grid>
      {isLoading == true && <LoaderView />}
    </Grid>
  );
};

export default FlightBookingProcess;

const Rs = () => {
  return <span style={{ fontFamily: "Roboto" }}>₹</span>;
};

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    marginTop: 65,
    width: 1236,
    padding: "15px 2%",
    position: "relative",
    background: theme.palette.background.lightGrayDefault,
    color: theme.palette.background.lightGrayDefaultContrast,
    [theme.breakpoints.down(1440)]: {
      width: "100%",
    },
    [theme.breakpoints.down(1390)]: {
      padding: "15px 20px",
    },
    [theme.breakpoints.down(450)]: {
      padding: 0,
    },
    "& .loader-view": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      flexDirection: "column",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 103,
      background: "rgba(0,0,0,0.5)",
      "& .loader-wrapper": {
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 10,
        borderRadius: 20,
      },
      "& img": {
        height: 160,
        width: 200,
      },
      "& .loader-text": {
        color: theme.palette.text.default,
        textAlign: "center",
        fontWeight: 500,
      },
    },
    "& .inner-section-wrapper": {
      display: "flex",
      padding: 5,
      maxWidth: 1200,
      width: "100%",
      gap: 20,
      [theme.breakpoints.down(768)]: {
        flexWrap: "wrap !important",
      },
    },
    "& .section-left": {
      width: "75%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    "& .section-right": {
      flexGrow: 1,
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    "& .section-container": {
      background: theme.palette.background.default,
      borderRadius: "0.25rem",
      border: "1px solid rgba(0,0,0,0.125)",
      boxShadow: "0px 0px 3px 0px rgba(170,170,170,1)!important",
      marginBottom: 25,
      width: "100%",
      [theme.breakpoints.down(768)]: {
        padding: "0 10px 0 0",
      },
      "&.no-border": {
        boxShadow: "none !important",
        border: "none !important",
        marginTop: 25,
        paddingTop: 5,
        "& .section-title": {
          borderBottom: "none !important",
          marginBottom: 0,
          padding: "0 15px",
        },
        "&.gst-input-section-wrapper": {
          borderRadius: "0.25rem",
          background: theme.palette.background.lightGrayDefault,
          "& .pax-info-title": {
            padding: "5px 0 0 10px",
          },
          "& .info-text": {
            color: theme.palette.background.defaultContrast,
            fontWeight: 500,
            fontSize: 12,
            padding: 10,
          },
          "& .inner-label": {
            color: theme.palette.background.defaultContrast,
            fontWeight: 500,
          },
          "& .MuiFormControl-root, .MuiInputBase-root": {
            background: theme.palette.background.default,
            color: theme.palette.background.defaultContrast,
            borderRadius: "0.25rem",
          },
          "& .MuiInputBase-input": {
            color: theme.palette.background.defaultContrast,
          },
          "& .no-bg-div .MuiFormControl-root": {
            background: "unset !important",
          },
        },
      },
    },
    "& .section-content": {
      padding: "5px 10px 5px 10px",
    },
    "& .section-title": {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: `solid 0.5px #ccc`,
      padding: "5px 15px",
    },
    "& .checkout-stepper": {
      height: 90,
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      flexDirection: "row",
      marginTop: 0,
      padding: "30px 0 0 0",
      background: theme.palette.background.default,
      borderRadius: "0.25rem",
      border: "1px solid rgba(0,0,0,0.125)",
      boxShadow: "0px 0px 3px 0px rgba(170,170,170,1)!important",
      marginBottom: 5,
      [theme.breakpoints.down(586)]: {
        justifyContent: "center",
        padding: "15px 0 0 0",
        height: 60,
      },
    },
    "& .MuiAccordion-root": {
      margin: "1rem auto",
      background: "#fff",
    },
    "& .MuiAccordionSummary-content p": {
      fontWeight: "bold",
    },
    "& .accordion__section": {
      width: "100%",
      padding: "5px 15px",
      [theme.breakpoints.down(500)]: {
        padding: "5px 5px 5px 15px",
      },
      [theme.breakpoints.down(360)]: {
        padding: "5px 5px 5px 15px",
      },
    },
    " & .progress-icon-wrapper": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "12vw",
      [theme.breakpoints.down(450)]: {
        width: "100px",
      },
    },
    "& .progress-icon": {
      width: 40,
      height: 40,
      padding: 7,
      borderRadius: "50%",
      background: theme.palette.secondary.main,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "10px",
      "& img": {
        width: "100%",
        height: "100%",
      },
    },
    "& .progress-icon-separator": {
      background: theme.palette.primary.darkText,
      height: 1.5,
      width: "7vw",
      marginTop: -60,
      [theme.breakpoints.down(586)]: {
        display: "none",
      },
    },
    "& .progress-label": {
      height: 50,
      "& p": {
        weight: 500,
        fontSize: 12,
        lineHeight: "24px",
        textAlign: "center",
      },
      [theme.breakpoints.down(512)]: {
        display: "none",
      },
    },
    "& .component-wrapper": {
      minHeight: "90vh",
      [theme.breakpoints.down(768)]: {
        minHeight: "62vh",
      },
      // overflowY: "auto",
      // paddingRight: "10px",
      // [theme.breakpoints.down(786)]: {
      //     height: "56vh",
      // },
    },
    "& .booking-details-section": {
      [theme.breakpoints.down(450)]: {
        position: "relative",
        width: "100%",
      },
      // borderRight: `solid 0.4px ${theme.palette.primary.shadedLightText}`,
      // padding: "10px 10px 0 0 !important",
      [theme.breakpoints.down(960)]: {
        borderRight: 0,
      },
      [theme.breakpoints.down(450)]: {
        paddingLeft: "20px !important",
        paddingRight: "10px !important",
      },
    },
    "& .fare-breakup": {
      // padding: "10px 0 0 0 !important",
      display: "flex",
      [theme.breakpoints.down(450)]: {
        justifyContent: "center",
        marginTop: 20,
        paddingLeft: "20px !important",
        marginBottom: 60,
        paddingRight: 12,
      },
    },
    "& .fare-breakup-wrapper": {
      width: 300,
      [theme.breakpoints.down(450)]: {
        width: 375,
      },
    },
    "& .fare-breakup-component": {
      background: theme.palette.background.default,
      borderRadius: "0.25rem",
      border: "1px solid rgba(0,0,0,0.125)",
      boxShadow: "0px 0px 3px 0px rgba(170,170,170,1)!important",
      marginBottom: 25,
    },
    "& .breakup-header": {
      background: theme.palette.background.default,
      borderBottom: `solid 0.5px ${theme.palette.background.lightGrayDefault}`,
      padding: "5px 10px",
      "& p": {
        fontWeight: 500,
        fontSize: "1rem",
        color: theme.palette.background.defaultContrast,
      },
    },
    "& .section-type": {
      marginBottom: 6,
      padding: "5px 10px",
      borderBottom: `solid 0.5px ${theme.palette.background.lightGrayDefault}`,
      "& p": {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "28px",
        textTransform: "uppercase",
        color: theme.palette.background.defaultContrast,
      },
    },
    "& .breakup-title": {
      justifyContent: "space-between",
      borderBottom: `solid 0.5px ${theme.palette.background.disabled}`,
      padding: "5px 10px",
      "& p": {
        fontWeight: 500,
        fontSize: "1rem",
        lineHeight: "28px",
      },
      "&.total-payable": {
        background: theme.palette.background.disabled,
        borderBottom: `none`,
        "& p": {
          color: theme.palette.background.disabledContrast,
        },
      },
      "& .coupon-remove": {
        cursor: "pointer",
        color: theme.palette.primary.danger,
        marginLeft: 10,
      },
    },
    "& .breakup-title-collapse": {
      cursor: "pointer",
      borderBottom: `solid 0.5px ${theme.palette.background.disabled}`,
      padding: "5px 10px",
      "& p": {
        fontWeight: 500,
        fontSize: 17,
        lineHeight: "28px",
        marginRight: 10,
        fontSize: 13,
      },
      "&.total": {
        background: theme.palette.background.disabled,
        borderBottom: `none`,
        "& p": {
          fontWeight: 500,
          fontSize: "1rem",
          color: theme.palette.background.defaultContrast,
        },
      },
    },
    "& .fare-breakup-collapse": {
      "& p": {
        fontWeight: 500,
        fontSize: 12,
        lineHeight: "24px",
      },
      "& .MuiCollapse-wrapper": {
        padding: "5px 10px 0 10px",
      },
    },
    "& .separator1": {
      borderBottom: `solid 1px ${theme.palette.primary.darkFadedText}`,
      marginTop: 10,
    },
    "& .separator2": {
      borderBottom: `solid 1px ${theme.palette.primary.darkFadedText}`,
      marginBottom: 10,
      marginTop: 0,
    },
    "& .separator-wrapper": {
      marginTop: 5,
      marginBottom: 15,
    },
    "& .anch-btn-holder": {
      [theme.breakpoints.down(450)]: {
        position: "absolute",
        top: 140,
        right: 30,
      },

      textAlign: "right",
      paddingTop: 5,
      "& span": {
        padding: "3px 5px",
        borderRadius: 3,
        border: `solid 1px ${theme.palette.quarternary.main}`,
        backgroundColor: theme.palette.quarternary.main,
        color: `${theme.palette.quarternary.contrastText} !important`,
        "&:hover": {
          color: `${theme.palette.primary.contrastText} !important`,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    "& .anch-btn": {
      color: `${theme.palette.primary.navyblue} !important`,
      cursor: "pointer",
      fontSize: "12px !important",
      fontWeight: "400 !important",
    },
    "& .coupons": {
      display: "flex",
      gap: "1rem",
      margin: "0.5rem 0",
      padding: "0.5rem",
      backgroundColor: theme.palette.background.darkerFadedGray,
      "& .coupons_name": {
        color: theme.palette.primary.lightText,
      },
      "& .coupon_desc": {
        margin: "0.5rem 0",
      },
      "& .coupon_desc p": {
        fontSize: "14px",
        lineHeight: "15px",
      },
      "& .coupons_name": {
        color: theme.palette.primary.defaultText,
        fontSize: "1.1rem",
        fontWeight: "bold",
      },
      "& .c_btn": {
        background: "none",
        outline: "none",
        fontSize: "1rem",
        border: "none",
        cursor: "pointer",
        color: theme.palette.secondary.lightBlue,
      },
      "& .coupon_icon": {
        fontSize: "2.3rem",
      },
    },
  },
  promoCollapseToggle: {
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
    justifyContent: "space-between",
    cursor: "pointer",
  },
}));
