import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  TextField,
  Popper,
  useTheme,
  IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import adultIcon from "assets/icons/person_black.svg";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import countries from "json/country_dial_codes.json";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useMediaQuery } from "@material-ui/core";

// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
import dateFnsFormat from "date-fns/format";
// import clsx from "clsx";
import { ErrorMessage, FieldArray } from "formik";
import { localforageGetItem } from "oautils/oaForageUtils";
// import OaComboDatePicker from "oahoc/OaComboDatePicker";
// import CustomDatePicker from "oahoc/CustomDatePicker";
import DatePickerWithCustomHeader from "oahoc/DatePickerCustomHeader ";
import OaFormikErrorAlert from "oahoc/OaFormikErrorAlert";
import OaTextFieldAdorned from "oahoc/OaTextFieldAdorned";
import WebApi from "api/ApiConstants";
import { AppConstant } from "appConstant";
// import OaAlert from "oahoc/OaAlert";
// import OaFormAlerts from "pages/components/OaFormAlerts";

const Passenger = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const index = props.indx;
  const values = props.values;
  const setFieldValue = props.setFieldValue;
  const requestObj = props?.history?.location?.state?.requestObj;
  // console.log(requestObj, "REQUESTOBJ");

  const [openAdditional, setOpenAdditional] = useState(!values.isDomestic);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [showSaveInfoTK, setShowSaveInfoTK] = useState(false);
  const [partnerId, setPartnerId] = useState();
  // const [userType, setUserType] = useState();
  const [userData, setUserData] = useState();
  const [isErrorOpen, setIsErrorOpen] = useState(true);
  const [savedPaxTravellerArr, setSavedPaxTravellerArr] = useState([]);

  // console.log("requestObj.partnerId  ", requestObj);
  // const [wonFareId, setWonFareId] = useState(props?.location.state.oneSelectedFareId);
  // const [retFareId, setRetFareId] = useState(props?.location.state.retSelectedFareId );
  // const [sessionId, setSessionId] = useState(props?.location.state.availSessionId);
  // const [dobValidation,setDobValidation ]= useState();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const customPopper = function(props) {
    return <Popper {...props} placement="bottom" style={{}} />;
  };

  const getReqDate = (daySpan) => {
    const reqDate = new Date();

    if (daySpan > 365 * 4) {
      daySpan += Math.floor(daySpan / 365 / 4);
    } else {
      if (daySpan < 0) {
        var tempDs = daySpan * -1;

        if (tempDs > 365 * 4) {
          tempDs += Math.floor(tempDs / 365 / 4);
        }

        daySpan = tempDs * -1;
      }
    }

    reqDate.setTime(reqDate.valueOf() + daySpan * 24 * 3600 * 1000);

    return reqDate;
  };

  const getValuesFromLocalStorage = () => {
    // setIsLoading(true);
    localforageGetItem("user-details", function(err, value) {
      // console.log(value);
      setUserData(value);
      setPartnerId(value.partnerId);
    });
  };

  useEffect(() => {
    getValuesFromLocalStorage();
  }, []);

  const savedPaxHandler = (value, index, type) => {
    // console.log(index);
    if (!AppConstant.savedFlightPax) {
      return false;
    }

    WebApi.getFlightSavedPax(
      {
        agentType: userData.userTypeAbv,
        domesticInternational: values.isDomestic === true ? "D" : "I",
        loggedInUserId: userData.userId,
        searchString: value,
        paxType: type,
      },
      (response) => {
        // console.log(response.data.travelers);
        setSavedPaxTravellerArr(response.data.travelers);
        // resolve(response);
      },
      (error) => {
        console.log("trn create error", error);
        // reject(error);
      }
    );
  };

  const visatypes = [
    { visatype: "", visalabel: "Select Visa Type" },
    { visatype: "tourist", visalabel: "Tourist" },
    { visatype: "employment", visalabel: "Employment" },
    { visatype: "seamen", visalabel: "Seamen" },
    { visatype: "residence", visalabel: "Residence" },
    { visatype: "student", visalabel: "Student" },
    { visatype: "dependent", visalabel: "Dependent" },
    { visatype: "migrant", visalabel: "Migrant" },
    { visatype: "hajj", visalabel: "Hajj" },
    { visatype: "umrah", visalabel: "Umrah" },
    { visatype: "business", visalabel: "Business" },
  ];

  const saveToProps = (values) => {
    props.getpaxinfo(values);
  };

  return (
    <>
      {values && props.type === "adult" && values.adultPax && (
        <div className="input-section-wrapper-inner">
          <Grid
            container
            className={classes.inputSectionTitle}
            style={{ margin: "0" }}
          ></Grid>

          <Grid container className={classes.inputSection}>
            <Grid item md={12} className="paxInputNameContainer">
              <Box className={classes.paxInputSection}>
                <Grid container className="paxInputContainer">
                  <Grid item md={2} sm={2} xs={12}>
                    <div className="form-group">
                      <label className="inner-label">Title</label>
                      <Select
                        name={`adultPax[${index}].initial`}
                        value={values.adultPax[index].initial}
                        // defaultValue={-1} //</div>{values.adultPax[index].initial}
                        onChange={(e) => {
                          // console.log(e.target.value);
                          setFieldValue(
                            `adultPax[${index}].initial`,
                            e.target.value
                          );
                        }}
                        variant="outlined"
                        className="nsel-component"
                      >
                        {/* <MenuItem key="-1" value="-1">
                          Title
                        </MenuItem> */}
                        <MenuItem key="Mr" value="Mr">
                          Mr
                        </MenuItem>
                        <MenuItem key="Mrs" value="Mrs">
                          Mrs
                        </MenuItem>
                        <MenuItem key="Ms" value="Ms">
                          Ms
                        </MenuItem>
                      </Select>

                      <ErrorMessage
                        name={`adultPax[${index}].initial`}
                        component="div"
                        className="error"
                      />

                      {/* <Collapse in={isErrorOpen}>
                        <ErrorMessage name={`adultPax[${index}].initial`}>
                          {(errorMsg) => {
                            // alert(errorMsg);
                            return (
                              <div className="error-msgBox">
                                <div className="error-container">
                                  <Alert
                                    variant="filled"
                                    severity="error"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setIsErrorOpen(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    Please Check the form again {errorMsg}
                                  </Alert>
                                </div>
                              </div>
                            );
                          }}
                        </ErrorMessage>
                      </Collapse> */}
                      <OaFormikErrorAlert
                        name={`adultPax[${index}].initial`}
                        error={props.error}
                        touched={props.touched}
                      />
                    </div>
                  </Grid>

                  <Grid item md={3} xs={12} sm={3}>
                    <div className="form-group">
                      <label className="inner-label">First Name</label>
                      <OaTextFieldAdorned
                        name={`adultPax[${index}].firstname`}
                        // placeholder="First Name"
                        //ref={"adult_fname_${index}"}
                        value={values.adultPax[index].firstname}
                        onChange={(e) => {
                          savedPaxHandler(e.target.value, index, "Adult");
                          setFieldValue(
                            `adultPax[${index}].firstname`,
                            e.target.value
                          );
                        }}
                        fullWidth
                        variant="outlined"
                        adornpos="start"
                        adornico={<i className="fa fa-user" />}
                      />
                      {savedPaxTravellerArr.length > 0 && (
                        <div
                          onClick={() => {
                            setSavedPaxTravellerArr([]);
                          }}
                          className="crossButton"
                        >
                          X
                        </div>
                      )}
                      {savedPaxTravellerArr.length > 0 && (
                        <div className="savedPaxsContainer">
                          {savedPaxTravellerArr.map((pax) => (
                            <div className="savedPaxContainer">
                              <div
                                onClick={() => {
                                  // console.log("wohooo", pax);
                                  setFieldValue(
                                    `adultPax[${index}].initial`,
                                    pax.paxTitle === "Mstr"
                                      ? "Mr"
                                      : pax.paxTitle
                                  );
                                  setFieldValue(
                                    `adultPax[${index}].firstname`,
                                    pax.firstName
                                  );
                                  setFieldValue(
                                    `adultPax[${index}].surname`,
                                    pax.lastName
                                  );
                                  pax?.dob &&
                                    setFieldValue(
                                      `adultPax[${index}].dobDisplay`,
                                      new Date(pax.dob)
                                    );
                                  pax?.dob &&
                                    setFieldValue(
                                      `adultPax[${index}].dob`,
                                      pax.dob
                                    );
                                  setFieldValue(
                                    `adultPax[${index}].passportno`,
                                    pax.passport
                                  );
                                  // setFieldValue(
                                  //   `adultPax[${index}].passportidate`,
                                  //   pax.passportIssueDate
                                  // );
                                  setFieldValue(
                                    `adultPax[${index}].passportidate`,
                                    new Date(pax.passportIssueDate)
                                  );
                                  setFieldValue(
                                    `adultPax[${index}].passportexpire`,
                                    new Date(pax.passportExpireDate)
                                  );
                                  setFieldValue(
                                    `adultPax[${index}].passporticountry`,
                                    pax.issueCountry
                                  );
                                  setSavedPaxTravellerArr([]);
                                }}
                              >
                                {pax.paxTitle} {pax.firstName} {pax.lastName} -
                                ({pax.paxType})
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      <ErrorMessage
                        name={`adultPax[${index}].firstname`}
                        component="div"
                        className="error"
                      />

                      <OaFormikErrorAlert
                        name={`adultPax[${index}].firstname`}
                        error={props.error}
                        touched={props.touched}
                      />
                    </div>
                  </Grid>

                  <Grid item md={3} xs={12} sm={3} className="lastNameRow">
                    <div className="form-group last-name">
                      <label className="inner-label">Last Name</label>
                      <OaTextFieldAdorned
                        name={`adultPax[${index}].surname`}
                        // placeholder="Last Name"
                        value={values.adultPax[index].surname}
                        onChange={(e) => {
                          setFieldValue(
                            `adultPax[${index}].surname`,
                            e.target.value
                          );
                        }}
                        fullWidth
                        variant="outlined"
                        adornpos="start"
                        adornico={<i className="fa fa-user" />}
                      />
                      <ErrorMessage
                        name={`adultPax[${index}].surname`}
                        component="div"
                        className="error"
                      />
                      <OaFormikErrorAlert
                        name={`adultPax[${index}].surname`}
                        error={props.error}
                        touched={props.touched}
                      />
                    </div>
                  </Grid>
                  {requestObj.fareType === "Student" && (
                    <Grid item md={2} xs={12} sm={2} className="lastNameRow">
                      <div className="form-group last-name">
                        <label className="inner-label">Student Id</label>
                        <OaTextFieldAdorned
                          name={`adultPax[${index}].studentId`}
                          // placeholder="Last Name"
                          value={values.adultPax[index].studentId}
                          onChange={(e) => {
                            setFieldValue(
                              `adultPax[${index}].studentId`,
                              e.target.value
                            );
                          }}
                          fullWidth
                          variant="outlined"
                          adornpos="start"
                          // adornico={<i className="fa fa-user" />}
                        />
                        <ErrorMessage
                          name={`adultPax[${index}].studentId`}
                          component="div"
                          className="error"
                        />
                        <OaFormikErrorAlert
                          name={`adultPax[${index}].studentId`}
                          error={props.error}
                          touched={props.touched}
                        />
                      </div>
                    </Grid>
                  )}

                  {(values.isDomestic == false ||
                    requestObj.fareType === "SeniorCitizen") && (
                    <Grid
                      item
                      md={3}
                      xs={12}
                      sm={3}
                      className="birthDateContainer"
                    >
                      <div className="form-group">
                        <label className="inner-label">DATE OF BIRTH</label>
                        {/* <OaComboDatePicker
                        name={`adultPax[${index}].dobDisplay`}
                        maxDate={getReqDate(-(365 * 13))}
                        className="pax-info-date-picker"
                        value={values.adultPax[index].dobDisplay}
                        onChange={(newDate) => {
                          const formatted = dateFnsFormat(
                            newDate,
                            "yyyy-MM-dd"
                          );
                          setFieldValue(
                            `adultPax[${index}].dobDisplay`,
                            newDate
                          );
                          setFieldValue(`adultPax[${index}].dob`, formatted);
                        }}
                      /> */}
                        <DatePickerWithCustomHeader
                          dateFormat="dd-MM-yyyy"
                          maxDate={getReqDate(-(365 * 13))}
                          name={`adultPax[${index}].dobDisplay`}
                          className="pax-info-date-picker"
                          selected={values.adultPax[index].dobDisplay}
                          autoComplete="off"
                          disableClearable
                          onChange={(newDate) => {
                            const formatted = dateFnsFormat(
                              newDate,
                              "dd-MMM-yyyy"
                            );
                            if (requestObj.fareType === "SeniorCitizen") {
                              // console.log(formatted);
                              let age = Math.floor(
                                (new Date() - new Date(formatted).getTime()) /
                                  3.15576e10
                              );
                              if (age < 60) return;
                            }
                            setFieldValue(
                              `adultPax[${index}].dobDisplay`,
                              newDate
                            );
                            setFieldValue(`adultPax[${index}].dob`, formatted);
                          }}
                          showIcon
                        />

                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          name={`adultPax[${index}].dobDisplay`}
                          disableFuture
                          maxDate={getReqDate(-(365 * 13))}
                          className="pax-info-date-picker"
                          fullWidth
                          color="secondary"
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          margin="normal"
                          autoOk={true}
                          value={values.adultPax[index].dobDisplay}
                          onChange={(e, v) => {
                            const formatted = dateFnsFormat(
                              new Date(e),
                              "yyyy-MM-dd"
                            );
                            setFieldValue(
                              `adultPax[${index}].dobDisplay`,
                              new Date(e)
                            );
                            setFieldValue(`adultPax[${index}].dob`, formatted);
                          }}
                          emptyLabel="Date of birth"
                        />
                      </MuiPickersUtilsProvider> */}
                        <ErrorMessage
                          name={`adultPax[${index}].dobDisplay`}
                          component="div"
                          className="error"
                        />
                      </div>
                      <OaFormikErrorAlert
                        name={`adultPax[${index}].dobDisplay`}
                        error={props.error}
                        touched={props.touched}
                      />
                    </Grid>
                  )}

                  <Grid
                    item
                    md={1}
                    xs={2}
                    sm={1}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Grid item md={6}>
                      <div
                        className="form-group"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          cursor: "pointer",
                          paddingTop: 5,
                          marginTop: 30,
                          marginLeft: 10,
                          paddingLeft: 16,
                        }}
                        onClick={() => setOpenAdditional(!openAdditional)}
                      >
                        <span className="additional-info-span">
                          <i
                            onMouseEnter={() => setShowAdditionalInfo(true)}
                            onMouseLeave={() => setShowAdditionalInfo(false)}
                            className={
                              openAdditional ? "fas fa-minus" : "fas fa-plus"
                            }
                          ></i>
                          {showAdditionalInfo && (
                            <span className="additional-info-mobile-label">
                              Additional Info
                            </span>
                          )}
                        </span>
                      </div>
                    </Grid>
                    {requestObj.clientType === "Corporate" && (
                      <Grid item md={6}>
                        <div
                          className="form-group"
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            cursor: "pointer",
                            paddingTop: 5,
                            marginTop: 30,
                          }}
                          onClick={() => {
                            setFieldValue(
                              `adultPax[${index}].paxSave`,
                              values.adultPax[index].paxSave === true
                                ? false
                                : true
                            );
                          }}
                        >
                          <span style={{ marginRight: 5, fontSize: 16 }}>
                            <i
                              className={"fas fa-save"}
                              onMouseEnter={() => setShowSaveInfoTK(true)}
                              onMouseLeave={() => setShowSaveInfoTK(false)}
                              style={{
                                // color:
                                //   values.adultPax[index].paxSave === true
                                //     ? theme.palette.primary.active
                                //     : theme.palette.primary.active,
                                color:
                                  values?.adultPax[index]?.paxSave === true
                                    ? "green"
                                    : "black",
                              }}
                            ></i>
                            {showSaveInfoTK && (
                              <span className="additional-info-mobile-label">
                                Save Pax
                              </span>
                            )}
                          </span>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item md={12}>
              <Collapse in={openAdditional}>
                <Grid container>
                  {values.isDomestic == false && (
                    <Grid container>
                      <Grid item md={12} className="passportContainer">
                        <Box>
                          <Grid container className="passportInputContainer">
                            <Grid item className="flex-col-20">
                              <div className="form-group">
                                <label className="inner-label">
                                  PASSPORT NO.
                                </label>
                                <TextField
                                  name={`adultPax[${index}].passportno`}
                                  value={values.adultPax[index].passportno}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `adultPax[${index}].passportno`,
                                      e.target.value
                                    );
                                  }}
                                  fullWidth
                                  variant="outlined"
                                />
                                <ErrorMessage
                                  name={`adultPax[${index}].passportno`}
                                  component="div"
                                  className="error"
                                />
                              </div>
                              {/* <Collapse in={isErrorOpen}>
                                <ErrorMessage
                                  name={`adultPax[${index}].passportno`}
                                >
                                  {(errorMsg) => {
                                    // alert(errorMsg);
                                    return (
                                      <div className="error-msgBox">
                                        <div className="error-container">
                                          <Alert
                                            variant="filled"
                                            severity="error"
                                            action={
                                              <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                  setIsErrorOpen(false);
                                                }}
                                              >
                                                <CloseIcon fontSize="inherit" />
                                              </IconButton>
                                            }
                                          >
                                            Please Check the form again{" "}
                                            {errorMsg}
                                          </Alert>
                                        </div>
                                      </div>
                                    );
                                  }}
                                </ErrorMessage>
                              </Collapse> */}
                              <OaFormikErrorAlert
                                name={`adultPax[${index}].passportno`}
                                error={props.error}
                                touched={props.touched}
                              />
                            </Grid>

                            <Grid item className="flex-col-20">
                              <div className="form-group">
                                <label className="inner-label">
                                  ISSUING DATE
                                </label>
                                {/* <OaComboDatePicker
                                  name={`adultPax[${index}].passportidate`}
                                  minDate={getReqDate(-(365 * 10))}
                                  className="pax-info-date-picker"
                                  value={values.adultPax[index].passportidate}
                                  onChange={(newDate) => {
                                    const formatted = dateFnsFormat(
                                      newDate,"yyyy-MM-dd"

                                    );
                                    setFieldValue(
                                      `adultPax[${index}].passportidate`,
                                      formatted
                                    );
                                  }}
                                /> */}
                                <DatePickerWithCustomHeader
                                  name={`adultPax[${index}].passportidate`}
                                  className="pax-info-date-picker"
                                  dateFormat="dd-MM-yyyy"
                                  selected={
                                    values.adultPax[index].passportidate
                                  }
                                  autoComplete="off"
                                  disableClearable
                                  maxDate={getReqDate(-(365 * 0))}
                                  minDate={getReqDate(-(365 * 10))}
                                  onChange={(newDate) => {
                                    const formatted = dateFnsFormat(
                                      newDate,
                                      "yyyy-MM-dd"
                                    );
                                    // console.log("newDate",newDate)
                                    setFieldValue(
                                      `adultPax[${index}].passportidate`,
                                      newDate
                                    );
                                    // setFieldValue(
                                    //   `adultPax[${index}].dob`,
                                    //   formatted
                                    // );
                                  }}
                                />

                                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    name={`adultPax[${index}].passportidate`}
                                    minDate={getReqDate(-(365 * 10))}
                                    disableFuture
                                    className="pax-info-date-picker"
                                    fullWidth
                                    color="secondary"
                                    inputVariant="outlined"
                                    format="dd-MM-yyyy"
                                    margin="normal"
                                    autoOk={true}
                                    value={values.adultPax[index].passportidate}
                                    onChange={(e, v) => {
                                      const formatted = dateFnsFormat(
                                        new Date(e),
                                        "yyyy-MM-dd"
                                      );
                                      setFieldValue(
                                        `adultPax[${index}].passportidate`,
                                        formatted
                                      );
                                    }}
                                    emptyLabel="Passport issued on"
                                  />
                                </MuiPickersUtilsProvider> */}
                                <ErrorMessage
                                  name={`adultPax[${index}].passportidate`}
                                  component="div"
                                  className="error"
                                />

                                <OaFormikErrorAlert
                                  name={`adultPax[${index}].passportidate`}
                                  error={props.error}
                                  touched={props.touched}
                                />
                              </div>
                            </Grid>

                            <Grid item className="flex-col-20">
                              <div className="form-group">
                                <label className="inner-label">
                                  EXPIRY DATE
                                </label>
                                {/* <OaComboDatePicker
                                  name={`adultPax[${index}].passportexpire`}
                                  maxDate={getReqDate(365 * 10)}
                                  className="pax-info-date-picker"
                                  value={values.adultPax[index].passportexpire}
                                  onChange={(newDate) => {
                                    const formatted = dateFnsFormat(
                                      newDate,
                                      "yyyy-MM-dd"
                                    );
                                    setFieldValue(
                                      `adultPax[${index}].passportexpire`,
                                      formatted
                                    );
                                  }}
                                /> */}
                                <DatePickerWithCustomHeader
                                  dateFormat="dd-MM-yyyy"
                                  maxDate={getReqDate(365 * 15)}
                                  minDate={getReqDate(-(365 * 0))}
                                  name={`adultPax[${index}].passportexpire`}
                                  className="pax-info-date-picker"
                                  autoComplete="off"
                                  disableClearable
                                  selected={
                                    values.adultPax[index].passportexpire
                                  }
                                  onChange={(newDate) => {
                                    const formatted = dateFnsFormat(
                                      newDate,
                                      "yyyy-MM-dd"
                                    );
                                    setFieldValue(
                                      `adultPax[${index}].passportexpire`,
                                      newDate
                                    );
                                  }}
                                />
                                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    name={`adultPax[${index}].passportexpire`}
                                    maxDate={getReqDate(365 * 10)}
                                    disablePast
                                    className="pax-info-date-picker"
                                    fullWidth
                                    color="secondary"
                                    inputVariant="outlined"
                                    format="dd-MM-yyyy"
                                    margin="normal"
                                    autoOk={true}
                                    value={
                                      values.adultPax[index].passportexpire
                                    }
                                    onChange={(e, v) => {
                                      const formatted = dateFnsFormat(
                                        new Date(e),
                                        "yyyy-MM-dd"
                                      );
                                      setFieldValue(
                                        `adultPax[${index}].passportexpire`,
                                        formatted
                                      );
                                    }}
                                    emptyLabel="Passport expiry date"
                                  />
                                </MuiPickersUtilsProvider> */}
                                <ErrorMessage
                                  name={`adultPax[${index}].passportexpire`}
                                  component="div"
                                  className="error"
                                />
                                <OaFormikErrorAlert
                                  name={`adultPax[${index}].passportexpire`}
                                  error={props.error}
                                  touched={props.touched}
                                />
                              </div>
                            </Grid>

                            <Grid item className="flex-col-20">
                              <div className="form-group">
                                <label className="inner-label">
                                  ISSUING COUNTRY
                                </label>
                                <Autocomplete
                                  className="aac-component"
                                  fullWidth
                                  name={`adultPax[${index}].passporticountry`}
                                  PopperComponent={customPopper}
                                  options={countries || []}
                                  getOptionLabel={(o) =>
                                    o
                                      ? o.code != ""
                                        ? `${o.name} (${o.code})`
                                        : `${o.name}`
                                      : ""
                                  }
                                  // defaultValue={
                                  //   countries.filter((ctry) => {
                                  //     return ctry.code === "";
                                  //   })[0]
                                  // }
                                  //value={values.adultPax[index].passporticountry}
                                  value={
                                    // values.adultPax[index].passporticountry ??
                                    countries.filter((ctry) => {
                                      return (
                                        ctry.code ===
                                          values?.adultPax[index]
                                            ?.passporticountry ?? ""
                                      );
                                    })[0]
                                  }
                                  getOptionSelected={(option, value) =>
                                    option.code == value.code
                                  }
                                  onChange={(event, newValue) => {
                                    // console.log(newValue);
                                    if (newValue != null) {
                                      setFieldValue(
                                        `adultPax[${index}].passporticountry`,
                                        newValue
                                      );
                                      setFieldValue(
                                        `adultPax[${index}].passporticountry`,
                                        newValue.code
                                      );
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      color="secondary"
                                      variant="outlined"
                                      inputProps={{
                                        ...params.inputProps,
                                        autocomplete: "new-password",
                                      }}
                                    />
                                  )}
                                  disablePortal={true}
                                />
                                <ErrorMessage
                                  name={`adultPax[${index}].passporticountry`}
                                  component="div"
                                  className="error"
                                />
                                <OaFormikErrorAlert
                                  name={`adultPax[${index}].passporticountry`}
                                  error={props.error}
                                  touched={props.touched}
                                />
                              </div>
                            </Grid>

                            <Grid item className="flex-col-20">
                              <div className="form-group">
                                <label className="inner-label">VISA TYPE</label>
                                <Autocomplete
                                  className="iac-component"
                                  fullWidth
                                  name={`adultPax[${index}].visatype`}
                                  PopperComponent={customPopper}
                                  options={visatypes || []}
                                  getOptionLabel={(o) =>
                                    o ? `${o.visalabel}` : ""
                                  }
                                  defaultValue={
                                    visatypes.filter((visas) => {
                                      return visas.visatype === "";
                                    })[0]
                                  }
                                  getOptionSelected={(option, value) =>
                                    option.code == value.visatype
                                  }
                                  onChange={(event, newValue) => {
                                    // console.log(newValue);
                                    if (newValue != null) {
                                      setFieldValue(
                                        `adultPax[${index}].visatype`,
                                        newValue.visatype
                                      );
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      color="secondary"
                                      variant="outlined"
                                      inputProps={{
                                        ...params.inputProps,
                                        autocomplete: "new-password",
                                      }}
                                    />
                                  )}
                                  disablePortal={true}
                                />
                                <ErrorMessage
                                  name={`adultPax[${index}].visatype`}
                                  component="div"
                                  className="error"
                                />
                                <OaFormikErrorAlert
                                  name={`adultPax[${index}].visatype`}
                                  error={props.error}
                                  touched={props.touched}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    item
                    md={12}
                    style={{
                      padding: "5px 10px",
                      paddingBottom: 0,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontSize: 12, fontWeight: 500 }}>
                      Onward
                    </Typography>
                  </Grid>

                  {values.adultPax[index].additionalOnward &&
                    values.adultPax[index].additionalOnward.map(
                      (addn, indx) =>
                        indx == 0 && (
                          <Grid
                            item
                            md={12}
                            style={{ padding: "0 5px" }}
                            key={indx}
                          >
                            <Box className={classes.paxInputSection}>
                              <Grid container>
                                <Grid item md={3} sm={12} xs={12}>
                                  <div
                                    className="onwardSection form-group"
                                    style={{ marginRight: 10 }}
                                  >
                                    <TextField
                                      placeholder="FF airline"
                                      name={`adultPax[${index}].additionalOnward[${indx}].frequentAirline`}
                                      value={
                                        values.adultPax[index].additionalOnward[
                                          indx
                                        ].frequentAirlineName +
                                        " " +
                                        values.adultPax[index].additionalOnward[
                                          indx
                                        ].frequentAirline
                                      }
                                      fullWidth
                                      disabled
                                      variant="outlined"
                                    />
                                  </div>
                                </Grid>

                                <Grid item md={3} sm={12} xs={12}>
                                  <div className="onwardSection form-group">
                                    <TextField
                                      name={`adultPax[${index}].additionalOnward[${indx}].frequentFlyerNo`}
                                      placeholder="FF no."
                                      value={
                                        values.adultPax[index].additionalOnward[
                                          indx
                                        ].frequentFlyerNo
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `adultPax[${index}].additionalOnward[${indx}].frequentFlyerNo`,
                                          e.target.value
                                        );
                                      }}
                                      fullWidth
                                      variant="outlined"
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        )
                    )}

                  {requestObj &&
                    (requestObj.mode == "ROUND" ||
                      requestObj.mode == "ROUND-SP") && (
                      <>
                        <Grid item md={12} style={{ padding: "5px 10px" }}>
                          <Typography style={{ fontSize: 12, fontWeight: 500 }}>
                            Return
                          </Typography>
                        </Grid>

                        {values.adultPax[index].additionalReturn &&
                          values.adultPax[index].additionalReturn.map(
                            (addn, indx) =>
                              indx == 0 && (
                                <Grid
                                  item
                                  md={12}
                                  style={{ padding: "0 5px" }}
                                  key={indx}
                                >
                                  <Box className={classes.paxInputSection}>
                                    <Grid container>
                                      <Grid item md={3} sm={12} xs={12}>
                                        <div
                                          className="form-group returnSection"
                                          style={{ paddingTop: 13.5 }}
                                        >
                                          <TextField
                                            placeholder="FF airline"
                                            name={`adultPax[${index}].additionalReturn[${indx}].frequentAirline`}
                                            value={
                                              values.adultPax[index]
                                                .additionalReturn[indx]
                                                .frequentAirlineName +
                                              " " +
                                              values.adultPax[index]
                                                .additionalReturn[indx]
                                                .frequentAirline
                                            }
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                          />
                                        </div>
                                      </Grid>

                                      <Grid item md={3} sm={12} xs={12}>
                                        <div className="form-group returnSection">
                                          <TextField
                                            name={`adultPax[${index}].additionalReturn[${indx}].frequentFlyerNo`}
                                            placeholder="FF no."
                                            value={
                                              values.adultPax[index]
                                                .additionalReturn[indx]
                                                .frequentFlyerNo
                                            }
                                            fullWidth
                                            onChange={(e) => {
                                              setFieldValue(
                                                `adultPax[${index}].additionalReturn[${indx}].frequentFlyerNo`,
                                                e.target.value
                                              );
                                            }}
                                            variant="outlined"
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>
                              )
                          )}
                      </>
                    )}
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </div>
      )}

      {values && props.type === "child" && values.childPax && (
        <div className="input-section-wrapper-inner">
          <Grid container className={classes.inputSection}>
            <Grid item md={12}>
              <Box className={classes.paxInputSection}>
                <Grid container className="paxInputContainer">
                  <Grid item md={2} sm={12} xs={12}>
                    <div className="form-group">
                      <label className="inner-label">Title</label>
                      <Select
                        name={`childPax[${index}].initial`}
                        value={values.childPax[index].initial}
                        // defaultValue={-1} //{values.childPax[index].initial}
                        onChange={(e) => {
                          //   console.log(e.target.value);
                          setFieldValue(
                            `childPax[${index}].initial`,
                            e.target.value
                          );
                        }}
                        variant="outlined"
                        className="nsel-component"
                      >
                        {/* <MenuItem key="-1" value="-1">
                          Title
                        </MenuItem> */}
                        <MenuItem key="Mstr" value="Mstr">
                          Mstr
                        </MenuItem>
                        <MenuItem key="Miss" value="Miss">
                          Miss
                        </MenuItem>
                      </Select>
                      <ErrorMessage
                        name={`childPax[${index}].initial`}
                        component="div"
                        className="error"
                      />
                      <OaFormikErrorAlert name={`childPax[${index}].initial`} />
                    </div>
                  </Grid>

                  <Grid item md={3} xs={12} sm={3}>
                    <div className="form-group">
                      <label className="inner-label">First Name</label>
                      <OaTextFieldAdorned
                        name={`childPax[${index}].firstname`}
                        // placeholder="First Name"
                        value={values.childPax[index].firstname}
                        onChange={(e) => {
                          savedPaxHandler(e.target.value, index, "Child");
                          setFieldValue(
                            `childPax[${index}].firstname`,
                            e.target.value
                          );
                        }}
                        fullWidth
                        variant="outlined"
                        adornpos="start"
                        adornico={<i className="fa fa-user" />}
                      />
                      {savedPaxTravellerArr.length > 0 && (
                        <div
                          onClick={() => {
                            setSavedPaxTravellerArr([]);
                          }}
                          className="crossButton"
                        >
                          X
                        </div>
                      )}
                      {savedPaxTravellerArr.length > 0 && (
                        <div className="savedPaxsContainer">
                          {savedPaxTravellerArr.map((pax) => (
                            <div className="savedPaxContainer">
                              <div
                                onClick={() => {
                                  setFieldValue(
                                    `childPax[${index}].initial`,
                                    pax.paxTitle === "Mstr"
                                      ? "Mstr"
                                      : pax.paxTitle
                                  );
                                  setFieldValue(
                                    `childPax[${index}].firstname`,
                                    pax.firstName
                                  );
                                  setFieldValue(
                                    `childPax[${index}].surname`,
                                    pax.lastName
                                  );
                                  pax?.dob &&
                                    setFieldValue(
                                      `childPax[${index}].dobDisplay`,
                                      new Date(pax.dob)
                                    );
                                  pax?.dob &&
                                    setFieldValue(
                                      `childPax[${index}].dob`,
                                      pax.dob
                                    );
                                  pax?.dob &&
                                    setFieldValue(
                                      `childPax[${index}].dob`,
                                      pax.dob
                                    );
                                  setFieldValue(
                                    `childPax[${index}].passportno`,
                                    pax.passport
                                  );
                                  // setFieldValue(
                                  //   `adultPax[${index}].passportidate`,
                                  //   pax.passportIssueDate
                                  // );
                                  setFieldValue(
                                    `childPax[${index}].passportidate`,
                                    new Date(pax.passportIssueDate)
                                  );
                                  setFieldValue(
                                    `childPax[${index}].passportexpire`,
                                    new Date(pax.passportExpireDate)
                                  );
                                  setFieldValue(
                                    `childPax[${index}].passporticountry`,
                                    pax.issueCountry
                                  );
                                  setSavedPaxTravellerArr([]);
                                }}
                              >
                                {pax.paxTitle} {pax.firstName} {pax.lastName} -
                                ({pax.paxType})
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      <ErrorMessage
                        name={`childPax[${index}].firstname`}
                        component="div"
                        className="error"
                      />
                      {/* <Collapse in={isErrorOpen}>
                        <ErrorMessage  name={`childPax[${index}].firstname`}>
                          {(errorMsg) => {
                            // alert(errorMsg);
                            return (
                              <div className="error-msgBox">
                                <div className="error-container">
                                  <Alert
                                    variant="filled"
                                    severity="error"
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setIsErrorOpen(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    Please Check the form again {errorMsg}
                                  </Alert>
                                </div>
                              </div>
                            );
                          }}
                        </ErrorMessage>
                      </Collapse> */}
                      <OaFormikErrorAlert
                        name={`childPax[${index}].firstname`}
                      />
                    </div>
                  </Grid>

                  <Grid item md={3} xs={12} sm={3}>
                    <div className="form-group">
                      <label className="inner-label">Last Name</label>
                      <OaTextFieldAdorned
                        name={`childPax[${index}].surname`}
                        // placeholder="Last Name"
                        value={values.childPax[index].surname}
                        onChange={(e) => {
                          setFieldValue(
                            `childPax[${index}].surname`,
                            e.target.value
                          );
                        }}
                        fullWidth
                        variant="outlined"
                        adornpos="start"
                        adornico={<i className="fa fa-user" />}
                      />
                      <ErrorMessage
                        name={`childPax[${index}].surname`}
                        component="div"
                        className="error"
                      />
                      <OaFormikErrorAlert
                        name={`childPax[${index}].surname`}
                        error={props.error}
                        touched={props.touched}
                      />
                    </div>
                  </Grid>

                  <Grid item md={2} xs={8} sm={3}>
                    <div className="form-group">
                      <label className="inner-label">DATE OF BIRTH</label>
                      {/* <OaComboDatePicker
                        name={`childPax[${index}].dobDisplay`}
                        minDate={getReqDate(-(365 * 12))}
                        maxDate={getReqDate(-(365 * 2) + 1)}
                        className="pax-info-date-picker"
                        value={values.childPax[index].dobDisplay}
                        onChange={(newDate) => {
                          const formatted = dateFnsFormat(
                            newDate,
                            "yyyy-MM-dd"
                          );
                          setFieldValue(
                            `childPax[${index}].dobDisplay`,
                            newDate
                          );
                          setFieldValue(`childPax[${index}].dob`, formatted);
                        }}
                      /> */}
                      <DatePickerWithCustomHeader
                        dateFormat="dd-MM-yyyy"
                        minDate={getReqDate(-(365 * 12))}
                        maxDate={getReqDate(-(365 * 2) + 1)}
                        name={`childPax[${index}].dobDisplay`}
                        className="pax-info-date-picker"
                        autoComplete="off"
                        disableClearable
                        selected={values.childPax[index].dobDisplay}
                        onChange={(newDate) => {
                          const formatted = dateFnsFormat(
                            newDate,
                            "dd-MMM-yyyy"
                          );
                          setFieldValue(
                            `childPax[${index}].dobDisplay`,
                            newDate
                          );
                          setFieldValue(`childPax[${index}].dob`, formatted);
                          //  console.log("newDate", newDate, formatted);
                        }}
                        showIcon
                      />
                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          name={`childPax[${index}].dobDisplay`}
                          disableFuture
                          minDate={getReqDate(-(365 * 12))}
                          maxDate={getReqDate(-(365 * 2) + 1)}
                          className="pax-info-date-picker"
                          fullWidth
                          color="secondary"
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          margin="normal"
                          autoOk={true}
                          value={values.childPax[index].dobDisplay}
                          onChange={(e, v) => {
                            const formatted = dateFnsFormat(
                              new Date(e),
                              "yyyy-MM-dd"
                            );
                            setFieldValue(`childPax[${index}].dob`, formatted);
                            setFieldValue(
                              `childPax[${index}].dobDisplay`,
                              new Date(e)
                            );
                          }}
                          emptyLabel="Date of birth"
                        />
                      </MuiPickersUtilsProvider> */}
                      <ErrorMessage
                        name={`childPax[${index}].dobDisplay`}
                        component="div"
                        className="error"
                      />
                      <OaFormikErrorAlert
                        name={`childPax[${index}].dobDisplay`}
                        error={props.error}
                        touched={props.touched}
                      />
                    </div>
                  </Grid>

                  <Grid
                    item
                    md={1}
                    xs={2}
                    sm={1}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Grid item md={6}>
                      <div
                        className="form-group"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          cursor: "pointer",
                          paddingTop: 5,
                          marginTop: 30,
                          marginLeft: 10,
                          paddingLeft: 16,
                        }}
                        onClick={() => setOpenAdditional(!openAdditional)}
                      >
                        <span className="additional-info-span">
                          <i
                            onMouseEnter={() => setShowAdditionalInfo(true)}
                            onMouseLeave={() => setShowAdditionalInfo(false)}
                            className={
                              openAdditional ? "fas fa-minus" : "fas fa-plus"
                            }
                          ></i>
                          {showAdditionalInfo && (
                            <span className="additional-info-mobile-label">
                              Additional Info
                            </span>
                          )}
                        </span>
                      </div>
                    </Grid>
                    {requestObj.clientType === "Corporate" && (
                      <Grid item md={6}>
                        <div
                          className="form-group"
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            cursor: "pointer",
                            paddingTop: 5,
                            marginTop: 30,
                          }}
                          onClick={() =>
                            setFieldValue(
                              `childPax[${index}].paxSave`,
                              values.childPax[index].paxSave === true
                                ? false
                                : true
                            )
                          }
                        >
                          <span style={{ marginRight: 5, fontSize: 16 }}>
                            <i
                              className={"fas fa-save"}
                              onMouseEnter={() => setShowSaveInfoTK(true)}
                              onMouseLeave={() => setShowSaveInfoTK(false)}
                              style={{
                                color:
                                  values.childPax[index].paxSave == true
                                    ? "green"
                                    : "black",
                              }}
                            ></i>
                            {showSaveInfoTK && (
                              <span className="additional-info-mobile-label">
                                Save Pax
                              </span>
                            )}
                          </span>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item md={12}>
              <Collapse in={openAdditional}>
                <Grid container>
                  {values.isDomestic == false && (
                    <Grid container>
                      <Grid item md={12}>
                        <Box>
                          <Grid container>
                            <Grid item className="flex-col-20">
                              <div className="form-group">
                                <label className="inner-label">
                                  PASSPORT NO.
                                </label>
                                <TextField
                                  name={`childPax[${index}].passportno`}
                                  value={values.childPax[index].passportno}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `childPax[${index}].passportno`,
                                      e.target.value
                                    );
                                  }}
                                  fullWidth
                                  variant="outlined"
                                />
                                <ErrorMessage
                                  name={`childPax[${index}].passportno`}
                                  component="div"
                                  className="error"
                                />
                                <OaFormikErrorAlert
                                  name={`childPax[${index}].passportno`}
                                  error={props.error}
                                  touched={props.touched}
                                />
                              </div>
                            </Grid>

                            <Grid item className="flex-col-20">
                              <div className="form-group">
                                <label className="inner-label">
                                  ISSUING DATE
                                </label>
                                {/* <OaComboDatePicker
                                  name={`childPax[${index}].passportidate`}
                                  minDate={getReqDate(-(365 * 10))}
                                  className="pax-info-date-picker"
                                  value={values.childPax[index].passportidate}
                                  onChange={(newDate) => {
                                    const formatted = dateFnsFormat(
                                      newDate, "yyyy-MM-dd"
                                    );
                                    setFieldValue(
                                      `childPax[${index}].passportidate`,
                                      formatted
                                    );
                                  }}
                                /> */}
                                <DatePickerWithCustomHeader
                                  dateFormat="dd-MM-yyyy"
                                  name={`childPax[${index}].passportidate`}
                                  maxDate={getReqDate(-(365 * 0))}
                                  minDate={getReqDate(-(365 * 10))}
                                  autoComplete="off"
                                  disableClearable
                                  className="pax-info-date-picker"
                                  selected={
                                    values.childPax[index].passportidate
                                  }
                                  onChange={(newDate) => {
                                    const formatted = dateFnsFormat(
                                      newDate,
                                      "yyyy-MM-dd"
                                    );
                                    setFieldValue(
                                      `childPax[${index}].passportidate`,
                                      newDate
                                    );
                                    //setFieldValue(`childPax[${index}].passportidate`, formatted);
                                  }}
                                />
                                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    name={`childPax[${index}].passportidate`}
                                    minDate={getReqDate(-(365 * 10))}
                                    disableFuture
                                    className="pax-info-date-picker"
                                    fullWidth
                                    color="secondary"
                                    inputVariant="outlined"
                                    format="dd-MM-yyyy"
                                    margin="normal"
                                    autoOk={true}
                                    value={values.childPax[index].passportidate}
                                    onChange={(e, v) => {
                                      const formatted = dateFnsFormat(
                                        new Date(e),
                                        "yyyy-MM-dd"
                                      );
                                      setFieldValue(
                                        `childPax[${index}].passportidate`,
                                        formatted
                                      );
                                    }}
                                    emptyLabel="Passport issued on"
                                  />
                                </MuiPickersUtilsProvider> */}
                                <ErrorMessage
                                  name={`childPax[${index}].passportidate`}
                                  component="div"
                                  className="error"
                                />
                                <OaFormikErrorAlert
                                  name={`childPax[${index}].passportidate`}
                                  error={props.error}
                                  touched={props.touched}
                                />
                              </div>
                            </Grid>

                            <Grid item className="flex-col-20">
                              <div className="form-group">
                                <label className="inner-label">
                                  EXPIRY DATE
                                </label>
                                {/* <OaComboDatePicker
                                  name={`childPax[${index}].passportexpire`}
                                  maxDate={getReqDate(365 * 10)}
                                  className="pax-info-date-picker"
                                  value={values.childPax[index].passportexpire}
                                  onChange={(newDate) => {
                                    const formatted = dateFnsFormat(
                                      newDate,
                                      "yyyy-MM-dd"
                                    );
                                    setFieldValue(
                                      `childPax[${index}].passportexpire`,
                                      formatted
                                    );
                                  }}
                                /> */}
                                <DatePickerWithCustomHeader
                                  dateFormat="dd-MM-yyyy"
                                  name={`childPax[${index}].passportexpire`}
                                  maxDate={getReqDate(365 * 15)}
                                  minDate={getReqDate(-(365 * 0))}
                                  autoComplete="off"
                                  disableClearable
                                  className="pax-info-date-picker"
                                  selected={
                                    values.childPax[index].passportexpire
                                  }
                                  onChange={(newDate) => {
                                    const formatted = dateFnsFormat(
                                      newDate,
                                      "yyyy-MM-dd"
                                    );
                                    setFieldValue(
                                      `childPax[${index}].passportexpire`,
                                      newDate
                                    );
                                    //setFieldValue(`childPax[${index}].passportexpire`, formatted);
                                  }}
                                />
                                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    name={`childPax[${index}].passportexpire`}
                                    maxDate={getReqDate(365 * 10)}
                                    disablePast
                                    className="pax-info-date-picker"
                                    fullWidth
                                    color="secondary"
                                    inputVariant="outlined"
                                    format="dd-MM-yyyy"
                                    margin="normal"
                                    autoOk={true}
                                    value={
                                      values.childPax[index].passportexpire
                                    }
                                    onChange={(e, v) => {
                                      const formatted = dateFnsFormat(
                                        new Date(e),
                                        "yyyy-MM-dd"
                                      );
                                      setFieldValue(
                                        `childPax[${index}].passportexpire`,
                                        formatted
                                      );
                                    }}
                                    emptyLabel="Passport expiry date"
                                  />
                                </MuiPickersUtilsProvider> */}
                                <ErrorMessage
                                  name={`childPax[${index}].passportexpire`}
                                  component="div"
                                  className="error"
                                />
                                <OaFormikErrorAlert
                                  name={`childPax[${index}].passportexpire`}
                                  error={props.error}
                                  touched={props.touched}
                                />
                              </div>
                            </Grid>

                            <Grid item className="flex-col-20">
                              <div className="form-group">
                                <label className="inner-label">
                                  ISSUING COUNTRY
                                </label>
                                <Autocomplete
                                  fullWidth
                                  className="aac-component"
                                  name={`childPax[${index}].passporticountry`}
                                  PopperComponent={customPopper}
                                  options={countries || []}
                                  getOptionLabel={(o) =>
                                    o
                                      ? o.code != ""
                                        ? `${o.name} (${o.code})`
                                        : `${o.name}`
                                      : ""
                                  }
                                  // defaultValue={
                                  //   countries.filter((ctry) => {
                                  //     return ctry.code === "";
                                  //   })[0]
                                  // }
                                  value={
                                    // values.adultPax[index].passporticountry ??
                                    countries.filter((ctry) => {
                                      return (
                                        ctry.code ===
                                          values?.childPax[index]
                                            ?.passporticountry ?? ""
                                      );
                                    })[0]
                                  }
                                  //value={values.childPax[index].passporticobj}
                                  getOptionSelected={(option, value) =>
                                    option.code == value.code
                                  }
                                  onChange={(event, newValue) => {
                                    // console.log(newValue);
                                    if (newValue != null) {
                                      setFieldValue(
                                        `childPax[${index}].passporticountry`,
                                        newValue
                                      );
                                      setFieldValue(
                                        `childPax[${index}].passporticountry`,
                                        newValue.code
                                      );
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      color="secondary"
                                      variant="outlined"
                                      inputProps={{
                                        ...params.inputProps,
                                        autocomplete: "new-password",
                                      }}
                                    />
                                  )}
                                  disablePortal={true}
                                />
                                <ErrorMessage
                                  name={`childPax[${index}].passporticountry`}
                                  component="div"
                                  className="error"
                                />
                                <OaFormikErrorAlert
                                  name={`childPax[${index}].passporticountry`}
                                  error={props.error}
                                  touched={props.touched}
                                />
                              </div>
                            </Grid>

                            <Grid item className="flex-col-20">
                              <div className="form-group">
                                <label className="inner-label">VISA TYPE</label>
                                <Autocomplete
                                  fullWidth
                                  className="aac-component"
                                  name={`childPax[${index}].visatype`}
                                  PopperComponent={customPopper}
                                  options={visatypes || []}
                                  getOptionLabel={(o) =>
                                    o ? `${o.visalabel}` : ""
                                  }
                                  defaultValue={
                                    visatypes.filter((visas) => {
                                      return visas.visatype === "";
                                    })[0]
                                  }
                                  getOptionSelected={(option, value) =>
                                    option.code == value.visatype
                                  }
                                  onChange={(event, newValue) => {
                                    // console.log(newValue);
                                    if (newValue != null) {
                                      setFieldValue(
                                        `childPax[${index}].visatype`,
                                        newValue.visatype
                                      );
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      color="secondary"
                                      variant="outlined"
                                      inputProps={{
                                        ...params.inputProps,
                                        autocomplete: "new-password",
                                      }}
                                    />
                                  )}
                                  disablePortal={true}
                                />
                                <ErrorMessage
                                  name={`childPax[${index}].visatype`}
                                  component="div"
                                  className="error"
                                />
                                <OaFormikErrorAlert
                                  name={`childPax[${index}].visatype`}
                                  error={props.error}
                                  touched={props.touched}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    item
                    md={12}
                    style={{
                      padding: "5px 10px",
                    }}
                  >
                    <Typography style={{ fontSize: 12, fontWeight: 500 }}>
                      Onward
                    </Typography>
                  </Grid>

                  {values.childPax[index].additionalOnward &&
                    values.childPax[index].additionalOnward.map(
                      (addn, indx) =>
                        indx == 0 && (
                          <Grid
                            item
                            md={12}
                            style={{ padding: "0 5px" }}
                            key={indx}
                          >
                            <Box className={classes.paxInputSection}>
                              <Grid container>
                                <Grid item md={3} sm={12} xs={12}>
                                  <div className=" onwardSection form-group">
                                    <TextField
                                      placeholder="FF airline"
                                      name={`childPax[${index}].additionalOnward[${indx}].frequentAirline`}
                                      value={
                                        values.childPax[index].additionalOnward[
                                          indx
                                        ].frequentAirlineName +
                                        " " +
                                        values.childPax[index].additionalOnward[
                                          indx
                                        ].frequentAirline
                                      }
                                      fullWidth
                                      disabled
                                      variant="outlined"
                                    />
                                  </div>
                                </Grid>

                                <Grid item md={3} sm={12} xs={12}>
                                  <div className="onwardSection form-group">
                                    <TextField
                                      name={`childPax[${index}].additionalOnward[${indx}].frequentFlyerNo`}
                                      placeholder="FF no."
                                      value={
                                        values.childPax[index].frequentFlyerNo
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `childPax[${index}].frequentFlyerNo`,
                                          e.target.value
                                        );
                                      }}
                                      fullWidth
                                      variant="outlined"
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        )
                    )}

                  {requestObj &&
                    (requestObj.mode == "ROUND" ||
                      requestObj.mode == "ROUND-SP") && (
                      <>
                        <Grid item md={12} style={{ padding: "5px 10px" }}>
                          <Typography style={{ fontSize: 12, fontWeight: 500 }}>
                            Return
                          </Typography>
                        </Grid>

                        {values.childPax[index].additionalReturn &&
                          values.childPax[index].additionalReturn.map(
                            (addn, indx) =>
                              indx == 0 && (
                                <Grid
                                  item
                                  md={12}
                                  style={{ padding: "0 5px" }}
                                  key={indx}
                                >
                                  <Box className={classes.paxInputSection}>
                                    <Grid container>
                                      <Grid item md={3} m={12} xs={12}>
                                        <div
                                          className="form-group returnSection"
                                          style={{ paddingTop: 13.5 }}
                                        >
                                          <TextField
                                            placeholder="FF airline"
                                            name={`childPax[${index}].additionalReturn[${indx}].frequentAirline`}
                                            value={
                                              values.childPax[index]
                                                .additionalReturn[indx]
                                                .frequentAirlineName +
                                              " " +
                                              values.childPax[index]
                                                .additionalReturn[indx]
                                                .frequentAirline
                                            }
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                          />
                                        </div>
                                      </Grid>

                                      <Grid item md={3}>
                                        <div className="form-group returnSection">
                                          <TextField
                                            name={`childPax[${index}].additionalReturn[${indx}].frequentFlyerNo`}
                                            placeholder="FF no."
                                            value={
                                              values.childPax[index]
                                                .additionalReturn[indx]
                                                .frequentFlyerNo
                                            }
                                            onChange={(ev) =>
                                              setFieldValue(
                                                `childPax[${index}].additionalReturn[${indx}].frequentFlyerNo`,
                                                ev.target.value
                                              )
                                            }
                                            fullWidth
                                            variant="outlined"
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>
                              )
                          )}
                      </>
                    )}
                </Grid>
              </Collapse>

              {/* <hr className="hr-tag" /> */}
            </Grid>
          </Grid>
        </div>
      )}

      {values && props.type === "infant" && values.infantPax && (
        <div className="input-section-wrapper-inner">
          <Grid container className={classes.inputSection}>
            <Grid item style={{ width: "100%" }}>
              <Box className={classes.paxInputSection}>
                <Grid container className="paxInputContainer">
                  <Grid item md={2} sm={12} xs={12}>
                    <div className="form-group">
                      <label className="inner-label">Title</label>
                      <Select
                        name={`infantPax[${index}].initial`}
                        value={values.infantPax[index].initial}
                        // defaultValue={-1} //{values.infantPax[index].initial}
                        onChange={(e) => {
                          setFieldValue(
                            `infantPax[${index}].initial`,
                            e.target.value
                          );
                        }}
                        variant="outlined"
                        className="nsel-component"
                      >
                        {/* <MenuItem key="-1" value="-1">
                          Title
                        </MenuItem> */}
                        <MenuItem key="Mstr" value="Mstr">
                          Mstr
                        </MenuItem>
                        <MenuItem key="Miss" value="Miss">
                          Miss
                        </MenuItem>
                      </Select>
                      <ErrorMessage
                        name={`infantPax[${index}].initial`}
                        component="div"
                        className="error"
                      />
                      <OaFormikErrorAlert
                        name={`infantPax[${index}].initial`}
                        error={props.error}
                        touched={props.touched}
                      />
                    </div>
                  </Grid>

                  <Grid item md={3} xs={12} sm={3}>
                    <div className="form-group">
                      <label className="inner-label">First Name</label>
                      <OaTextFieldAdorned
                        name={`infantPax[${index}].firstname`}
                        // placeholder="First Name"
                        value={values.infantPax[index].firstname}
                        onChange={(e) => {
                          savedPaxHandler(e.target.value, index, "Infant");
                          setFieldValue(
                            `infantPax[${index}].firstname`,
                            e.target.value
                          );
                        }}
                        fullWidth
                        variant="outlined"
                        adornpos="start"
                        adornico={<i className="fa fa-user" />}
                      />
                      {savedPaxTravellerArr.length > 0 && (
                        <div
                          onClick={() => {
                            setSavedPaxTravellerArr([]);
                          }}
                          className="crossButton"
                        >
                          X
                        </div>
                      )}
                      {savedPaxTravellerArr.length > 0 && (
                        <div className="savedPaxsContainer">
                          {savedPaxTravellerArr.map((pax) => (
                            <div className="savedPaxContainer">
                              <div
                                onClick={() => {
                                  setFieldValue(
                                    `infantPax[${index}].initial`,
                                    pax.paxTitle === "Mstr"
                                      ? "Mr"
                                      : pax.paxTitle
                                  );
                                  setFieldValue(
                                    `infantPax[${index}].firstname`,
                                    pax.firstName
                                  );
                                  setFieldValue(
                                    `infantPax[${index}].surname`,
                                    pax.lastName
                                  );
                                  setFieldValue(
                                    `infantPax[${index}].dobDisplay`,
                                    new Date(pax.dob)
                                  );
                                  setFieldValue(
                                    `infantPax[${index}].dob`,
                                    pax.dob
                                  );
                                  setFieldValue(
                                    `infantPax[${index}].passportno`,
                                    pax.passport
                                  );
                                  // setFieldValue(
                                  //   `adultPax[${index}].passportidate`,
                                  //   pax.passportIssueDate
                                  // );
                                  setFieldValue(
                                    `infantPax[${index}].passportidate`,
                                    new Date(pax.passportIssueDate)
                                  );
                                  setFieldValue(
                                    `infantPax[${index}].passportexpire`,
                                    new Date(pax.passportExpireDate)
                                  );
                                  setFieldValue(
                                    `infantPax[${index}].passporticountry`,
                                    pax.issueCountry
                                  );

                                  setSavedPaxTravellerArr([]);
                                }}
                              >
                                {pax.paxTitle} {pax.firstName} {pax.lastName} -
                                ({pax.paxType})
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      <ErrorMessage
                        name={`infantPax[${index}].firstname`}
                        component="div"
                        className="error"
                      />
                      <OaFormikErrorAlert
                        name={`infantPax[${index}].firstname`}
                        error={props.error}
                        touched={props.touched}
                      />
                    </div>
                  </Grid>

                  <Grid item md={3} xs={12} sm={3}>
                    <div className="form-group">
                      <label className="inner-label">Last Name</label>
                      <OaTextFieldAdorned
                        name={`infantPax[${index}].surname`}
                        // placeholder="Last Name"
                        value={values.infantPax[index].surname}
                        onChange={(e) => {
                          setFieldValue(
                            `infantPax[${index}].surname`,
                            e.target.value
                          );
                        }}
                        fullWidth
                        variant="outlined"
                        adornpos="start"
                        adornico={<i className="fa fa-user" />}
                      />
                      <ErrorMessage
                        name={`infantPax[${index}].surname`}
                        component="div"
                        className="error"
                      />
                      <OaFormikErrorAlert
                        name={`infantPax[${index}].surname`}
                        error={props.error}
                        touched={props.touched}
                      />
                    </div>
                  </Grid>

                  <Grid item md={2} xs={8} sm={3}>
                    <div className="form-group">
                      <label className="inner-label">DATE OF BIRTH</label>
                      {/* <OaComboDatePicker
                        name={`infantPax[${index}].dobDisplay`}
                        minDate={getReqDate(-(365 * 2))}
                        maxDate={getReqDate(-3)}
                        className="pax-info-date-picker"
                        value={values.infantPax[index].dobDisplay}
                        onChange={(newDate) => {
                          const formatted = dateFnsFormat(
                            newDate, "yyyy-MM-dd"
                          );
                          setFieldValue(
                            `infantPax[${index}].dobDisplay`,
                            newDate
                          );
                          setFieldValue(`infantPax[${index}].dob`, formatted);
                        }}
                      /> */}

                      <DatePickerWithCustomHeader
                        dateFormat="dd-MM-yyyy"
                        name={`infantPax[${index}].dobDisplay`}
                        minDate={getReqDate(-(365 * 2))}
                        maxDate={getReqDate(-3)}
                        className="pax-info-date-picker"
                        autoComplete="off"
                        disableClearable
                        selected={values.infantPax[index].dobDisplay}
                        onChange={(newDate) => {
                          const formatted = dateFnsFormat(
                            newDate,
                            "dd-MMM-yyyy"
                          );
                          setFieldValue(
                            `infantPax[${index}].dobDisplay`,
                            newDate
                          );
                          setFieldValue(`infantPax[${index}].dob`, formatted);
                          const newDatee = new Date(formatted);
                          //   console.log(newDate, formatted, newDatee);
                        }}
                        startYear={1970}
                        endYear={2023}
                        showIcon
                      />
                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          name={`infantPax[${index}].dobDisplay`}
                          disableFuture
                          minDate={getReqDate(-(365 * 2))}
                          maxDate={getReqDate(-3)}
                          className="pax-info-date-picker"
                          fullWidth
                          color="secondary"
                          inputVariant="outlined"
                          format="dd-MM-yyyy"
                          margin="normal"
                          autoOk={true}
                          value={values.infantPax[index].dobDisplay}
                          onChange={(e, v) => {
                            const formatted = dateFnsFormat(
                              new Date(e),
                              "yyyy-MM-dd"
                            );
                            setFieldValue(`infantPax[${index}].dob`, formatted);
                            setFieldValue(
                              `infantPax[${index}].dobDisplay`,
                              new Date(e)
                            );
                          }}
                          emptyLabel="Date of birth"
                        />
                      </MuiPickersUtilsProvider> */}
                      <ErrorMessage
                        name={`infantPax[${index}].dobDisplay`}
                        component="div"
                        className="error"
                      />
                      <OaFormikErrorAlert
                        name={`infantPax[${index}].dobDisplay`}
                        error={props.error}
                        touched={props.touched}
                      />
                    </div>
                  </Grid>

                  <Grid
                    item
                    md={1}
                    xs={2}
                    sm={1}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Grid item md={6}>
                      <div
                        className="form-group"
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          cursor: "pointer",
                          paddingTop: 5,
                          marginTop: 30,
                          marginLeft: 10,
                          paddingLeft: 16,
                        }}
                        onClick={() => setOpenAdditional(!openAdditional)}
                      >
                        <span className="additional-info-span">
                          <i
                            onMouseEnter={() => setShowAdditionalInfo(true)}
                            onMouseLeave={() => setShowAdditionalInfo(false)}
                            className={
                              openAdditional ? "fas fa-minus" : "fas fa-plus"
                            }
                          ></i>
                          {showAdditionalInfo && (
                            <span className="additional-info-mobile-label">
                              Additional Info
                            </span>
                          )}
                        </span>
                      </div>
                    </Grid>
                    {requestObj.clientType === "Corporate" && (
                      <Grid item md={6}>
                        <div
                          className="form-group"
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            cursor: "pointer",
                            paddingTop: 5,
                            marginTop: 30,
                          }}
                          onClick={() =>
                            setFieldValue(
                              `infantPax[${index}].paxSave`,
                              values.infantPax[index].paxSave === true
                                ? false
                                : true
                            )
                          }
                        >
                          <span style={{ marginRight: 5, fontSize: 16 }}>
                            <i
                              className={"fas fa-save"}
                              onMouseEnter={() => setShowSaveInfoTK(true)}
                              onMouseLeave={() => setShowSaveInfoTK(false)}
                              style={{
                                color:
                                  values.infantPax[index].paxSave == true
                                    ? "green"
                                    : "black",
                              }}
                            ></i>
                            {showSaveInfoTK && (
                              <span className="additional-info-mobile-label">
                                Save Pax
                              </span>
                            )}
                          </span>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item md={12}>
              <Collapse in={openAdditional}>
                <Grid container>
                  {values.isDomestic == false && (
                    <Grid container>
                      <Grid item md={12}>
                        <Box>
                          <Grid container>
                            <Grid item className="flex-col-20">
                              <div className="form-group">
                                <label className="inner-label">
                                  PASSPORT NO.
                                </label>
                                <TextField
                                  name={`infantPax[${index}].passportno`}
                                  value={values.infantPax[index].passportno}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `infantPax[${index}].passportno`,
                                      e.target.value
                                    );
                                  }}
                                  fullWidth
                                  variant="outlined"
                                />
                                <ErrorMessage
                                  name={`infantPax[${index}].passportno`}
                                  component="div"
                                  className="error"
                                />
                                <OaFormikErrorAlert
                                  name={`infantPax[${index}].passportno`}
                                  error={props.error}
                                  touched={props.touched}
                                />
                              </div>
                            </Grid>

                            <Grid item className="flex-col-20">
                              <div className="form-group">
                                <label className="inner-label">
                                  ISSUING DATE
                                </label>
                                {/* <OaComboDatePicker
                                  name={`infantPax[${index}].passportidate`}
                                  minDate={getReqDate(-(365 * 10))}
                                  className="pax-info-date-picker"
                                  value={values.infantPax[index].passportidate}
                                  onChange={(newDate) => {
                                    const formatted = dateFnsFormat(
                                      newDate, "yyyy-MM-dd"
                                    );
                                    setFieldValue(
                                      `infantPax[${index}].passportidate`,
                                      formatted
                                    );
                                  }}
                                /> */}
                                <DatePickerWithCustomHeader
                                  dateFormat="dd-MM-yyyy"
                                  name={`infantPax[${index}].passportidate`}
                                  maxDate={getReqDate(-(365 * 0))}
                                  minDate={getReqDate(-(365 * 10))}
                                  className="pax-info-date-picker"
                                  autoComplete="off"
                                  disableClearable
                                  selected={
                                    values.infantPax[index].passportidate
                                  }
                                  onChange={(newDate) => {
                                    const formatted = dateFnsFormat(
                                      newDate,
                                      "yyyy-MM-dd"
                                    );
                                    setFieldValue(
                                      `infantPax[${index}].passportidate`,
                                      newDate
                                    );
                                  }}
                                  startYear={1970}
                                  endYear={2023}
                                />
                                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    name={`infantPax[${index}].passportidate`}
                                    minDate={getReqDate(-(365 * 10))}
                                    disableFuture
                                    className="pax-info-date-picker"
                                    fullWidth
                                    color="secondary"
                                    inputVariant="outlined"
                                    format="dd-MM-yyyy"
                                    margin="normal"
                                    autoOk={true}
                                    value={
                                      values.infantPax[index].passportidate
                                    }
                                    onChange={(e, v) => {
                                      const formatted = dateFnsFormat(
                                        new Date(e),
                                        "yyyy-MM-dd"
                                      );
                                      setFieldValue(
                                        `infantPax[${index}].passportidate`,
                                        formatted
                                      );
                                    }}
                                    emptyLabel="Passport issued on"
                                  />
                                </MuiPickersUtilsProvider> */}
                                <ErrorMessage
                                  name={`infantPax[${index}].passportidate`}
                                  component="div"
                                  className="error"
                                />
                                <OaFormikErrorAlert
                                  name={`infantPax[${index}].passportidate`}
                                  error={props.error}
                                  touched={props.touched}
                                />
                              </div>
                            </Grid>

                            <Grid item className="flex-col-20">
                              <div className="form-group">
                                <label className="inner-label">
                                  EXPIRY DATE
                                </label>
                                {/* <OaComboDatePicker
                                  name={`infantPax[${index}].passportexpire`}
                                  maxDate={getReqDate(365 * 10)}
                                  className="pax-info-date-picker"
                                  value={values.infantPax[index].passportexpire}
                                  onChange={(newDate) => {
                                    const formatted = dateFnsFormat(
                                      newDate, "yyyy-MM-dd"
                                    );
                                    setFieldValue(
                                      `infantPax[${index}].passportexpire`,
                                      formatted
                                    );
                                  }}
                                /> */}
                                <DatePickerWithCustomHeader
                                  dateFormat="dd-MM-yyyy"
                                  name={`infantPax[${index}].passportexpire`}
                                  maxDate={getReqDate(365 * 15)}
                                  minDate={getReqDate(-(365 * 0))}
                                  autoComplete="off"
                                  disableClearable
                                  className="pax-info-date-picker"
                                  selected={
                                    values.infantPax[index].passportexpire
                                  }
                                  onChange={(newDate) => {
                                    const formatted = dateFnsFormat(
                                      newDate,
                                      "yyyy-MM-dd"
                                    );
                                    setFieldValue(
                                      `infantPax[${index}].passportexpire`,
                                      newDate
                                    );
                                  }}
                                />
                                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    name={`infantPax[${index}].passportexpire`}
                                    maxDate={getReqDate(365 * 10)}
                                    disablePast
                                    className="pax-info-date-picker"
                                    fullWidth
                                    color="secondary"
                                    inputVariant="outlined"
                                    format="dd-MM-yyyy"
                                    margin="normal"
                                    autoOk={true}
                                    value={
                                      values.infantPax[index].passportexpire
                                    }
                                    onChange={(e, v) => {
                                      const formatted = dateFnsFormat(
                                        new Date(e),
                                        "yyyy-MM-dd"
                                      );
                                      setFieldValue(
                                        `infantPax[${index}].passportexpire`,
                                        formatted
                                      );
                                    }}
                                    emptyLabel="Passport expiry date"
                                  />
                                </MuiPickersUtilsProvider> */}
                                <ErrorMessage
                                  name={`infantPax[${index}].passportexpire`}
                                  component="div"
                                  className="error"
                                />
                                <OaFormikErrorAlert
                                  name={`infantPax[${index}].passportexpire`}
                                  error={props.error}
                                  touched={props.touched}
                                />
                              </div>
                            </Grid>

                            <Grid item className="flex-col-20">
                              <div className="form-group">
                                <label className="inner-label">
                                  ISSUING COUNTRY
                                </label>
                                <Autocomplete
                                  fullWidth
                                  className="aac-component"
                                  name={`infantPax[${index}].passporticountry`}
                                  PopperComponent={customPopper}
                                  options={countries || []}
                                  getOptionLabel={(o) =>
                                    o
                                      ? o.code != ""
                                        ? `${o.name} (${o.code})`
                                        : `${o.name}`
                                      : ""
                                  }
                                  // defaultValue={
                                  //   countries.filter((ctry) => {
                                  //     return ctry.code === "";
                                  //   })[0]
                                  // }
                                  value={
                                    // values.adultPax[index].passporticountry ??
                                    countries.filter((ctry) => {
                                      return (
                                        ctry.code ===
                                          values?.infantPax[index]
                                            ?.passporticountry ?? ""
                                      );
                                    })[0]
                                  }
                                  //value={values.infantPax[index].passporticountry}
                                  getOptionSelected={(option, value) =>
                                    option.code == value.code
                                  }
                                  onChange={(event, newValue) => {
                                    // console.log(newValue);
                                    if (newValue != null) {
                                      setFieldValue(
                                        `infantPax[${index}].passporticountry`,
                                        newValue
                                      );
                                      setFieldValue(
                                        `infantPax[${index}].passporticountry`,
                                        newValue.code
                                      );
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      color="secondary"
                                      variant="outlined"
                                      type="text"
                                      inputProps={{
                                        ...params.inputProps,
                                        autocomplete: "new-password",
                                      }}
                                    />
                                  )}
                                  disablePortal={true}
                                />
                                <ErrorMessage
                                  name={`infantPax[${index}].passporticountry`}
                                  component="div"
                                  className="error"
                                />
                                <OaFormikErrorAlert
                                  name={`infantPax[${index}].passporticountry`}
                                  error={props.error}
                                  touched={props.touched}
                                />
                              </div>
                            </Grid>

                            <Grid item className="flex-col-20">
                              <div className="form-group">
                                <label className="inner-label">VISA TYPE</label>
                                <Autocomplete
                                  fullWidth
                                  className="aac-component"
                                  name={`infantPax[${index}].visatype`}
                                  PopperComponent={customPopper}
                                  options={visatypes || []}
                                  getOptionLabel={(o) =>
                                    o ? `${o.visalabel}` : ""
                                  }
                                  defaultValue={
                                    visatypes.filter((visas) => {
                                      return visas.visatype === "";
                                    })[0]
                                  }
                                  getOptionSelected={(option, value) =>
                                    option.code == value.visatype
                                  }
                                  onChange={(event, newValue) => {
                                    // console.log(newValue);
                                    if (newValue != null) {
                                      setFieldValue(
                                        `infantPax[${index}].visatype`,
                                        newValue.visatype
                                      );
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      color="secondary"
                                      variant="outlined"
                                      inputProps={{
                                        ...params.inputProps,
                                        autocomplete: "new-password",
                                      }}
                                    />
                                  )}
                                  disablePortal={true}
                                />
                                <ErrorMessage
                                  name={`infantPax[${index}].visatype`}
                                  component="div"
                                  className="error"
                                />
                                <OaFormikErrorAlert
                                  name={`infantPax[${index}].visatype`}
                                  error={props.error}
                                  touched={props.touched}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    item
                    md={12}
                    style={{ padding: "5px 10px", paddingBottom: 0 }}
                  >
                    <Typography style={{ fontSize: 12, fontWeight: 500 }}>
                      Onward
                    </Typography>
                  </Grid>

                  {values.infantPax[index].additionalOnward &&
                    values.infantPax[index].additionalOnward.map(
                      (addn, indx) =>
                        indx == 0 && (
                          <Grid
                            item
                            md={12}
                            style={{ padding: "0 5px" }}
                            key={indx}
                          >
                            <Box className={classes.paxInputSection}>
                              <Grid container>
                                <Grid item md={3} sm={12} xs={12}>
                                  <div className="form-group">
                                    <TextField
                                      placeholder="FF airline"
                                      name={`infantPax[${index}].additionalOnward[${indx}].frequentAirline`}
                                      value={
                                        values.infantPax[index]
                                          .additionalOnward[indx]
                                          .frequentAirlineName +
                                        " " +
                                        values.infantPax[index]
                                          .additionalOnward[indx]
                                          .frequentAirline
                                      }
                                      fullWidth
                                      disabled
                                      variant="outlined"
                                    />
                                  </div>
                                </Grid>

                                <Grid item md={3} m={12} xs={12}>
                                  <div className="form-group">
                                    <TextField
                                      name={`infantPax[${index}].additionalOnward[${indx}].frequentFlyerNo`}
                                      placeholder="FF no."
                                      value={
                                        values.infantPax[index]
                                          .additionalOnward[indx]
                                          .frequentFlyerNo
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `infantPax[${index}].additionalOnward[${indx}].frequentFlyerNo`,
                                          e.target.value
                                        );
                                      }}
                                      fullWidth
                                      variant="outlined"
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        )
                    )}

                  {requestObj &&
                    (requestObj.mode == "ROUND" ||
                      requestObj.mode == "ROUND-SP") && (
                      <>
                        <Grid item md={12} style={{ padding: "5px 10px" }}>
                          <Typography style={{ fontSize: 12, fontWeight: 500 }}>
                            Round
                          </Typography>
                        </Grid>

                        {values.infantPax[index].additionalReturn &&
                          values.infantPax[index].additionalReturn.map(
                            (addn, indx) =>
                              indx == 0 && (
                                <Grid
                                  item
                                  md={12}
                                  style={{ padding: "0 5px" }}
                                  key={indx}
                                >
                                  <Box className={classes.paxInputSection}>
                                    <Grid container>
                                      <Grid item md={3} m={12} xs={12}>
                                        <div
                                          className="form-group"
                                          style={{ paddingTop: 13.5 }}
                                        >
                                          <TextField
                                            placeholder="FF airline"
                                            name={`infantPax[${index}].additionalReturn[${indx}].frequentAirline`}
                                            value={
                                              values.infantPax[index]
                                                .additionalReturn[indx]
                                                .frequentAirlineName +
                                              " " +
                                              values.infantPax[index]
                                                .additionalReturn[indx]
                                                .frequentAirline
                                            }
                                            fullWidth
                                            disabled
                                            variant="outlined"
                                          />
                                        </div>
                                      </Grid>

                                      <Grid item md={3} m={12} xs={12}>
                                        <div className="form-group">
                                          <TextField
                                            name={`infantPax[${index}].additionalReturn[${indx}].frequentFlyerNo`}
                                            placeholder="FF no."
                                            value={
                                              values.infantPax[index]
                                                .additionalReturn[indx]
                                                .frequentFlyerNo
                                            }
                                            onChange={(ev) =>
                                              setFieldValue(
                                                `infantPax[${index}].additionalReturn[${indx}].frequentFlyerNo`,
                                                ev.target.value
                                              )
                                            }
                                            fullWidth
                                            variant="outlined"
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>
                              )
                          )}
                      </>
                    )}
                </Grid>
              </Collapse>

              {/* <hr className="hr-tag" /> */}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default Passenger;

const useStyles = makeStyles((theme) => ({
  inputSectionTitle: {
    marginTop: 3,
  },
  addtnServices: {
    [theme.breakpoints.down(550)]: {
      textAlign: "left !important",
      width: 180,
    },
  },

  paxInputSection: {
    "& .onwardSection": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
    "& .paxInputContainer": {
      "& .additional-info-span": {
        marginRight: 5,
        fontSize: 16,
      },
      "& .additional-info-mobile-label": {
        position: "absolute",
        background: "#fff",
        width: 100,
        textAlign: "center",
        fontSize: 12,
        fontWeight: 500,
        zIndex: 100,
        borderRadius: 5,
        top: 10,
        padding: "2px 0px",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",

        // display: "none",
        marginLeft: 5,
      },
      [theme.breakpoints.down(450)]: {
        flexDirection: "column",
        "& .additional-info-span": {
          display: "flex",
          alignItems: "center",
          width: 300,
        },
        "& .additional-info-mobile-label": {
          display: "inline-block",
        },
      },
    },
    "& .lastNameRow": {
      display: "flex",
      [theme.breakpoints.down(450)]: {
        "& .last-name": {
          width: "100%",
        },
      },
    },
    "& .react-datepicker__input-container .react-datepicker__calendar-icon": {
      top: `8px !important`,
    },
    "& .react-datepicker__input-container input": {
      padding: "12px 10px 12px 28px !important",
    },
  },
  inputSection: {
    width: "100%",
    marginTop: 3,
    marginBottom: 3,
    "& .MuiAutocomplete-paper": {
      zIndex: 1100,
    },
    "& .paxInputNameContainer": {
      [theme.breakpoints.down(450)]: {
        width: "100%",
      },
    },
    "& .passportContainer": {
      [theme.breakpoints.down(500)]: {
        width: "100%",
      },
    },
    "& .birthDateContainer": {
      padding: "0px 6px",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    "& .passportInputContainer": {
      [theme.breakpoints.down(500)]: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        "& .flex-col-20": {
          width: "100%",
        },
      },
    },
    "& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active": {
      WebkitBoxShadow: "0 0 0 30px white inset !important",
    },
    [theme.breakpoints.down(1720)]: {
      "& .MuiInputBase-input": {
        fontSize: "13px !important",
      },
    },
    "& .pax-info-title": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      "& img": {
        height: 20,
        marginRight: 15,
      },
      "& p": {
        fontSize: 16,
        fontWeight: 500,
      },
      "& span": {
        fontSize: 14,
      },
    },
    "& .onwardSection": {
      paddingTop: "0px !important",
      paddingBottom: "5px !important",
      paddingLeft: "4px !important",
    },
    "& .returnSection": {
      paddingTop: "0px !important",
      paddingBottom: "5px !important",
      paddingLeft: "4px !important",
    },
    "& .form-group": {
      position: "relative",
      "& .crossButton": {
        position: "absolute",
        right: 0,
        fontSize: 15,
        cursor: "pointer",
        top: 47,
        right: 20,
      },
      "& .savedPaxsContainer": {
        position: "absolute",
        zIndex: 5,
        width: "90%",
        [theme.breakpoints.down("970")]: {
          width: "94%",
        },
        padding: 5,
        backgroundColor: "#fff",
        borderRadius: 10,
        border: "1px solid #eee",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
      },

      "& .savedPaxContainer": {
        backgroundColor: "#fff",
        padding: 5,

        "&:hover": {
          background: "#eee",
          cursor: "pointer",
        },
      },
      "& .react-datepicker__input-container": {
        "& input": {
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
        },
      },
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingTop: 0,
      paddingBottom: 0,
      width: "100%",
      [theme.breakpoints.down(450)]: {
        padding: "8px 0px !important",
      },
      "& .aac-component .MuiAutocomplete-inputRoot": {
        height: 43,
      },
      "& .iac-component .MuiAutocomplete-inputRoot": {
        height: 43,
      },
      "& .nsel-component.MuiInputBase-root": {
        marginTop: 5,
        width: "100%",
        paddingLeft: 5,
        "& .MuiSelect-iconOutlined": {
          right: 7,
          [theme.breakpoints.down(1720)]: {
            right: 0,
          },
        },
        "& .MuiSelect-outlined.MuiSelect-outlined": {
          [theme.breakpoints.down(1720)]: {
            padding: "13px 18px 13px 5px",
          },
        },
      },
    },
    "& .button-holder": {
      marginTop: 50,
      justifyContent: "right",
    },
    "& .action-button": {
      background: theme.palette.secondary.main,
      padding: "5px",
      textAlign: "center",
      color: theme.palette.secondary.contrastText,
      width: 200,
      height: 45,
      borderRadius: 5,
      fontSize: 22,
      fontWeight: 500,
      appearance: "none",
      MozAppearance: "none",
      WebkitAppearance: "none",
      border: "none !important",
      outline: "none !important",
      cursor: "pointer",
    },
    "& .pax-info-date-picker": {
      border: `solid 1px ${theme.palette.secondary.grandmaGray}`,
      borderRadius: 3,
      marginTop: 5,
      "&:hover": {
        border: `solid 1px ${theme.palette.background.dark}`,
      },
      "& .ocdp-container": {
        padding: 5,
      },
      "& .MuiIconButton-label": {
        color: theme.palette.primary.darkText,
      },
      "& .MuiInputAdornment-positionEnd": {
        [theme.breakpoints.down(1500)]: {
          marginLeft: 0,
          marginRight: -12,
          width: 25,
        },
        "& .MuiIconButton-root": {
          [theme.breakpoints.down(1500)]: {
            padding: "12px 0",
          },
        },
      },
    },
    "& .inner-label": {
      fontSize: "14px",
      fontWeight: 400,
      color: theme.palette.primary.darkText,
      [theme.breakpoints.down(1100)]: {
        fontSize: 12,
      },
      [theme.breakpoints.down(960)]: {
        fontSize: 14,
      },
    },
    "& .collapsible-toggler": {
      cursor: "pointer",
      "& span, p": {
        fontSize: 12,
        fontWeight: 400,
      },
    },
    "& .collapsible-inner": {
      padding: "5px 5px",
    },
    "& .flex-col-20": {
      width: "20%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .flex-col-33": {
      width: "33.33%",
    },
    "& .form-group-rdx": {
      paddingTop: "0 !important",
      marginTop: -13,
    },
    "& .hr-tag": {
      margin: "0.5rem 0",
      height: "0.3px",
      background: "rgb(240, 240, 240)",
    },
    "& .ocdp-container": {
      padding: "5px 10px !imported",
    },

    "& .error-msgBox": {
      width: "60%",
      // height:'100vh',
      bottom: 0,
      position: "fixed",
      // position:'absolute',
      top: "10%",
      left: "20%",
      padding: "6px 13px",
      zIndex: 999,
      margin: "10px 0",
      "& .error-container": {
        position: "relative",
        width: "100%",
        padding: "6px 13px",
        margin: "10px 0",
      },
    },
  },
}));
